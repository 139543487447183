import React, { useEffect, useMemo, useState } from "react";
import {
  Box,
  Button,
  CircularProgress,
  InputLabel,
  MenuItem,
  Modal,
  Select,
  Stack,
  TextField,
  Typography,
  FormControl,
  Alert,
  IconButton,
  Collapse,
  OutlinedInput,
  Checkbox,
  FormControlLabel,
  Chip,
  Radio,
  RadioGroup,
  FormLabel,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";

import moment from "moment";
import { AgGridReact } from "ag-grid-react";
import "@ag-grid-community/all-modules/dist/styles/ag-grid.css";
import "@ag-grid-community/all-modules/dist/styles/ag-theme-material.css";
import "ag-grid-enterprise";
import axios from "axios";
import LocalizationProvider from "@mui/lab/LocalizationProvider";
import DesktopDatePicker from "@mui/lab/DesktopDatePicker";
import AdapterDateFns from "@mui/lab/AdapterDateFns";
import { toast } from "react-toastify";
import {
  handleSpringDataMutationRequest,
  handleSpringDataRequest,
} from "../../../utils/apiUtils";
import { BASE_SPRING_ENDPOINT } from "../../../utils/apiUtils/config";
import ReportsLoader from "./ReportsLoader";
import { channelLogoMap } from "../../../utils/common/CallenderConstants";
import {
  razorPayKey,
  springBaseUrl,
} from "../../../utils/common/AmplitudeConstants";
import { sideBar } from "../../../utils/common/sidebar.js";
import CustomDateComponent from "./customDateComponent";
import "./acc.css";

const TransactionsManagement = (props) => {
  console.log("props", props);
  const [ledger, setLedger] = useState(null);
  const [accBalance, setAccBalance] = useState(null);
  const [hotelCurr, setHotelCurr] = useState(null);
  const [groupId, setGroupId] = useState(null);
  const [accountInfo, setAccountInfo] = useState(null);
  const [rowsData, setRowsData] = useState(null);
  const [gridApi, setGridApi] = useState(null);
  const [gridColumnApi, setGridColumnApi] = useState(null);
  const [selectedRowsLength, setSelectedRowsLength] = useState(0);
  const [filterCount, setFilterCount] = useState(0);
  const [selectedRows, setSelectedRows] = useState(null);
  const [selectedBookingId, setSelectedBookingId] = useState(null);
  const [name, setname] = useState("");
  const [email, setemail] = useState("");
  const [contact, setcontact] = useState("");
  const [authType, setauthType] = useState("netbanking");
  const [beneName, setbeneName] = useState("");
  const [accNumber, setaccNumber] = useState(0);
  const [accountType, setaccountType] = useState("savings");
  const [ifsc, setifsc] = useState("");
  const [customerId, setcustomerId] = useState("");
  const [orderId, setorderId] = useState("");
  const [indPaymentMethod, setindPaymentMethod] = useState(false);
  const [indPaymentDetail, setindPaymentDetail] = useState(null);
  const [isValidHash, setIsValidHash] = useState(false);
  const [openAccountingVersionModal, setOpenAccountingVersionModal] =
    useState(false);
  const handleOpenAccountingVersionModalOpen = () =>
    setOpenAccountingVersionModal(true);
  const handleOpenAccountingVersionModalClose = () =>
    setOpenAccountingVersionModal(false);
  const [createBillingSchedule, setCreateBillingSchedule] = useState(false);
  const handleOpenCreateBillingSchedule = () => setCreateBillingSchedule(true);
  const handleCreateBillingScheduleClose = () => {
    setdayError(false);
    setCreateBillingSchedule(false);
  };
  const [deleteBillingSchedule, setDeleteBillingSchedule] = useState(false);
  const handleOpenDeleteBillingSchedule = () => setDeleteBillingSchedule(true);
  const handleDeleteBillingScheduleClose = () => {
    setDeleteBillingSchedule(false);
  };

  const [createManualEntry, setCreateManualEntry] = useState(false);
  const handleOpenCreateManualEntry = () => setCreateManualEntry(true);
  const handleCreateManualEntryClose = () => {
    setCreateManualEntry(false);
  };

  const [generateInvoices, setGenerateInvoices] = useState(false);
  const handleOpenGenerateInvoices = () => setGenerateInvoices(true);
  const handleGenerateInvoicesClose = () => {
    setGenerateInvoices(false);
  };

  const [voidInvoices, setVoidInvoices] = useState(false);
  const handleOpenVoidInvoices = () => setVoidInvoices(true);
  const handleVoidInvoicesClose = () => {
    setVoidInvoices(false);
  };

  const [showPaymentLink, setshowPaymentLink] = useState(false);

  const handleOpenshowPaymentLink = () => setshowPaymentLink(true);
  const handleCloseShowPaymentLink = () => setshowPaymentLink(false);

  const [mAmount, setMAmount] = useState("");
  const [mType, setMType] = useState("DEBIT");
  const [mMode, setMMode] = useState("SUBSCRIPTION");
  const [mDescription, setMDescription] = useState("");
  const [charge, setCharge] = useState(false);
  const [sendEmail, setsendEmail] = useState(false);
  const [terminalId, setterminalId] = useState("PRE");

  const [startDate, setStartDate] = useState(
    new Date()?.toISOString().slice(0, 10)
  );

  const [endDate, setEndDate] = useState(
    new Date()?.toISOString().slice(0, 10)
  );

  const [startDateUpdate, setStartDateUpdate] = useState("");

  const [endDateUpdate, setEndDateUpdate] = useState("");

  const handleSendEmailCheckboxChecked = (e) => {
    // console.log("e.target.checked : ", e.target.checked);
    if (e.target.checked) {
      setsendEmail(true);
    } else {
      setsendEmail(false);
    }
  };
  const handleCheckboxChecked = (e) => {
    if (e.target.checked) {
      setCharge(true);
    } else {
      setCharge(false);
    }
  };

  useEffect(() => {
    props && setLedger(props?.ledger);
    props && setRowsData(props?.ledger);
    props && setAccBalance(parseFloat(props?.accBalance).toFixed(2));
    props && setHotelCurr(props?.curr);
    props && setGroupId(props?.groupId);
    props && setAccountInfo(props?.res);
  }, [props]);

  const [currSymbol, setCurrSymbol] = useState(null);
  useEffect(() => {
    let curr = hotelCurr === "INR" ? "Rs." : "$";
    // const datass = Currencies?.filter((item) => {
    //   if (item.code === hotelCurr) {
    //     curr = item.symbol;
    //   }
    // });
    setCurrSymbol(curr);
  }, [hotelCurr]);

  const [depositLoading, setDepositLoading] = useState(false);
  const [depositAmt, setDepositAmt] = useState(0);

  const [open, setOpen] = useState(false);

  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const [openindPay, setopenindPay] = useState(false);

  const handleindPayOpen = () => setopenindPay(true);
  const handleindPayClose = () => setopenindPay(false);

  const [banneropen, setBannerOpen] = useState(true);

  const handleBannerOpen = () => setBannerOpen(true);
  const handleBannerClose = () => setBannerOpen(false);

  const [pmDetails, setPmDetails] = useState(null);
  const [nextPaymentSchedule, setnextPaymentSchedule] = useState(null);

  const [genericLoad, setgenericLoad] = useState(false);
  const [dayError, setdayError] = useState(false);
  const [pickDate, setPickDate] = useState(
    new Date()?.toISOString().slice(0, 10)
  );
  const handlePickDateChange = (newValue) => {
    let value = newValue?.target?.value?.split("-")[2];
    // console.log("newValue : ", newValue?.target?.value);
    if (parseInt(value) !== 1) {
      setdayError(true);
    } else {
      setdayError(false);
    }
    setPickDate(newValue?.target?.value);
  };

  const changeAccountingVersion = () => {
    setgenericLoad(true);
    axios
      .put(
        `${BASE_SPRING_ENDPOINT}` +
          `core/api/v1/account/change-accounting-version?groupId=${groupId}&version=2`
      )
      .then(async (res) => {
        toast.success("Successfully update accounting version");
        handleOpenAccountingVersionModalClose();
        setgenericLoad(false);
        const datass = await props?.getCompanyData1();
        const datasss = await props?.getCompanyData2();
        const data = props?.getLedgerData();
        const datas = props?.getAccountBalance();
        getIndianPaymentMethodDetails();
        fetchPaymentMethodData();
        getNextInvoicingSchedule();
      })
      .catch((err) => {
        toast.success("Something went wrong, Please try again later");
        handleOpenAccountingVersionModalClose();
        setgenericLoad(false);
      });
  };
  const dateConversion = (newDate) => {
    const [year, month, day] = newDate.split("-").map(Number);
    const dateObject = new Date(year, month - 1, day);
    return dateObject;
  };
  const createBillingScheduleAPI = () => {
    setgenericLoad(true);
    let finalDateValue = moment(dateConversion(pickDate)).format(
      "DD-MM-YYYY HH:mm:ss"
    );
    let value = moment(dateConversion(pickDate))
      .format("DD-MM-YYYY")
      .split("-")[0];
    // console.log("value : ", value, finalDateValue);
    if (parseInt(value) !== 1) {
      setdayError(true);
      setgenericLoad(false);
      return;
    }

    axios
      .get(
        `${BASE_SPRING_ENDPOINT}` +
          `core/api/v1/payment-schedule/createBillingSchedule?groupId=${groupId}&startDate=${finalDateValue}&billingType=${terminalId}`
      )
      .then((res) => {
        // setPickDate(new Date());
        handleCreateBillingScheduleClose();
        setgenericLoad(false);
        const data = props?.getLedgerData();
        const datas = props?.getAccountBalance();
        getIndianPaymentMethodDetails();
        fetchPaymentMethodData();
        getNextInvoicingSchedule();
      })
      .catch((err) => {
        // setPickDate(new Date());
        handleCreateBillingScheduleClose();
        setgenericLoad(false);
        console.log("The err is : ", err);
      });
  };

  const deleteBillingScheduleAPI = () => {
    setgenericLoad(true);
    axios
      .get(
        `${BASE_SPRING_ENDPOINT}` +
          `core/api/v1/payment-schedule/deleteBillingSchedule?groupId=${groupId}`
      )
      .then((res) => {
        handleDeleteBillingScheduleClose();
        setgenericLoad(false);
        const data = props?.getLedgerData();
        const datas = props?.getAccountBalance();
        getIndianPaymentMethodDetails();
        fetchPaymentMethodData();
        getNextInvoicingSchedule();
      })
      .catch((err) => {
        handleDeleteBillingScheduleClose();
        setgenericLoad(false);
        console.log("The err is : ", err);
      });
  };

  const createManualEntryAPI = () => {
    if (mAmount === null || mAmount === undefined || mAmount === "") {
      toast.error("Please enter a valid amount");
      return;
    }
    if (mType === null || mType === undefined || mType === "") {
      toast.error("Please enter a valid type");
      return;
    }
    if (mMode === null || mMode === undefined || mMode === "") {
      toast.error("Please enter a valid mode");
      return;
    }

    if (!mDescription.trim()) {
      toast.error("Please enter a valid description");
      return;
    }
    // if(mAmount || mAmount === undefined || mAmount === ""){
    //     toast.error("Please enter a valid amount");
    //     return;
    // }
    setgenericLoad(true);
    if (mType === "DEBIT") {
      const payload = {
        customLedgerId: "1111",
        groupId: groupId,
        description: mDescription,
        amount: mAmount,
        mode: mMode,
        type: mType,
        startDate: startDate,
        endDate: endDate,
        status: "SUCCESS",
      };
      axios
        .post(
          `${BASE_SPRING_ENDPOINT}` +
            `core/api/v1/account-ledger/create-manual-entry?sendEmail=${sendEmail}`,
          payload
        )
        .then((res) => {
          toast.success("Successfully added debit entry");
          handleCreateManualEntryClose();
          setgenericLoad(false);
          const data = props?.getLedgerData();
          const datas = props?.getAccountBalance();
          getIndianPaymentMethodDetails();
          fetchPaymentMethodData();
          getNextInvoicingSchedule();
          setMAmount("");
          setMType("DEBIT");
          setMMode("SUBSCRIPTION");
          setMDescription("");
          setsendEmail(false);
          setStartDate("");
          setEndDate("");
        })
        .catch((err) => {
          handleCreateManualEntryClose();
          setgenericLoad(false);
          setMAmount("");
          setMType("DEBIT");
          setMMode("SUBSCRIPTION");
          setMDescription("");
          console.log("The err is : ", err);
          setsendEmail(false);
          setStartDate("");
          setEndDate("");
        });
    }
    if (mType === "CREDIT") {
      let shouldCharge = "NO";
      if (charge) {
        shouldCharge = "YES";
      }
      const payload = {
        customLedgerId: "1111",
        groupId: groupId,
        description: mDescription,
        amount: parseFloat(mAmount),
        mode: mMode,
        type: mType,
        startDate: startDate,
        endDate: endDate,
        status: "SUCCESS",
        currency: hotelCurr,
      };

      axios
        .post(
          `${BASE_SPRING_ENDPOINT}` +
            `core/api/v1/account-ledger/create-manual-credit-entry?charge=${shouldCharge}&sendEmail=${sendEmail}`,
          payload
        )
        .then((res) => {
          toast.success(res?.data);
          handleCreateManualEntryClose();
          setgenericLoad(false);
          const data = props?.getLedgerData();
          const datas = props?.getAccountBalance();
          getIndianPaymentMethodDetails();
          fetchPaymentMethodData();
          getNextInvoicingSchedule();
          setMAmount("");
          setMType("DEBIT");
          setMMode("SUBSCRIPTION");
          setMDescription("");
          setCharge(false);
          setsendEmail(false);
          setStartDate("");
          setEndDate("");
        })
        .catch((err) => {
          // console.log(err);
          toast.error(err?.message);
          handleCreateManualEntryClose();
          setgenericLoad(false);
          const data = props?.getLedgerData();
          const datas = props?.getAccountBalance();
          getIndianPaymentMethodDetails();
          fetchPaymentMethodData();
          getNextInvoicingSchedule();
          setMAmount("");
          setMType("DEBIT");
          setMMode("SUBSCRIPTION");
          setMDescription("");
          setCharge(false);
          setsendEmail(false);
          setStartDate("");
          setEndDate("");
          // console.log("The err is : ", err);
        });
    }
  };
  //----------------------------
  const fetchPaymentMethodData = () => {
    axios
      .get(
        `${BASE_SPRING_ENDPOINT}` +
          `core/api/v1/account-payment-method/get-active-payment-method?groupId=${groupId}&hotelId=${groupId}`
      )
      .then((res) => {
        setPmDetails(res?.data?.[0]);
      })
      .catch((err) => {
        toast.error("No active payment method is set for the account");
        setDepositAmt(0);
        handleClose();
        console.log("The err is : ", err);
      });
  };

  const fetchPaymentMethodData2 = () => {
    axios
      .get(
        `${BASE_SPRING_ENDPOINT}` +
          `core/api/v1/account-payment-method/get-active-payment-method?groupId=${groupId}&hotelId=${groupId}`
      )
      .then((res) => {
        if (res?.data && res?.data?.length > 0) {
          setPmDetails(res?.data?.[0]);
          handleOpen();
        } else {
          toast.error(
            "No active payment method is set for the account, please setup payment method in Payment methods tab."
          );
        }
        // console.log("active payment method : ", res);
      })
      .catch((err) => {
        toast.error("No active payment method is set for the account");
        setDepositAmt(0);
        handleClose();
        console.log("The err is : ", err);
      });
  };

  const [nextPaySchedule, setNextPaySchedule] = useState(null);
  //----------------------
  const getNextInvoicingSchedule = () => {
    handleSpringDataRequest(
      `core/api/v1/payment-schedule/get-all-schedule?groupId=${groupId}&hotelId=${groupId}`
    )
      .then((res) => {
        // setPmDetails(res[0]);
        console.log("next payment schedule : ", res);
        if (res && res !== undefined && res !== "FAILED") {
          setNextPaySchedule(res);
        }
      })
      .catch((err) => {
        // toast.error("No active payment method is set for the account");
        // setDepositAmt(0);
        // handleClose();
        setNextPaySchedule(null);
        console.log("The err is : ", err);
      });
    // axios
    //   .get(
    //     `${BASE_SPRING_ENDPOINT}` +
    //       `core/api/v1/payment-schedule/get-all-schedule?groupId=${groupId}&hotelId=${groupId}`
    //   )
    //   .then((res) => {
    //     // setPmDetails(res[0]);
    //     console.log("next payment schedule : ", res);
    //     if (res && res !== undefined && res?.data !== "FAILED") {
    //       setNextPaySchedule(res?.data);
    //     }
    //   })
    //   .catch((err) => {
    //     // toast.error("No active payment method is set for the account");
    //     // setDepositAmt(0);
    //     // handleClose();
    //     setNextPaySchedule(null);
    //     console.log("The err is : ", err);
    //   });
  };
  //-------------------
  const getIndianPaymentMethodDetails = () => {
    axios
      .get(
        `${BASE_SPRING_ENDPOINT}` +
          `core/api/v1/account-payment-method/get-account-payment-method-india?groupId=${groupId}&hotelId=${groupId}`
      )
      .then((res) => {
        console.log("Result is : ", res);
        if (res && res?.data !== undefined && res?.data !== "FAILED") {
          setindPaymentMethod(true);
          setindPaymentDetail(res?.data);
        }
      })
      .catch((err) => {
        console.log("Error is : ", err);
        setindPaymentMethod(false);
      });
  };

  useEffect(() => {
    if (groupId) {
      getIndianPaymentMethodDetails();
      fetchPaymentMethodData();
      getNextInvoicingSchedule();
    }
  }, [groupId]);

  const handleFortisPayment = () => {
    setDepositLoading(true);
    setRowsData(null);
    let mode = pmDetails?.payment_method_type === "account" ? "ach" : "cc";
    // console.log("pmdetails are : ", pmDetails);
    let amt = depositAmt.toString();
    handleSpringDataMutationRequest(
      "POST",
      `core/api/v1/account-payment-method/fortis_process_transaction/?groupId=${groupId}&hotelId=${groupId}&mode=${mode}&amt=${amt}`,
      pmDetails
    )
      .then((res) => {
        // console.log("The fortis result is : ", res);
        toast.success(res.responseMessageStr);
        setDepositAmt(0);
        handleClose();
        const data = props?.getLedgerData();
        const datas = props?.getAccountBalance();
        setDepositLoading(false);
      })
      .catch((err) => {
        console.log("the err is : ", err);
        toast.error(err);
        setDepositAmt(0);
        handleClose();
        setDepositLoading(false);
      });
  };

  const [razorpayFinalKey, setRazorpayFinalKey] = useState(razorPayKey);
  const [imageUrl, setImageUrl] = useState(channelLogoMap.STAYFLEXI_OM);
  const [tableLoader, setTableLoader] = useState(false);

  const payloadForRazorpay = {
    group_id: groupId,
    "razorpay-pg-id": "",
    amount: 0,
    currency: "INR",
  };

  // Razorpay
  var options = {
    key: razorpayFinalKey,
    amount: "100",
    currency: "INR",
    name: "Stayflexi",
    description: "Recharge account",
    image: imageUrl,
    // order_id: "", //This is a sample Order ID. Pass the `id` obtained in the response of Step 1
    handler: function (response) {
      setTableLoader(true);
      setRowsData(null);
      payloadForRazorpay["razorpay-pg-id"] = response.razorpay_payment_id;
      handleSpringDataMutationRequest(
        "POST",
        `core/api/v1/payments/razorpay/capture-payment/?groupId=${groupId}&hotelId=${groupId}`,
        payloadForRazorpay
      )
        .then((resp) => {
          toast.success(resp);
          const data = props?.getLedgerData();
          const datas = props?.getAccountBalance();
          setRazorpayAmount(0);
          setTableLoader(false);
        })
        .catch((err) => {
          toast.error(err);
          setTableLoader(false);
        });
      // alert(response.razorpay_payment_id);
      // alert(response.razorpay_order_id);
      // alert(response.razorpay_signature);
    },
    prefill: {
      name: accountInfo?.account_details?.account_name,
      email: accountInfo?.account_details?.account_billing_email,
      contact: "",
    },
    notes: {
      account_id: groupId,
      module_source: "ACCOUNT_PAYMENT_LINK",
    },
    theme: {
      color: "#1ab394",
      hide_topbar: false,
    },
  };

  const [errorShow, setErrorShow] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const invokeRazorpayModal = () => {
    payloadForRazorpay["amount"] = options["amount"];
    let rzp1 = new window.Razorpay(options);
    rzp1.open();
    rzp1.on("payment.failed", function (response) {
      setErrorShow(true);
      setErrorMessage(response?.error?.description);
      setTimeout(() => {
        setErrorShow(false);
      }, 4000);
    });
  };
  const [razorpayAmount, setRazorpayAmount] = useState(0);
  const [finalAmount, setFinalAmount] = useState(0);
  const [openAmountModal, setOpenAmountModal] = useState(false);

  const handleAmountClose = () => setOpenAmountModal(false);
  const handleAmountOpen = () => setOpenAmountModal(true);

  // open={openEmandateModal}
  // onClose={handleCloseOpenEmandateModal}
  const [openEmandateModal, setOpenEmandateModal] = useState(false);

  const handleoOpenEmandateModal = () => setOpenEmandateModal(true);
  const handleCloseOpenEmandateModal = () => {
    setname("");
    setemail("");
    setcontact("");
    setauthType("netbanking");
    setbeneName("");
    setaccNumber(0);
    setaccountType("savings");
    setifsc("");
    setOpenEmandateModal(false);
  };

  useEffect(() => {
    if (props && props?.accBalance && props?.accBalance < 0) {
      let amt = props?.accBalance * -1;
      let toSetamt = amt.toString().split(".")[0];
      setRazorpayAmount(toSetamt);
      setDepositAmt(toSetamt);
    }
  }, [props, props?.accBalance]);

  const rowSelectionType = "multiple";

  const onPaginationChange = (pageSize) => {
    gridApi.paginationSetPageSize(pageSize);
    // console.log("");
  };

  // quick search
  const onFilterTextChange = (event) => {
    // console.log(event.target.value)
    setFilterCount((prevCount) => prevCount + 1);
    // console.log("filterCount", filterCount)
    gridApi.setQuickFilter(event.target.value);
  };

  const [allCols, setAllCols] = useState(null);
  const [colmap, setColmap] = useState(null);

  const onGridReady = (params) => {
    setGridApi(params.api);
    setGridColumnApi(params.columnApi);
    setAllCols(params?.columnApi?.columnController?.columnDefs);
    // console.log("defs  : ", params.columnApi.columnController);
    const mp = new Map();
    const dt = params?.columnApi?.columnController?.columnDefs.map(
      (res, idx) => {
        //   console.log("index is : ", idx, "    ", res.field);
        mp.set(res.field, {
          header: res.headerName,
          hidden: false,
          type: "",
          order: idx + 1,
        });
      }
    );
    setColmap(mp);
    // handleDataRequest("https://beta.stayflexi.com/api/v2/reports/getReportData/?hotel_id=12354&report_type=unifiedBookingReport&start_date=2021-01-01&end_date=2021-12-31&date_filter_mode=checkout")
    // .then((res) => setRowsData(res.report_data))
    // // .then((res) => console.log(console.log(res)))
    // .then((res) => params.api.applyTransaction({add:res}))
  };

  const [load, setload] = useState(false);
  // open={openDInvoice}
  //       onClose={handleCloseDInvoice}
  const [currentLedgerId, setcurrentLedgerId] = useState(null);
  const [openDInvoice, setopenDInvoice] = useState(false);

  const handleOpenDInvoice = (id) => {
    setcurrentLedgerId(id);
    setopenDInvoice(true);
  };

  const handleCloseDInvoice = () => setopenDInvoice(false);

  const downloadInvoice = (id) => {
    setload(true);
    axios
      .get(
        `${BASE_SPRING_ENDPOINT}` +
          `core/api/v1/account-ledger/download-invoice/${groupId}/${id}/?groupId=${groupId}&hotelId=${groupId}`
      )
      .then((res) => {
        // console.log(res);
        // toast.success(res);
        // console.log("res : ", res);
        setload(false);
        setcurrentLedgerId(null);
        handleCloseDInvoice();
      })
      .catch((err) => {
        console.log(err);
        setload(false);
        setcurrentLedgerId(null);
        handleCloseDInvoice();
      });
  };

  const [updateEntry, setUpdateEntry] = useState(false);
  const handleUpdateEntryOpen = () => setUpdateEntry(true);
  const handleUpdateEnryClose = () => setUpdateEntry(false);
  const [uAmount, setUAmount] = useState();
  const [uType, setUType] = useState();
  const [uMode, setUMode] = useState();
  const [uDescription, setUDescription] = useState();
  const [currentId, setCurrentId] = useState(-1);

  const updateLedgerEntryAPI = () => {
    if (
      uDescription === null ||
      uDescription === undefined ||
      uDescription === ""
    ) {
      toast.error("Please enter the description");
      return;
    }
    // if (mType === null || mType === undefined || mType === "") {
    //   toast.error("Please enter a valid type");
    //   return;
    // }
    // if (mMode === null || mMode === undefined || mMode === "") {
    //   toast.error("Please enter a valid mode");
    //   return;
    // }
    // if(mAmount || mAmount === undefined || mAmount === ""){
    //     toast.error("Please enter a valid amount");
    //     return;
    // }
    setgenericLoad(true);
    const payload = {
      id: currentId,
      // groupId: groupId,
      description: uDescription,
      amount: uAmount,
      mode: uMode,
      type: uType,
      startDate: startDateUpdate,
      endDate: endDateUpdate,
    };
    axios
      .post(
        `${BASE_SPRING_ENDPOINT}` +
          `core/api/v1/account-ledger/update-ledger-entry`,
        payload
      )
      .then((res) => {
        toast.success("Successfully update ledger entry");
        handleUpdateEnryClose();
        setgenericLoad(false);
        const data = props?.getLedgerData();
        const datas = props?.getAccountBalance();
        getIndianPaymentMethodDetails();
        fetchPaymentMethodData();
        getNextInvoicingSchedule();
        setUAmount("");
        setUType("");
        setUMode("");
        setUDescription("");
        setStartDateUpdate("");
        setEndDateUpdate("");
      })
      .catch((err) => {
        toast.error(err);
        handleUpdateEnryClose();
        setgenericLoad(false);
        setUAmount("");
        setUType("");
        setUMode("");
        setUDescription("");
        setStartDateUpdate("");
        setUDescription("");
        console.log("The err is : ", err);
      });
  };

  const columnsInr = [
    {
      field: "id",
      headerName: "ID",
      checkboxSelection: true,
      headerCheckboxSelection: true,
      cellRendererFramework: (params) => {
        return (
          <div
            style={{
              // fontSize: "16px",
              cursor: "pointer",
              color: "#49D0C9",
              // fontWeight: "bold",
            }}
            onClick={() => {
              setCurrentId(params.value);
              setUAmount(params.data.amount);
              setUType(params.data.type);
              setUMode(params.data.mode);
              setUDescription(params.data.description);
              handleUpdateEntryOpen();
              setStartDateUpdate(
                params?.data?.start_date ||
                  new Date()?.toISOString().slice(0, 10)
              );
              setEndDateUpdate(
                params?.data?.end_date || new Date()?.toISOString().slice(0, 10)
              );
              // console.log("params?.data", params?.data);
              // onOpenMessageModal();
            }}
          >
            <u>{params.value}</u>
          </div>
        );
      },
    },
    {
      field: "date",
      headerName: "Ledger date",
      //   cellRendererFramework: (params) => cellRenderer(params),

      valueFormatter: (params) => {
        return moment(params.value).format("lll");
      },
    },
    {
      field: "start_date",
      headerName: "Start date",
      valueFormatter: (params) => {
        return moment(params.value).format("ll");
      },
    },
    {
      field: "end_date",
      headerName: "End date",
      valueFormatter: (params) => {
        return moment(params.value).format("ll");
      },
    },
    {
      field: "description",
      headerName: "Description",
      //   valueFormatter: (params) => {
      //     return params.value ? params.value : "-";
      //   },
      hide: false,
    },
    {
      field: "mode",
      headerName: "Mode",
      valueFormatter: (params) => {
        return params?.value?.toUpperCase();
      },
      //   filter: "agDateColumnFilter",
      //   filterParams: filterParams,
      hide: false,
    },
    {
      field: "type",
      headerName: "Type",
      // valueFormatter: (params) => params.value,
    },
    {
      field: "amount",
      headerName: "Amount",
      // valueFormatter: (params) => {
      //   return parseFloat(params.value).toFixed(2);
      // },
      cellRenderer: (params) => {
        // console.log("The params are : ", params.data.type);
        if (params.data.type === "DEBIT") {
          return currSymbol + " " + parseFloat(params.value).toFixed(2);
        } else {
          return currSymbol + " " + parseFloat(params.value).toFixed(2);
        }
        // const updatedBookingId =
        //   "SFBOOKING_" + String(hotelId) + "_" + String(param.value);
        // return (
        //   <Link
        //     to={{
        //       pathname: `/folio/${updatedBookingId}`,
        //       search: `?hotelId=${hotelId}`,
        //     }}
        //     target="_blank"
        //   >
        //     {param.value}
        //   </Link>
        // );
      },
    },
    { field: "status", headerName: "Status" },
    {
      field: "id",
      headerName: "Invoice generated",
      cellRendererFramework: (params) => {
        if (
          (params?.data?.mode === "SUBSCRIPTION" ||
            params?.data?.mode === "subscription") &&
          params?.data?.type === "DEBIT" &&
          params?.data?.serial_invoice_id === "" &&
          params?.data?.serial_invoice_id !== "-1"
        ) {
          if (
            params?.data?.accounting_version_change_date !== 0 &&
            params?.data?.invoice_date_epoc !== 0 &&
            params?.data?.invoice_date_epoc >
              params?.data?.accounting_version_change_date
          )
            return "Not generated";
          else return "Not generated";
        } else if (
          (params?.data?.mode === "SUBSCRIPTION" ||
            params?.data?.mode === "subscription") &&
          params?.data?.type === "DEBIT" &&
          params?.data?.serial_invoice_id !== "" &&
          params?.data?.serial_invoice_id !== "-1"
        ) {
          if (
            params?.data?.accounting_version_change_date !== 0 &&
            params?.data?.invoice_date_epoc !== 0 &&
            params?.data?.invoice_date_epoc >
              params?.data?.accounting_version_change_date
          )
            return "Generated";
          else return "Not generated";
        } else if (
          (params?.data?.mode === "SUBSCRIPTION" ||
            params?.data?.mode === "subscription") &&
          params?.data?.type === "DEBIT" &&
          params?.data?.serial_invoice_id !== "" &&
          params?.data?.serial_invoice_id === "-1"
        ) {
          if (
            params?.data?.accounting_version_change_date !== 0 &&
            params?.data?.invoice_date_epoc !== 0 &&
            params?.data?.invoice_date_epoc >
              params?.data?.accounting_version_change_date
          )
            return "Voided";
          else return "Not generated";
        } else {
          return "Not generated";
        }
      },
    },
    {
      field: "id",
      headerName: "Download Invoice",
      sortable: true,
      // checkboxSelection: true,
      // headerCheckboxSelection: true,
      cellRendererFramework: (params) => {
        // console.log("params?.data?.type : ", params?.data?.type);
        if (
          (params?.data?.mode === "SUBSCRIPTION" ||
            params?.data?.mode === "subscription") &&
          params?.data?.type === "DEBIT" &&
          params?.data?.total_outstanding >= 0 &&
          params?.data?.serial_invoice_id !== "" &&
          params?.data?.serial_invoice_id !== "-1"
        ) {
          // console.log("Inside if of cellrenderer before return");
          if (
            params?.data?.accounting_version_change_date !== 0 &&
            params?.data?.invoice_date_epoc !== 0 &&
            params?.data?.invoice_date_epoc >
              params?.data?.accounting_version_change_date
          ) {
            return (
              <Button
                variant="custom-button"
                style={{
                  // fontSize: "16px",
                  // cursor: "pointer",
                  // color: "#49D0C9",
                  // fontWeight: "bold",
                  height: "2rem",
                }}
                onClick={() => {
                  // console.log("Params are : ", params);
                  // console.log("The id is ", params.value);
                  handleOpenDInvoice(params.value);
                  // setCurrentMessage(params.value);
                  // onOpenMessageModal();
                }}
              >
                Download
              </Button>
            );
          } else {
            return "";
          }
        }
        return "";
      },
    },
  ];

  const columnsUsd = [
    {
      field: "id",
      headerName: "ID",
      checkboxSelection: true,
      headerCheckboxSelection: true,
      cellRendererFramework: (params) => {
        return (
          <div
            style={{
              // fontSize: "16px",
              cursor: "pointer",
              color: "#49D0C9",
              // fontWeight: "bold",
            }}
            onClick={() => {
              setCurrentId(params.value);
              setUAmount(params.data.amount);
              setUType(params.data.type);
              setUMode(params.data.mode);
              setUDescription(params.data.description);
              handleUpdateEntryOpen();
              // onOpenMessageModal();
            }}
          >
            <u>{params.value}</u>
          </div>
        );
      },
    },
    {
      field: "date",
      headerName: "Ledger date",
      valueFormatter: (params) => {
        return moment(params.value).format("lll");
      },
    },
    {
      field: "start_date",
      headerName: "Start date",
      valueFormatter: (params) => {
        if (params.value) {
          return moment(params.value).format("ll");
        } else {
          return "";
        }
      },
    },
    {
      field: "end_date",
      headerName: "End date",
      valueFormatter: (params) => {
        if (params.value) {
          return moment(params.value).format("ll");
        } else {
          return "";
        }
      },
    },
    {
      field: "description",
      headerName: "Description",
      //   valueFormatter: (params) => {
      //     return params.value ? params.value : "-";
      //   },
      hide: false,
    },
    {
      field: "mode",
      headerName: "Mode",
      valueFormatter: (params) => {
        return params?.value?.toUpperCase();
      },
      //   filter: "agDateColumnFilter",
      //   filterParams: filterParams,
      hide: false,
    },
    {
      field: "type",
      headerName: "Type",
      // valueFormatter: (params) => params.value,
    },
    {
      field: "amount",
      headerName: "Amount",
      // valueFormatter: (params) => {
      //   return parseFloat(params.value).toFixed(2);
      // },
      cellRenderer: (params) => {
        // console.log("The params are : ", params.data.type);
        if (params.data.type === "DEBIT") {
          return currSymbol + " " + parseFloat(params.value).toFixed(2);
        } else {
          return currSymbol + " " + parseFloat(params.value).toFixed(2);
        }
        // const updatedBookingId =
        //   "SFBOOKING_" + String(hotelId) + "_" + String(param.value);
        // return (
        //   <Link
        //     to={{
        //       pathname: `/folio/${updatedBookingId}`,
        //       search: `?hotelId=${hotelId}`,
        //     }}
        //     target="_blank"
        //   >
        //     {param.value}
        //   </Link>
        // );
      },
    },
    { field: "status", headerName: "Status" },
    {
      field: "id",
      headerName: "Invoice generated",
      cellRendererFramework: (params) => {
        if (
          (params?.data?.mode === "SUBSCRIPTION" ||
            params?.data?.mode === "subscription") &&
          params?.data?.type === "DEBIT" &&
          params?.data?.serial_invoice_id === "" &&
          params?.data?.serial_invoice_id !== "-1"
        ) {
          if (
            params?.data?.accounting_version_change_date !== 0 &&
            params?.data?.invoice_date_epoc !== 0 &&
            params?.data?.invoice_date_epoc >
              params?.data?.accounting_version_change_date
          )
            return "Not generated";
          else return "Old invoice";
        } else if (
          (params?.data?.mode === "SUBSCRIPTION" ||
            params?.data?.mode === "subscription") &&
          params?.data?.type === "DEBIT" &&
          params?.data?.serial_invoice_id !== "" &&
          params?.data?.serial_invoice_id !== "-1"
        ) {
          if (
            params?.data?.accounting_version_change_date !== 0 &&
            params?.data?.invoice_date_epoc !== 0 &&
            params?.data?.invoice_date_epoc >
              params?.data?.accounting_version_change_date
          )
            return "Generated";
          else return "Old invoice";
        } else if (
          (params?.data?.mode === "SUBSCRIPTION" ||
            params?.data?.mode === "subscription") &&
          params?.data?.type === "DEBIT" &&
          params?.data?.serial_invoice_id !== "" &&
          params?.data?.serial_invoice_id === "-1"
        ) {
          if (
            params?.data?.accounting_version_change_date !== 0 &&
            params?.data?.invoice_date_epoc !== 0 &&
            params?.data?.invoice_date_epoc >
              params?.data?.accounting_version_change_date
          )
            return "Voided";
          else return "Old invoice";
        } else {
          return "";
        }
      },
    },
    {
      field: "id",
      headerName: "Download Invoice",
      sortable: true,
      cellRendererFramework: (params) => {
        // console.log("params?.data?.type : ", params?.data?.type);
        if (
          (params?.data?.mode === "SUBSCRIPTION" ||
            params?.data?.mode === "subscription") &&
          params?.data?.type === "DEBIT"
          // params?.data?.total_outstanding >= 0
        ) {
          // if (
          //   params?.data?.accounting_version_change_date !== 0 &&
          //   params?.data?.invoice_date_epoc !== 0 &&
          //   params?.data?.invoice_date_epoc >
          //     params?.data?.accounting_version_change_date
          // ) {
          // console.log("Inside if of cellrenderer before return");
          return (
            <a
              // className="submit"
              // variant="custom-button"
              // onClick={downloadInvoice}
              type="button"
              disabled={load}
              href={
                BASE_SPRING_ENDPOINT +
                "core/api/v1/account-ledger/download-invoice/" +
                groupId +
                "/" +
                params.value +
                "/?groupId=" +
                groupId +
                "&hotelId=" +
                groupId
              }
            >
              Download Invoice
            </a>
            //   <Button
            //     variant="custom-button"
            //     style={{
            //       // fontSize: "16px",
            //       // cursor: "pointer",
            //       // color: "#49D0C9",
            //       // fontWeight: "bold",
            //       height: "2rem",
            //     }}
            //     onClick={() => {
            //       // setcurrentLedgerId(id);
            //       downloadInvoice(params.value);
            //       console.log("Params are : ", params);
            //       console.log("The id is ", params.value);
            //       // handleOpenDInvoice(params.value);
            //       // setCurrentMessage(params.value);
            //       // onOpenMessageModal();
            //     }}
            //   >
            //      Download
            //  </Button>
          );
          // } else {
          //   return "";
          // }
        }
        return "";
      },
    },
  ];

  // useEffect(() => {
  //   console.log(
  //     "accBalance : ",
  //     accBalance,
  //     " type of accBalance : ",
  //     typeof accBalance
  //   );

  //   if (accBalance) {
  //     console.log("In if block");
  //     let accBal = parseInt(accBalance);
  //     console.log("new accBal pasing : ", accBal);
  //     // debugger;
  //     if (accBal >= 0) {
  //       let flag = true;
  //       columns.map((columnObj) => {
  //         Object.entries(columnObj).map(([key, value]) => {
  //           if (key === "field" && value === "id") flag = false;
  //         });
  //       });
  //       if (flag) {
  //         columns.push({
  //           field: "id",
  //           headerName: "Download Invoice",
  //           sortable: true,
  //           // checkboxSelection: true,
  //           // headerCheckboxSelection: true,
  //           cellRendererFramework: (params) => {
  //             console.log("params?.data?.type : ", params?.data?.type);
  //             if (params?.data?.type === "DEBIT") {
  //               console.log("Inside if of cellrenderer before return");
  //               return (
  //                 <Button
  //                   variant="custom-button"
  //                   style={{
  //                     // fontSize: "16px",
  //                     // cursor: "pointer",
  //                     // color: "#49D0C9",
  //                     // fontWeight: "bold",
  //                     height: "2rem",
  //                   }}
  //                   onClick={() => {
  //                     console.log("Params are : ", params);
  //                     console.log("The id is ", params.value);
  //                     // setCurrentMessage(params.value);
  //                     // onOpenMessageModal();
  //                   }}
  //                 >
  //                   <u>Download</u>
  //                 </Button>
  //               );
  //             }
  //             return "";
  //           },
  //         });
  //       }
  //     }
  //   }
  // }, [accBalance]);

  const defaultColDefs = {
    cellStyle: { fontSize: "9px" },
    sortable: true, //sort the table
    floatingFilter: true, // getting the filter below the columns
    tooltipField: "name",
    filter: "agMultiColumnFilter", // setting the multi column filter for the table
    display: "subMenu", // how the text filter and the set filter will look like
    components: {
      agDateInput: CustomDateComponent,
    },
    resizable: true,
    cellRendererParams: {
      checkbox: true,
    },
    flex: 1,
  };

  function updatePinnedRowonDateChange() {
    let result = [
      {
        date: "Total",
        amount: accBalance,
      },
    ];
    const data = rowsData?.forEach((data) => {
      result[0].amount = accBalance;
    });
    gridApi && gridApi.setPinnedBottomRowData(result);
  }

  const onSelectionChanged = (event) => {
    // console.log("event : ", event);
    setSelectedRowsLength(event.api.getSelectedRows().length);
    setSelectedRows(event.api.getSelectedRows());
    const selectedBooking_Id = event.api.getSelectedRows()[0]?.bookingid;
    setSelectedBookingId(selectedBooking_Id);
    // console.log("Selected rows length", event.api.getSelectedRows().length)
  };

  // console.log("selectedRows : ", selectedRows);
  const [selectedInvoiceIds, setSelectedInvoiceIds] = useState(null);
  const [invoiceSet, setInvoiceSet] = useState(null);

  useEffect(() => {
    if (selectedRows && selectedRows !== undefined && selectedRows.length > 0) {
      let data = [];
      let seet = new Set();
      const daaata = selectedRows.map((res) => {
        let id;
        let type;
        let mode;
        let invoiceId;
        let invoiceDate;
        let changeDate;
        const dattta = Object.entries(res).map(([key, value]) => {
          if (key === "type") type = value;
          if (key === "serial_invoice_id") invoiceId = value;
          if (key === "mode") mode = value;
          if (key === "invoice_date_epoc") invoiceDate = value;
          if (key === "accounting_version_change_date") changeDate = value;
          if (key === "id") {
            id = value;
          }
        });
        // console.log(type);
        // console.log(id);
        // console.log(mode);
        // console.log(invoiceId);
        // console.log(invoiceDate);
        // console.log(changeDate);
        if (
          type === "DEBIT"
          // &&
          // (mode === "SUBSCRIPTION" || mode === "subscription") &&
          // invoiceId === "" &&
          // changeDate &&
          // changeDate !== 0 &&
          // changeDate < invoiceDate
        ) {
          data.push(id);
          seet.add(id);
        }
      });
      // console.log("Data : ", data);
      setSelectedInvoiceIds(data);
      setInvoiceSet(seet);
    }
  }, [selectedRows]);

  // console.log("rowsData : ", rowsData);

  const generateInvoicesAPI = () => {
    if (rowsData && rowsData !== undefined && rowsData.length > 0) {
      let allIds = [];
      const data = rowsData.map((res) => {
        let id;
        let type;
        let mode;
        let invoiceId;
        let invoiceDate;
        let changeDate;
        const dattta = Object.entries(res).map(([key, value]) => {
          if (key === "type") type = value;
          if (key === "serial_invoice_id") invoiceId = value;
          if (key === "mode") mode = value;
          if (key === "invoice_date_epoc") invoiceDate = value;
          if (key === "accounting_version_change_date") changeDate = value;
          if (key === "id") {
            id = value;
          }
        });
        // console.log(type);
        // console.log(id);
        // console.log(mode);
        // console.log(invoiceId);
        // console.log(invoiceDate);
        // console.log(changeDate);
        if (
          type === "DEBIT" &&
          (mode === "SUBSCRIPTION" || mode === "subscription") &&
          invoiceId === "" &&
          changeDate &&
          changeDate !== 0 &&
          changeDate < invoiceDate
        ) {
          allIds.push(id);
        }
      });
      // console.log("all ids : ", allIds);
      if (
        allIds &&
        selectedInvoiceIds &&
        allIds?.length > selectedInvoiceIds.length
      ) {
        // console.log("set is : ", invoiceSet);
        let selIds = selectedInvoiceIds;
        selIds.sort(function (a, b) {
          return a - b;
        });

        // console.log("sorted set ids : ", selIds);
        let leastIdSel = selIds[0];
        let maxIdSel = selIds[selIds.length - 1];

        allIds.sort(function (a, b) {
          return a - b;
        });

        // console.log("sorted all ids : ", allIds);
        // let leastIdAll = allIds[0];
        // let maxIdAll = allIds[selIds.length - 1];

        let flag = false;

        for (let i = 0; i < allIds.length; i++) {
          // debugger;
          // if (invoiceSet.has(id)) {
          //   flag = true;
          // } else if (id > leastId && id < maxId) {
          //   flag = true;
          // } else if (id < leastId) {
          //   flag = false;
          // }

          if (invoiceSet.has(allIds[i])) {
            flag = true;
          } else if (allIds[i] < maxIdSel) {
            flag = false;
            break;
          }
        }

        if (!flag) {
          toast.error(
            "Please void or generate invoice ids for previous invoices first"
          );
          return;
        }
      }
      // else {
      //   toast.success("Length is same");
      //   return;
      // }
      setgenericLoad(true);
      axios
        .post(
          `${BASE_SPRING_ENDPOINT}` +
            `core/api/v1/account-ledger/generate-invoices`,
          {
            invoice_ids: selectedInvoiceIds,
            group_id: groupId,
          }
        )
        .then((res) => {
          toast.success(res?.data);
          const data = props?.getLedgerData();
          const datas = props?.getAccountBalance();
          getIndianPaymentMethodDetails();
          fetchPaymentMethodData();
          getNextInvoicingSchedule();
          setSelectedInvoiceIds(null);
          setInvoiceSet(null);
          handleGenerateInvoicesClose();
          setgenericLoad(false);
        })
        .catch((err) => {
          toast.error("Unable to generate invoice ids, Please try again later");
          // console.log(err);
          setSelectedInvoiceIds(null);
          setInvoiceSet(null);
          handleGenerateInvoicesClose();
          setgenericLoad(false);
        });
    }
  };

  const voidInvoicesAPI = () => {
    console.log("in voidInvoicesAPI");
    if (selectedInvoiceIds && selectedInvoiceIds.length > 1) {
      toast.error("Please select only one invoice to void");
      return;
    }
    console.log(selectedInvoiceIds);
    let voidInvoiceId = selectedInvoiceIds;
    console.log(voidInvoiceId[0]);
    setgenericLoad(true);
    axios
      .get(
        `${BASE_SPRING_ENDPOINT}` +
          `core/api/v1/account-ledger/void-invoice/${groupId}/${voidInvoiceId[0]}`
      )
      .then((res) => {
        toast.success(res?.data);
        const data = props?.getLedgerData();
        const datas = props?.getAccountBalance();
        getIndianPaymentMethodDetails();
        fetchPaymentMethodData();
        getNextInvoicingSchedule();
        setSelectedInvoiceIds(null);
        setInvoiceSet(null);
        handleVoidInvoicesClose();
        setgenericLoad(false);
      })
      .catch((err) => {
        toast.error("Unable to void invoice, Please try again later");
        setSelectedInvoiceIds(null);
        setInvoiceSet(null);
        handleVoidInvoicesClose();
        setgenericLoad(false);
      });
    // toast.success("the id to void is ", voidInvoiceId);
  };

  const gridOptions = {
    pagination: true,
    onGridColumnsChanged: (params) => {
      //   console.log(
      //     "params : ",
      //     params.columnApi.columnController.displayedColumns
      //   );
    },
    // generation of the pinned bottom row data
    onFilterChanged: (params) => {
      let result = {
        date: "Total",
        amount: accBalance,
      };
      setTimeout(() => {
        params.api.forEachNodeAfterFilter((i) => {
          result.amount = accBalance;
        });
        params.api.setPinnedBottomRowData([result]);
      }, 0);
    },
    rowSelection: "multiple",
  };

  useEffect(() => {
    rowsData && updatePinnedRowonDateChange();
  });

  const onExportClick = () => {
    selectedRowsLength === 0
      ? gridApi.exportDataAsCsv()
      : gridApi.exportDataAsCsv({ onlySelected: true });
  };

  //  Exporting it in the Excel format
  const onExportExcel = () => {
    selectedRowsLength === 0
      ? gridApi.exportDataAsExcel()
      : gridApi.exportDataAsExcel({ onlySelected: true });
  };

  const getDownloadType = (dType) => {
    // console.log("download type", dType);
    dType === "1" && onExportClick();
    // dType === "2" && onOpenModal();
    dType === "3" && onExportExcel();
    // dType === "4" && onExportTally();
  };

  // Getting the number of enteries
  const [numEnteries, setNumEnteries] = useState(0);
  const [selectedNumEnteries, setSelectedNumEnteries] = useState(0);
  useEffect(() => {
    setSelectedNumEnteries(selectedRowsLength);
    setNumEnteries(rowsData?.length);
  }, [rowsData, selectedRowsLength]);

  // different date type fiters eg : checkin date, check out date, etc.
  const [filterDateType, setFilterDateType] = useState("staythrough");
  const onChangeDateFilter = (filterType) => {
    // console.log("date filter type", filterType);
    setFilterDateType(filterType);
  };

  const handleDate = (selectedDate) => {
    return moment(selectedDate).format("YYYY-MM-DD");
  };

  var date = new Date();
  const [datePicker, setDatePicker] = useState([
    {
      startDate: new Date(),
      endDate: new Date(),
      key: "selection",
    },
  ]);

  const filterParams = {
    debounceMs: 500,
    suppressAndOrCondition: true,
    comparator: function (filterLocalDateAtMidnight, cellValue) {
      if (cellValue == null) {
        return 0;
      }
      //   console.log(moment(dateParts).format("DD-MM-YYYY"))
      var dateParts = moment(cellValue).format("DD-MM-YYYY");
      const formattedDateParts = dateParts.split("-");
      var year = Number(formattedDateParts[2]);
      var month = Number(formattedDateParts[1]) - 1;
      var day = Number(formattedDateParts[0]);
      var cellDate = new Date(year, month, day);

      if (cellDate < filterLocalDateAtMidnight) {
        return -1;
      } else if (cellDate > filterLocalDateAtMidnight) {
        return 1;
      } else {
        return 0;
      }
    },
  };
  var recurringOptions = {
    key: razorpayFinalKey,
    // order_id: orderId,
    // customer_id: customerId,
    recurring: "1",
    handler: function (response) {
      // alert(response.razorpay_payment_id);
      // debugger;
      let payload = {
        group_id: groupId,
        "razorpay-pg-id": response.razorpay_payment_id,
        amount: 0,
        currency: "INR",
      };

      handleSpringDataMutationRequest(
        "POST",
        `core/api/v1/payments/razorpay/fetch-token-for-recurring-payments/?groupId=${groupId}&hotelId=${groupId}`,
        payload
      )
        .then((res) => {
          // console.log(res);
          toast.success("SuccessFully added recurring payment method");
          const data = props?.getLedgerData();
          const datas = props?.getAccountBalance();
          getIndianPaymentMethodDetails();
          fetchPaymentMethodData();
          getNextInvoicingSchedule();
          setname("");
          setemail("");
          setcontact("");
          setauthType("netbanking");
          setbeneName("");
          setaccNumber(0);
          setaccountType("savings");
          setifsc("");
          handleCloseOpenEmandateModal();
          setloading(false);
        })
        .catch((err) => {
          console.log(err);
          toast.error(
            "Error while setting up recurring payment method. Please try again later"
          );
          setname("");
          setemail("");
          setcontact("");
          setauthType("netbanking");
          setbeneName("");
          setaccNumber(0);
          setaccountType("savings");
          setifsc("");
          handleCloseOpenEmandateModal();
          setloading(false);
        });

      // alert(response.razorpay_payment_id);
      // alert(response.razorpay_order_id);
      // alert(response.razorpay_signature);
    },
    notes: {
      account_id: groupId,
    },
    theme: {
      color: "#F37254",
    },
  };

  const invokeRecurringRazorpayModal = () => {
    let rzp1 = new window.Razorpay(recurringOptions);
    rzp1.open();
    rzp1.on("payment.failed", function (response) {
      setErrorShow(true);
      setErrorMessage(response?.error?.description);
      setTimeout(() => {
        setErrorShow(false);
      }, 4000);
    });
  };
  const [loading, setloading] = useState(false);

  const createCustomerAndOrder = () => {
    setloading(true);
    if (name === null || name === undefined || name === "") {
      toast.error("Please enter the name");
      setloading(false);
      return;
    }

    if (email === null || email === undefined || email === "") {
      toast.error("Please enter the email");
      setloading(false);
      return;
    }

    if (
      contact === null ||
      contact === undefined ||
      contact === "" ||
      contact.length > 10
    ) {
      toast.error("Please enter the valid contact number");
      setloading(false);
      return;
    }

    if (beneName === null || beneName === undefined || beneName === "") {
      toast.error("Please enter the beneficiary name");
      setloading(false);
      return;
    }

    if (accNumber === null || accNumber === undefined || accNumber === 0) {
      toast.error("Please enter the account number");
      setloading(false);
      return;
    }

    if (ifsc === null || ifsc === undefined || ifsc === "") {
      toast.error("Please enter the ifsc code");
      setloading(false);
      return;
    }

    let payload = {
      name: name,
      email: email,
      contact: contact,
      auth_type: "netbanking",
      beneficiary_name: beneName,
      account_number: accNumber,
      account_type: accountType,
      ifsc_code: ifsc,
    };

    handleSpringDataMutationRequest(
      "POST",
      `core/api/v1/payments/razorpay/create-customer-order-recurring-payments/?groupId=${groupId}&hotelId=${groupId}`,
      payload
    )
      .then((res) => {
        setcustomerId(res?.customer?.id);
        setorderId(res?.order?.id);
        // var recurringOptions = {
        //   key: razorpayFinalKey,
        //   order_id: orderId,
        //   customer_id: customerId,
        recurringOptions["customer_id"] = res?.customer?.id;
        recurringOptions["order_id"] = res?.order?.id;
        // doWork();
        setTimeout(() => {
          invokeRecurringRazorpayModal();
        }, 1000);
        // console.log(res);
      })
      .catch((err) => {
        console.log(err.response.data);
        toast.error("Please enter the valid details and try again");
        setname("");
        setemail("");
        setcontact("");
        setauthType("netbanking");
        setbeneName("");
        setaccNumber(0);
        setaccountType("savings");
        setifsc("");

        handleCloseOpenEmandateModal();
        setloading(false);
      });
  };

  const [state, setState] = useState({ right: false });

  // console.log("pmDetails : ", pmDetails);
  // console.log("nextPaySchedule : ", nextPaySchedule);
  // console.log("hotelCurr : ", hotelCurr);

  const toggleDrawer = (anchor, open) => (event) => {
    if (
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }
    setState({ ...state, [anchor]: open });
  };

  const getUrl = () => {
    if (process.env.REACT_APP_ENV_PAY === "PROD") {
      return "https://app.stayflexi.com/";
    } else if (process.env.REACT_APP_ENV_PAY === "PREPROD") {
      return "https://preprod-app.stayflexi.com/";
    } else {
      return "https://beta.app.stayflexi.com/";
    }
  };
  const [pendingDays, setpendingDays] = useState(0);
  const [showPendingDays, setshowPendingDays] = useState(false);
  useEffect(() => {
    if (
      groupId !== null &&
      groupId !== undefined &&
      groupId !== "" &&
      hotelCurr === "INR"
    ) {
      handleSpringDataRequest(
        `web-api/get-payment-dues-amount?groupId=${groupId}&hotel_id=${groupId}&hotelId=${groupId}`
      )
        .then((res) => {
          if (res !== null && res !== undefined) {
            if (res.pendingAmount < 0) {
              setshowPendingDays(true);
              setpendingDays(res.daysDiff);
            }
          }
        })
        .catch((err) => console.error(err));
    }
  }, [groupId, hotelCurr]);

  console.log("pendingDays", pendingDays);

  return (
    <div>
      {rowsData === null ? (
        <ReportsLoader />
      ) : (
        <div>
          {nextPaySchedule &&
            hotelCurr === "INR" &&
            indPaymentMethod === false && (
              <div>
                <Collapse in={banneropen}>
                  <Alert
                    icon={false}
                    action={
                      <IconButton
                        aria-label="close"
                        color="inherit"
                        size="small"
                        onClick={() => {
                          setBannerOpen(false);
                        }}
                      >
                        <CloseIcon fontSize="inherit" />
                      </IconButton>
                    }
                    sx={{ mb: 2 }}
                  >
                    Your next invoice will be automatically generated on{" "}
                    {nextPaySchedule?.next_schedule_date}, covering the period
                    from {nextPaySchedule?.schedule_start} to{" "}
                    {nextPaySchedule?.schedule_end}, for a total of {currSymbol}{" "}
                    {nextPaySchedule?.amount}. Please click on add payment
                    method button to enroll for autopay.
                  </Alert>
                </Collapse>
              </div>
            )}

          {nextPaySchedule &&
            hotelCurr === "INR" &&
            indPaymentMethod === true && (
              <div>
                <Collapse in={banneropen}>
                  <Alert
                    icon={false}
                    action={
                      <IconButton
                        aria-label="close"
                        color="inherit"
                        size="small"
                        onClick={() => {
                          setBannerOpen(false);
                        }}
                      >
                        <CloseIcon fontSize="inherit" />
                      </IconButton>
                    }
                    sx={{ mb: 2 }}
                  >
                    Your next invoice will be automatically generated on{" "}
                    {nextPaySchedule?.next_schedule_date}, covering the period
                    from {nextPaySchedule?.schedule_start} to{" "}
                    {nextPaySchedule?.schedule_end}, for a total of {currSymbol}{" "}
                    {nextPaySchedule?.amount}.
                    <a
                      onClick={() => {
                        handleindPayOpen();
                      }}
                      style={{ cursor: "pointer" }}
                    >
                      {" "}
                      <b>
                        <u>Click here</u>
                      </b>{" "}
                    </a>
                    to view subscribed payment menthod.
                  </Alert>
                </Collapse>
              </div>
            )}

          {nextPaySchedule &&
            hotelCurr !== "INR" &&
            pmDetails &&
            pmDetails !== undefined && (
              <div>
                <Collapse in={banneropen}>
                  <Alert
                    icon={false}
                    action={
                      <IconButton
                        aria-label="close"
                        color="inherit"
                        size="small"
                        onClick={() => {
                          setBannerOpen(false);
                        }}
                      >
                        <CloseIcon fontSize="inherit" />
                      </IconButton>
                    }
                    sx={{ mb: 2 }}
                  >
                    Your next invoice will be automatically generated on{" "}
                    {nextPaySchedule?.next_schedule_date}, covering the period
                    from {nextPaySchedule?.schedule_start} to{" "}
                    {nextPaySchedule?.schedule_end}, for a total of {currSymbol}{" "}
                    {nextPaySchedule?.amount}. Visit Payment Methods page to
                    manage subscribed payment menthod.
                  </Alert>
                </Collapse>
              </div>
            )}

          {nextPaySchedule &&
            hotelCurr !== "INR" &&
            (pmDetails === null || pmDetails === undefined) && (
              <div>
                <Collapse in={banneropen}>
                  <Alert
                    icon={false}
                    action={
                      <IconButton
                        aria-label="close"
                        color="inherit"
                        size="small"
                        onClick={() => {
                          setBannerOpen(false);
                        }}
                      >
                        <CloseIcon fontSize="inherit" />
                      </IconButton>
                    }
                    sx={{ mb: 2 }}
                  >
                    Your next invoice will be automatically generated on{" "}
                    {nextPaySchedule?.next_schedule_date}, covering the period
                    from {nextPaySchedule?.schedule_start} to{" "}
                    {nextPaySchedule?.schedule_end}, for a total of {currSymbol}{" "}
                    {nextPaySchedule?.amount}. Please add a payment method to
                    enroll for autopay.
                  </Alert>
                </Collapse>
              </div>
            )}

          <div
            style={{
              display: "flex",

              justifyContent: "end",
            }}
          >
            <div className="d-flex flex-column">
              {accBalance && (
                <Typography
                  variant="h6"
                  style={{ fontWeight: "400", marginBottom: "1rem" }}
                >
                  Current Outstanding : {currSymbol + " " + accBalance}
                </Typography>
              )}
              {hotelCurr === "INR" && showPendingDays && (
                <Typography
                  variant="h6"
                  style={{ fontWeight: "400", marginBottom: "1rem" }}
                >
                  Pending from : {pendingDays} days
                </Typography>
              )}
            </div>
          </div>

          <div>
            <Stack className="agGridWrapr position-relative">
              <Stack
                spacing={2}
                className="agGridTableWrapper position-relative w-100"
              >
                <Stack
                  direction="row"
                  className="position-relative w-100 justify-content-between"
                >
                  <Stack direction="row" spacing={2}>
                    <TextField
                      id="outlined-basic"
                      onChange={onFilterTextChange}
                      label="Search"
                      size="small"
                    />
                    <Box sx={{ minWidth: 120 }}>
                      <FormControl fullWidth size="small">
                        <InputLabel id="demo-simple-select-label">
                          Pages
                        </InputLabel>
                        <Select
                          labelId="demo-simple-select-label"
                          id="demo-simple-select"
                          value={gridApi?.paginationPageSize}
                          label="Pages"
                          onChange={(e) => onPaginationChange(e.target.value)}
                        >
                          <MenuItem value={"10"}>10</MenuItem>
                          <MenuItem value={"20"}>20</MenuItem>
                          <MenuItem value={"50"}>50</MenuItem>
                          <MenuItem value={"100"}>100</MenuItem>
                        </Select>
                      </FormControl>
                    </Box>

                    <Box sx={{ minWidth: 120 }}>
                      <FormControl fullWidth size="small">
                        <InputLabel id="demo-simple-select-label">
                          Export as
                        </InputLabel>
                        <Select
                          labelId="demo-simple-select-label"
                          id="demo-simple-select"
                          value={"0"}
                          label="Export as"
                          onChange={(e) => getDownloadType(e.target.value)}
                        >
                          <MenuItem value={"0"}>Select option</MenuItem>
                          <MenuItem value={"1"}>Export as CSV</MenuItem>
                        </Select>
                      </FormControl>
                    </Box>
                    {(!accountInfo?.accounting_version ||
                      accountInfo?.accounting_version === 1) && (
                      <Button
                        variant="custom-button"
                        style={{
                          fontWeight: "400",
                          marginLeft: "2rem",
                          marginBottom: "1rem",
                          marginTop: "4px",
                          height: "2rem",
                        }}
                        onClick={() => {
                          handleOpenAccountingVersionModalOpen();
                        }}
                      >
                        Change accounting version
                      </Button>
                    )}
                    {(nextPaySchedule === null ||
                      nextPaySchedule === undefined) && (
                      <Button
                        variant="custom-button"
                        style={{
                          fontWeight: "400",
                          marginLeft: "2rem",
                          marginBottom: "1rem",
                          marginTop: "4px",
                          height: "2rem",
                        }}
                        onClick={() => {
                          handleOpenCreateBillingSchedule();
                        }}
                      >
                        Create billing schedule
                      </Button>
                    )}
                    {nextPaySchedule && (
                      <Button
                        variant="custom-button"
                        style={{
                          fontWeight: "400",
                          marginLeft: "2rem",
                          marginBottom: "1rem",
                          marginTop: "4px",
                          height: "2rem",
                        }}
                        onClick={() => {
                          handleOpenDeleteBillingSchedule();
                        }}
                      >
                        Delete billing schedule
                      </Button>
                    )}
                    <Button
                      variant="custom-button"
                      style={{
                        fontWeight: "400",
                        marginLeft: "2rem",
                        marginBottom: "1rem",
                        marginTop: "4px",
                        height: "2rem",
                      }}
                      onClick={() => {
                        handleOpenCreateManualEntry();
                      }}
                    >
                      Add manual ledger entry
                    </Button>

                    {hotelCurr && hotelCurr === "INR" && (
                      <Button
                        variant="custom-button"
                        style={{
                          fontWeight: "400",
                          marginLeft: "2rem",
                          marginBottom: "1rem",
                          marginTop: "4px",
                          height: "2rem",
                        }}
                        onClick={() => {
                          handleOpenGenerateInvoices();
                        }}
                      >
                        Generate invoices
                      </Button>
                    )}

                    {hotelCurr && hotelCurr === "INR" && (
                      <Button
                        variant="custom-button"
                        style={{
                          fontWeight: "400",
                          marginLeft: "2rem",
                          marginBottom: "1rem",
                          marginTop: "4px",
                          height: "2rem",
                        }}
                        onClick={() => {
                          handleOpenVoidInvoices();
                        }}
                      >
                        Void invoice
                      </Button>
                    )}

                    {hotelCurr && hotelCurr === "INR" && (
                      <Button
                        variant="custom-button"
                        style={{
                          fontWeight: "400",
                          marginLeft: "2rem",
                          marginBottom: "1rem",
                          marginTop: "4px",
                          height: "2rem",
                        }}
                        onClick={() => {
                          handleOpenshowPaymentLink();
                        }}
                      >
                        Copy payment link
                      </Button>
                    )}
                  </Stack>
                </Stack>
                {rowsData && hotelCurr && hotelCurr === "INR" ? (
                  <Stack className="agTable">
                    <Stack className="ag-theme-material" sx={{ height: 800 }}>
                      <AgGridReact
                        className="agGridTable"
                        rowData={rowsData}
                        onGridReady={onGridReady}
                        columnDefs={columnsInr}
                        defaultColDef={defaultColDefs}
                        enableBrowserTooltips={true}
                        onSelectionChanged={onSelectionChanged}
                        enableMultiRowDragging={false}
                        rowMultiSelectWithClick={false}
                        paginationPageSize={50}
                        alwaysShowBothConditions={true}
                        rowSelection={rowSelectionType}
                        suppressPivotMode={true}
                        suppressColumnMove={true}
                        suppressRowGroups={true}
                        sideBar={sideBar()}
                        groupMultiAutoColumn={true}
                        statusBar={{
                          statusPanels: [
                            {
                              statusPanel:
                                "agTotalAndFilteredRowCountComponent",
                              align: "left",
                            },
                            {
                              statusPanel: "agTotalRowCountComponent",
                              align: "center",
                            },
                            { statusPanel: "agFilteredRowCountComponent" },
                            { statusPanel: "agSelectedRowCountComponent" },
                            { statusPanel: "agAggregationComponent" },
                          ],
                        }}
                        gridOptions={gridOptions}
                      />
                    </Stack>
                  </Stack>
                ) : (
                  <Stack className="agTable">
                    <Stack className="ag-theme-material" sx={{ height: 800 }}>
                      <AgGridReact
                        className="agGridTable"
                        rowData={rowsData}
                        onGridReady={onGridReady}
                        columnDefs={columnsUsd}
                        defaultColDef={defaultColDefs}
                        enableBrowserTooltips={true}
                        onSelectionChanged={onSelectionChanged}
                        enableMultiRowDragging={false}
                        rowMultiSelectWithClick={false}
                        paginationPageSize={50}
                        alwaysShowBothConditions={true}
                        rowSelection={rowSelectionType}
                        suppressPivotMode={true}
                        suppressColumnMove={true}
                        suppressRowGroups={true}
                        sideBar={sideBar()}
                        groupMultiAutoColumn={true}
                        statusBar={{
                          statusPanels: [
                            {
                              statusPanel:
                                "agTotalAndFilteredRowCountComponent",
                              align: "left",
                            },
                            {
                              statusPanel: "agTotalRowCountComponent",
                              align: "center",
                            },
                            { statusPanel: "agFilteredRowCountComponent" },
                            { statusPanel: "agSelectedRowCountComponent" },
                            { statusPanel: "agAggregationComponent" },
                          ],
                        }}
                        gridOptions={gridOptions}
                      />
                    </Stack>
                  </Stack>
                )}
              </Stack>
            </Stack>
          </div>
        </div>
      )}
      <Modal
        open={openindPay}
        onClose={handleindPayClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box className="settleDuesModal" sx={modalStyle}>
          <div className="settleDuesWrapper">
            <div className="settleDuesHeader">
              <div className="settleDuesHeading">Payment method details</div>
              <div className="close" onClick={() => handleindPayClose()}>
                X
              </div>
            </div>
            <div className="amountWrapper">
              {indPaymentDetail && (
                <div className="fieldWrapper">
                  <div style={{ display: "flex" }}>
                    <p style={{ marginRight: "1rem" }}>Payment method type</p>
                    <p>-</p>
                    <p style={{ marginLeft: "1rem" }}>Net-Banking</p>
                  </div>

                  {/* <div style={{ display: "flex" }}>
                    <p style={{ marginRight: "1rem" }}>Account name</p>
                    <p>-</p>
                    <p style={{ marginLeft: "1rem" }}>
                      {indPaymentDetail?.payment_method}
                    </p>
                  </div> */}

                  <div style={{ display: "flex" }}>
                    <p style={{ marginRight: "1rem" }}>Account last 4</p>
                    <p>-</p>
                    <p style={{ marginLeft: "1rem" }}>
                      {indPaymentDetail?.bank_last4}
                    </p>
                  </div>
                </div>
              )}
              <div className="settleDuesSubmitBtns">
                <Button
                  className="cancelBtn"
                  onClick={() => handleindPayClose()}
                >
                  Close
                </Button>
              </div>
            </div>
          </div>
        </Box>
      </Modal>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box className="settleDuesModal" sx={modalStyle}>
          <div className="settleDuesWrapper">
            <div className="settleDuesHeader">
              <div className="settleDuesHeading">Confirm the details</div>
              <div className="close" onClick={() => handleClose()}>
                X
              </div>
            </div>
            <div className="amountWrapper">
              <div className="fieldWrapper">
                <TextField
                  required
                  id="outlined-name"
                  label="Enter the amount"
                  name="deposit_amount"
                  value={depositAmt}
                  onChange={(e) =>
                    /^[0-9.]*$/.test(e.target.value)
                      ? setDepositAmt(e.target.value)
                      : setDepositAmt(depositAmt)
                  }
                  sx={{ width: "100%" }}
                  type={"text"}
                />
              </div>

              {pmDetails && (
                <div className="fieldWrapper">
                  <Typography
                    variant="h5"
                    style={{ fontWeight: "600", marginBottom: "1rem" }}
                  >
                    Payment method details
                  </Typography>
                  {pmDetails?.payment_method_type === "account" ? (
                    <div style={{ display: "flex" }}>
                      <p style={{ marginRight: "1rem" }}>Payment method type</p>
                      <p>-</p>
                      <p style={{ marginLeft: "1rem" }}>Account</p>
                    </div>
                  ) : (
                    <div style={{ display: "flex" }}>
                      <p style={{ marginRight: "1rem" }}>Payment method type</p>
                      <p>-</p>
                      <p style={{ marginLeft: "1rem" }}>
                        {pmDetails?.card_type === "credit"
                          ? "Credit card"
                          : "Debit card"}
                      </p>
                    </div>
                  )}
                  {pmDetails?.payment_method_type === "account" ? (
                    <div style={{ display: "flex" }}>
                      <p style={{ marginRight: "1rem" }}>Account last 4</p>
                      <p>-</p>
                      <p style={{ marginLeft: "1rem" }}>
                        {pmDetails?.ach_account_last4}
                      </p>
                    </div>
                  ) : (
                    <div style={{ display: "flex" }}>
                      <p style={{ marginRight: "1rem" }}>Card last 4</p>
                      <p>-</p>
                      <p style={{ marginLeft: "1rem" }}>
                        {pmDetails?.card_last4}
                      </p>
                    </div>
                  )}

                  {pmDetails?.payment_method_type === "account" ? (
                    <div style={{ display: "flex" }}>
                      <p style={{ marginRight: "1rem" }}>Account type</p>
                      <p>-</p>
                      <p style={{ marginLeft: "1rem" }}>
                        {pmDetails?.ach_account_type}
                      </p>
                    </div>
                  ) : (
                    <div style={{ display: "flex" }}>
                      <p style={{ marginRight: "1rem" }}>Card issuer</p>
                      <p>-</p>
                      <p style={{ marginLeft: "1rem" }}>
                        {pmDetails?.card_issuer.toUpperCase()}
                      </p>
                    </div>
                  )}
                </div>
              )}
              <div className="settleDuesSubmitBtns">
                <Button className="cancelBtn" onClick={() => handleClose()}>
                  Cancel
                </Button>
                <Button
                  variant="custom-button"
                  onClick={handleFortisPayment}
                  disabled={depositLoading || depositAmt <= 0}
                >
                  {depositLoading ? (
                    <CircularProgress
                      size={15}
                      color="inherit"
                      sx={{ ml: 1.5 }}
                    />
                  ) : (
                    "Pay"
                  )}
                </Button>
              </div>
            </div>
          </div>
        </Box>
      </Modal>
      <Modal
        open={openDInvoice}
        onClose={handleCloseDInvoice}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box className="settleDuesModal" sx={modalStyle}>
          <div className="settleDuesWrapper">
            <div className="settleDuesHeader">
              <div className="settleDuesHeading">Download invoice</div>
              <div className="close" onClick={() => handleCloseDInvoice()}>
                X
              </div>
            </div>
            <div className="amountWrapper">
              <div className="fieldWrapper">
                <Typography>Do you want to download the invoice</Typography>
              </div>

              <div className="settleDuesSubmitBtns">
                <Button
                  className="cancelBtn"
                  onClick={() => handleCloseDInvoice()}
                >
                  Cancel
                </Button>
                <a
                  type="button"
                  disabled={load}
                  href={
                    BASE_SPRING_ENDPOINT +
                    "core/api/v1/account-ledger/download-invoice/" +
                    groupId +
                    "/" +
                    currentLedgerId +
                    "/?groupId=" +
                    groupId +
                    "&hotelId=" +
                    groupId
                  }
                >
                  {load ? (
                    <CircularProgress
                      size={15}
                      color="inherit"
                      sx={{ ml: 1.5 }}
                    />
                  ) : (
                    "Download"
                  )}
                </a>
              </div>
            </div>
          </div>
        </Box>
      </Modal>
      <Modal
        open={openAmountModal}
        onClose={handleAmountClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box className="settleDuesModal" sx={modalStyle}>
          <div className="settleDuesWrapper">
            <div className="settleDuesHeader">
              <div className="settleDuesHeading">Confirm the details</div>
              <div className="close" onClick={() => handleAmountClose()}>
                X
              </div>
            </div>
            <div className="amountWrapper">
              <div className="fieldWrapper">
                <TextField
                  required
                  id="outlined-name"
                  label="Enter the amount"
                  name="deposit_amount"
                  value={razorpayAmount}
                  onChange={(e) => {
                    /^[0-9.]*$/.test(e.target.value)
                      ? setRazorpayAmount(e.target.value)
                      : setRazorpayAmount(razorpayAmount);
                  }}
                  sx={{ width: "100%" }}
                  type={"text"}
                />
              </div>

              <div className="settleDuesSubmitBtns">
                <Button
                  className="cancelBtn"
                  onClick={() => handleAmountClose()}
                >
                  Cancel
                </Button>
                <Button
                  variant="custom-button"
                  onClick={() => {
                    handleAmountClose();
                    let amt = razorpayAmount.toString().split(".");
                    let final = amt[0];
                    options["amount"] = final * 100 + "";

                    setRazorpayAmount(final * 100 + "");
                    invokeRazorpayModal();
                  }}
                  disabled={razorpayAmount <= 0}
                >
                  Pay
                </Button>
              </div>
            </div>
          </div>
        </Box>
      </Modal>
      <Modal
        open={openEmandateModal}
        onClose={handleCloseOpenEmandateModal}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box className="settleDuesModal" sx={modalStyle}>
          <div className="settleDuesWrapper">
            <div className="settleDuesHeader">
              <div className="settleDuesHeading">Enter the details</div>
              <div
                className="close"
                onClick={() => {
                  setloading(false);
                  handleCloseOpenEmandateModal();
                }}
              >
                X
              </div>
            </div>
            <div className="amountWrapper">
              <div className="fieldWrapper">
                <TextField
                  size="small"
                  required
                  id="outlined-name"
                  label="Enter name"
                  name="deposit_amount"
                  value={name}
                  onChange={(e) => {
                    setname(e.target.value);
                  }}
                  sx={{ width: "100%" }}
                  type={"text"}
                />
              </div>
            </div>
            <div className="amountWrapper">
              <div className="fieldWrapper">
                <TextField
                  size="small"
                  required
                  id="outlined-name"
                  label="Enter email"
                  name="deposit_amount"
                  value={email}
                  onChange={(e) => {
                    setemail(e.target.value);
                  }}
                  sx={{ width: "100%" }}
                  type={"text"}
                />
              </div>
            </div>
            <div className="amountWrapper">
              <div className="fieldWrapper">
                <TextField
                  size="small"
                  required
                  id="outlined-name"
                  label="Enter contact number"
                  name="deposit_amount"
                  value={contact}
                  onChange={(e) => {
                    /^[0-9.]*$/.test(e.target.value)
                      ? setcontact(e.target.value)
                      : setcontact(contact);
                  }}
                  sx={{ width: "100%" }}
                  type={"text"}
                />
              </div>
            </div>
            <div className="amountWrapper">
              <div className="fieldWrapper">
                <TextField
                  size="small"
                  required
                  id="outlined-name"
                  label="Enter beneficiary name"
                  name="deposit_amount"
                  value={beneName}
                  onChange={(e) => {
                    setbeneName(e.target.value);
                  }}
                  sx={{ width: "100%" }}
                  type={"text"}
                />
              </div>
            </div>
            <div className="amountWrapper">
              <div className="fieldWrapper">
                <TextField
                  size="small"
                  required
                  id="outlined-name"
                  label="Enter account number"
                  name="deposit_amount"
                  value={accNumber}
                  onChange={(e) => {
                    /^[0-9.]*$/.test(e.target.value)
                      ? setaccNumber(e.target.value)
                      : setaccNumber(accNumber);
                  }}
                  sx={{ width: "100%" }}
                  type={"text"}
                />
              </div>
            </div>
            <div className="amountWrapper">
              <div className="fieldWrapper">
                <FormControl
                  fullWidth
                  sx={{ width: "12rem", marginRight: "0.5rem" }}
                >
                  <InputLabel id="demo-simple-select-label">
                    Account type
                  </InputLabel>
                  <Select
                    size="small"
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    value={accountType}
                    onChange={(e) => {
                      setaccountType(e.target.value);
                    }}
                    label="Account type"
                  >
                    <MenuItem value={"savings"}>Savings</MenuItem>
                    <MenuItem value={"current"}>Current</MenuItem>
                  </Select>
                </FormControl>
              </div>
            </div>
            <div className="amountWrapper">
              <div className="fieldWrapper">
                <TextField
                  size="small"
                  required
                  id="outlined-name"
                  label="Enter ifsc"
                  name="deposit_amount"
                  value={ifsc}
                  onChange={(e) => {
                    setifsc(e.target.value);
                  }}
                  sx={{ width: "100%" }}
                  type={"text"}
                />
              </div>

              <div className="settleDuesSubmitBtns">
                <Button
                  className="cancelBtn"
                  onClick={() => {
                    setloading(false);
                    handleCloseOpenEmandateModal();
                  }}
                >
                  Cancel
                </Button>
                <Button
                  variant="custom-button"
                  onClick={() => {
                    createCustomerAndOrder();
                  }}
                >
                  {loading ? (
                    <CircularProgress
                      size={15}
                      color="inherit"
                      sx={{ ml: 1.5 }}
                    />
                  ) : (
                    "Submit"
                  )}
                </Button>
              </div>
            </div>
          </div>
        </Box>
      </Modal>
      <Modal
        open={openAccountingVersionModal}
        onClose={handleOpenAccountingVersionModalClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box className="settleDuesModal" sx={modalStyle}>
          <div className="settleDuesWrapper">
            <div className="settleDuesHeader">
              <div className="settleDuesHeading">Change accounting version</div>
              <div
                className="close"
                onClick={() => handleOpenAccountingVersionModalClose()}
              >
                X
              </div>
            </div>
            <div className="amountWrapper">
              <div className="fieldWrapper">
                <Typography>
                  Do you want to change accounting version to V2?
                </Typography>
              </div>

              <div className="settleDuesSubmitBtns">
                <Button
                  className="cancelBtn"
                  onClick={() => handleOpenAccountingVersionModalClose()}
                >
                  Cancel
                </Button>
                <Button
                  variant="custom-button"
                  onClick={() => {
                    changeAccountingVersion();
                  }}
                >
                  {genericLoad ? (
                    <CircularProgress
                      size={15}
                      color="inherit"
                      sx={{ ml: 1.5 }}
                    />
                  ) : (
                    "Confirm"
                  )}
                </Button>
              </div>
            </div>
          </div>
        </Box>
      </Modal>
      <Modal
        open={createBillingSchedule}
        onClose={handleCreateBillingScheduleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box className="settleDuesModal" sx={modalStyle}>
          <div className="settleDuesWrapper">
            <div className="settleDuesHeader">
              <div className="settleDuesHeading">Create billing schedule</div>
              <div
                className="close"
                onClick={() => handleCreateBillingScheduleClose()}
              >
                X
              </div>
            </div>
            <div className="amountWrapper">
              <div className="fieldWrapper">
                <Typography>Billing start date</Typography>
                <div className="datePicker">
                  <TextField
                    className="mt-3"
                    style={{ width: "20rem" }}
                    type="date"
                    id="outlined-basic"
                    label="Billing start date *"
                    variant="outlined"
                    size="small"
                    value={pickDate}
                    onChange={handlePickDateChange}
                  />
                  {/* <LocalizationProvider dateAdapter={AdapterDateFns}>
                    <DesktopDatePicker
                      size="small"
                      label="Billing start date"
                      inputFormat="dd/MM/yyyy"
                      value={pickDate}
                      onChange={handlePickDateChange}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          id="outlined-basic"
                          className="mt-3"
                          style={{ width: "20rem" }}
                          size="small"
                        />
                      )}
                    />
                  </LocalizationProvider> */}
                </div>
                {dayError && (
                  <p style={{ fontSize: "15px", color: "red" }}>
                    The billing schedule can only start from 1st day of month
                  </p>
                )}
              </div>

              <FormControl style={{ marginTop: "10px" }}>
                <FormLabel id="demo-row-radio-buttons-group-label">
                  Billing type
                </FormLabel>
                <RadioGroup
                  row
                  aria-labelledby="demo-row-radio-buttons-group-label"
                  name="row-radio-buttons-group"
                  value={terminalId}
                  onChange={(e) => setterminalId(e.target.value)}
                >
                  <FormControlLabel
                    value="PRE"
                    control={<Radio />}
                    label="Pre"
                  />
                  <FormControlLabel
                    value="POST"
                    control={<Radio />}
                    label="Post"
                  />
                </RadioGroup>
              </FormControl>

              <div className="settleDuesSubmitBtns">
                <Button
                  className="cancelBtn"
                  onClick={() => handleCreateBillingScheduleClose()}
                >
                  Cancel
                </Button>
                <Button
                  variant="custom-button"
                  onClick={() => {
                    createBillingScheduleAPI();
                  }}
                >
                  {genericLoad ? (
                    <CircularProgress
                      size={15}
                      color="inherit"
                      sx={{ ml: 1.5 }}
                    />
                  ) : (
                    "Create"
                  )}
                </Button>
              </div>
            </div>
          </div>
        </Box>
      </Modal>
      <Modal
        open={deleteBillingSchedule}
        onClose={handleDeleteBillingScheduleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box className="settleDuesModal" sx={modalStyle}>
          <div className="settleDuesWrapper">
            <div className="settleDuesHeader">
              <div className="settleDuesHeading">Delete billing schedule</div>
              <div
                className="close"
                onClick={() => handleDeleteBillingScheduleClose()}
              >
                X
              </div>
            </div>
            <div className="amountWrapper">
              <div className="fieldWrapper">
                <Typography>
                  Do you want to delete the billing schedule for account?
                </Typography>
              </div>

              <div className="settleDuesSubmitBtns">
                <Button
                  className="cancelBtn"
                  onClick={() => handleDeleteBillingScheduleClose()}
                >
                  Cancel
                </Button>
                <Button
                  variant="custom-button"
                  onClick={() => {
                    deleteBillingScheduleAPI();
                  }}
                >
                  {genericLoad ? (
                    <CircularProgress
                      size={15}
                      color="inherit"
                      sx={{ ml: 1.5 }}
                    />
                  ) : (
                    "Confirm"
                  )}
                </Button>
              </div>
            </div>
          </div>
        </Box>
      </Modal>
      <Modal
        open={generateInvoices}
        onClose={handleGenerateInvoicesClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box className="settleDuesModal" sx={modalStyle}>
          <div className="settleDuesWrapper">
            <div className="settleDuesHeader">
              <div className="settleDuesHeading">Generate invoices</div>
              <div
                className="close"
                onClick={() => handleGenerateInvoicesClose()}
              >
                X
              </div>
            </div>
            <div className="amountWrapper">
              <div className="fieldWrapper">
                <Typography>
                  Do you want to generate invoices for below selected invoices?
                </Typography>

                {selectedInvoiceIds && selectedInvoiceIds?.length > 0 && (
                  <div
                    className="selectedRoomIdsChip"
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      width: "100%",
                      justifyContent: "flex-start",
                      marginTop: "1rem",
                    }}
                  >
                    <div
                      className="selectedRoomIds"
                      style={{
                        marginTop: "auto",
                        marginBottom: "auto",
                        display: "flex",
                        flexDirection: "row",
                        justifyContent: "flex-start",
                      }}
                    >
                      {selectedInvoiceIds.map((item) => (
                        <div
                          style={{
                            marginLeft: "5px",
                            position: "relative",
                            width: "100%",
                            display: "flex",
                            flexWrap: "wrap",
                          }}
                        >
                          <Chip label={item} onClick={() => {}} />
                        </div>
                      ))}
                    </div>
                  </div>
                )}
              </div>

              <div className="settleDuesSubmitBtns">
                <Button
                  className="cancelBtn"
                  onClick={() => handleGenerateInvoicesClose()}
                >
                  Cancel
                </Button>
                {selectedInvoiceIds && selectedInvoiceIds?.length > 0 && (
                  <Button
                    variant="custom-button"
                    onClick={() => {
                      generateInvoicesAPI();
                    }}
                  >
                    {genericLoad ? (
                      <CircularProgress
                        size={15}
                        color="inherit"
                        sx={{ ml: 1.5 }}
                      />
                    ) : (
                      "Generate"
                    )}
                  </Button>
                )}
              </div>
            </div>
          </div>
        </Box>
      </Modal>
      <Modal
        open={voidInvoices}
        onClose={handleVoidInvoicesClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box className="settleDuesModal" sx={modalStyle}>
          <div className="settleDuesWrapper">
            <div className="settleDuesHeader">
              <div className="settleDuesHeading">Void invoices</div>
              <div className="close" onClick={() => handleVoidInvoicesClose()}>
                X
              </div>
            </div>
            <div className="amountWrapper">
              <div className="fieldWrapper">
                <Typography>
                  Do you want to void below selected invoice?
                </Typography>

                {selectedInvoiceIds && selectedInvoiceIds?.length > 0 && (
                  <div
                    className="selectedRoomIdsChip"
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      width: "100%",
                      justifyContent: "flex-start",
                      marginTop: "1rem",
                    }}
                  >
                    <div
                      className="selectedRoomIds"
                      style={{
                        marginTop: "auto",
                        marginBottom: "auto",
                        display: "flex",
                        flexDirection: "row",
                        justifyContent: "flex-start",
                      }}
                    >
                      {selectedInvoiceIds.map((item) => (
                        <div
                          style={{
                            marginLeft: "5px",
                            position: "relative",
                            width: "100%",
                            display: "flex",
                            flexWrap: "wrap",
                          }}
                        >
                          <Chip label={item} onClick={() => {}} />
                        </div>
                      ))}
                    </div>
                  </div>
                )}
              </div>

              <div className="settleDuesSubmitBtns">
                <Button
                  className="cancelBtn"
                  onClick={() => handleVoidInvoicesClose()}
                >
                  Cancel
                </Button>
                {selectedInvoiceIds && selectedInvoiceIds?.length > 0 && (
                  <Button
                    variant="custom-button"
                    onClick={() => {
                      voidInvoicesAPI();
                    }}
                  >
                    {genericLoad ? (
                      <CircularProgress
                        size={15}
                        color="inherit"
                        sx={{ ml: 1.5 }}
                      />
                    ) : (
                      "Void"
                    )}
                  </Button>
                )}
              </div>
            </div>
          </div>
        </Box>
      </Modal>
      <Modal
        open={createManualEntry}
        onClose={handleCreateManualEntryClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box className="settleDuesModal" sx={modalStyle}>
          <div className="settleDuesWrapper">
            <div className="settleDuesHeader">
              <div className="settleDuesHeading">
                <Typography variant="h6">Create entry</Typography>
              </div>
              <div
                className="close"
                onClick={() => handleCreateManualEntryClose()}
              >
                X
              </div>
            </div>
            <div className="amountWrapper">
              <div className="fieldWrapper">
                <Typography variant="h6">Amount</Typography>{" "}
                <OutlinedInput
                  notched={false}
                  // disabled={true}
                  size="small"
                  label="hello"
                  className="mt-3"
                  fullWidth
                  value={mAmount}
                  onChange={(e) =>
                    /^[0-9.]*$/.test(e.target.value)
                      ? setMAmount(e.target.value)
                      : setMAmount(mAmount)
                  }
                />
                <Typography className="mt-4" variant="h6">
                  Start date
                </Typography>{" "}
                <FormControl fullWidth className="mt-1">
                  <TextField
                    type="date"
                    size="small"
                    fullWidth
                    value={startDate}
                    onChange={(e) => setStartDate(e.target.value)}
                  />
                </FormControl>
                <Typography className="mt-4" variant="h6">
                  End date
                </Typography>{" "}
                <FormControl fullWidth className="mt-1">
                  <TextField
                    type="date"
                    size="small"
                    fullWidth
                    value={endDate}
                    onChange={(e) => setEndDate(e.target.value)}
                  />
                </FormControl>
                <Typography className="mt-4" variant="h6">
                  Type
                </Typography>{" "}
                <FormControl fullWidth className="mt-1">
                  <Select
                    notched={false}
                    labelId="demo-simple-select-helper-label"
                    id="demo-simple-select-helper"
                    value={mType}
                    label="Type"
                    onChange={(e) => {
                      setMType(e.target.value);
                    }}
                    size="small"
                  >
                    <MenuItem value="DEBIT">DEBIT</MenuItem>
                    <MenuItem value="CREDIT">CREDIT</MenuItem>
                  </Select>
                </FormControl>
                <Typography className="mt-4" variant="h6">
                  Mode
                </Typography>{" "}
                <FormControl fullWidth className="mt-3 mb-3">
                  <Select
                    notched={false}
                    labelId="demo-simple-select-helper-label"
                    id="demo-simple-select-helper"
                    value={mMode}
                    label="Mode"
                    onChange={(e) => {
                      setMMode(e.target.value);
                    }}
                    size="small"
                  >
                    <MenuItem value="SUBSCRIPTION">SUBSCRIPTION</MenuItem>
                    <MenuItem value="WITHHOLD">WITHHOLD</MenuItem>
                    <MenuItem value="REFUND">REFUND</MenuItem>
                    <MenuItem value="DISCOUNT">DISCOUNT</MenuItem>
                    <MenuItem value="OTHER">OTHER</MenuItem>
                  </Select>
                </FormControl>
                <Typography className="mb-1" variant="h6">
                  Description
                </Typography>
                <FormControl fullWidth className="mt-1">
                  <OutlinedInput
                    notched={false}
                    id="outlined-basic"
                    name="aboutUsFullText"
                    label="Description"
                    multiline
                    rows={3}
                    maxRows={25}
                    variant="outlined"
                    value={mDescription}
                    onChange={(e) => {
                      setMDescription(e.target.value);
                    }}
                    type="text"
                  />
                </FormControl>
                {mType &&
                  mMode &&
                  mType === "CREDIT" &&
                  (mMode === "SUBSCRIPTION" || mMode === "OTHER") && (
                    <FormControlLabel
                      className="mt-3"
                      control={<Checkbox onChange={handleCheckboxChecked} />}
                      label="Charge the primary payment method on file"
                    />
                  )}
                <FormControlLabel
                  className="mt-3"
                  control={
                    <Checkbox onChange={handleSendEmailCheckboxChecked} />
                  }
                  label="Send notification email to customer"
                />
              </div>

              <div className="settleDuesSubmitBtns">
                <Button
                  className="cancelBtn"
                  onClick={() => handleCreateManualEntryClose()}
                >
                  Cancel
                </Button>
                <Button
                  variant="custom-button"
                  onClick={() => {
                    createManualEntryAPI();
                  }}
                  style={{ width: "8rem", fontSize: "15px" }}
                >
                  {genericLoad ? (
                    <CircularProgress
                      size={15}
                      color="inherit"
                      sx={{ ml: 1.5 }}
                    />
                  ) : (
                    "Create"
                  )}
                </Button>
              </div>
            </div>
          </div>
        </Box>
      </Modal>
      <Modal
        open={updateEntry}
        onClose={handleUpdateEnryClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box className="settleDuesModal" sx={modalStyle}>
          <div className="settleDuesWrapper">
            <div className="settleDuesHeader">
              <div className="settleDuesHeading">Update ledger entry</div>
              <div className="close" onClick={() => handleUpdateEnryClose()}>
                X
              </div>
            </div>
            <div className="amountWrapper">
              <div className="fieldWrapper">
                <Typography variant="h6">Amount</Typography>{" "}
                <OutlinedInput
                  notched={false}
                  size="small"
                  label="hello"
                  className="mt-3"
                  fullWidth
                  value={uAmount}
                  disabled={true}
                  onChange={(e) =>
                    /^[0-9.]*$/.test(e.target.value)
                      ? setUAmount(e.target.value)
                      : setUAmount(uAmount)
                  }
                />
                <Typography className="mt-4" variant="h6">
                  Start date
                </Typography>{" "}
                <FormControl fullWidth className="mt-1">
                  <TextField
                    type="date"
                    size="small"
                    fullWidth
                    value={startDateUpdate}
                    onChange={(e) => {
                      setStartDateUpdate(e.target.value);
                      console.log(e.target.value);
                    }}
                  />
                </FormControl>
                <Typography className="mt-4" variant="h6">
                  End date
                </Typography>{" "}
                <FormControl fullWidth className="mt-1">
                  <TextField
                    type="date"
                    size="small"
                    fullWidth
                    value={endDateUpdate}
                    onChange={(e) => setEndDateUpdate(e.target.value)}
                  />
                </FormControl>
                <Typography className="mt-4" variant="h6">
                  Type
                </Typography>{" "}
                <FormControl fullWidth className="mt-3">
                  <Select
                    disabled={false}
                    notched={false}
                    labelId="demo-simple-select-helper-label"
                    id="demo-simple-select-helper"
                    value={uType}
                    label="Type"
                    onChange={(e) => {
                      setUType(e.target.value);
                    }}
                    size="small"
                  >
                    <MenuItem value="DEBIT">DEBIT</MenuItem>
                    <MenuItem value="CREDIT">CREDIT</MenuItem>
                  </Select>
                </FormControl>
                <Typography className="mt-4" variant="h6">
                  Mode
                </Typography>{" "}
                <FormControl fullWidth className="mt-3 mb-3">
                  <Select
                    disabled={true}
                    notched={false}
                    labelId="demo-simple-select-helper-label"
                    id="demo-simple-select-helper"
                    value={uMode}
                    label="Mode"
                    onChange={(e) => {
                      setUMode(e.target.value);
                    }}
                    size="small"
                  >
                    <MenuItem value="SUBSCRIPTION">SUBSCRIPTION</MenuItem>
                    <MenuItem value="WITHHOLD">WITHHOLD</MenuItem>
                    <MenuItem value="REFUND">REFUND</MenuItem>
                    <MenuItem value="DISCOUNT">DISCOUNT</MenuItem>
                    <MenuItem value="OTHER">OTHER</MenuItem>
                  </Select>
                </FormControl>
                <Typography className="mb-3" variant="h6">
                  Description
                </Typography>{" "}
                <OutlinedInput
                  notched={false}
                  // disabled={true}
                  id="outlined-basic"
                  name="aboutUsFullText"
                  label="Description"
                  multiline
                  rows={5}
                  maxRows={25}
                  variant="outlined"
                  value={uDescription}
                  onChange={(e) => {
                    setUDescription(e.target.value);
                  }}
                  sx={{ width: "36rem" }}
                  type="text"
                />
              </div>

              <div className="settleDuesSubmitBtns">
                <Button
                  className="cancelBtn"
                  onClick={() => handleUpdateEnryClose()}
                >
                  Cancel
                </Button>
                <Button
                  variant="custom-button"
                  onClick={() => {
                    updateLedgerEntryAPI();
                  }}
                  style={{ width: "8rem" }}
                >
                  {genericLoad ? (
                    <CircularProgress
                      size={15}
                      color="inherit"
                      sx={{ ml: 1.5 }}
                    />
                  ) : (
                    "Update"
                  )}
                </Button>
              </div>
            </div>
          </div>
        </Box>
      </Modal>

      <Modal
        open={showPaymentLink}
        onClose={handleCloseShowPaymentLink}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box className="settleDuesModal" sx={modalStyle}>
          <div className="settleDuesWrapper">
            <div className="settleDuesHeader">
              <div className="settleDuesHeading">Payment link</div>
              <div
                className="close"
                onClick={() => handleCloseShowPaymentLink()}
              >
                X
              </div>
            </div>
            <div className="amountWrapper">
              <div className="fieldWrapper">
                <Typography>
                  Please open this link in incognito window
                </Typography>

                <h4 style={{ marginTop: "1rem" }}>
                  {`${getUrl()}pay-now/?group_id=${groupId}`}
                </h4>
              </div>
            </div>
          </div>
        </Box>
      </Modal>
    </div>
  );
};

export default TransactionsManagement;

const modalStyle = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 700,
  bgcolor: "background.paper",
  boxShadow: 24,
  p: 4,
};

import React, { useEffect } from "react";
import {
  Alert,
  Box,
  Button,
  Card,
  CardContent,
  CardHeader,
  Divider,
  Drawer,
  FormControl,
  Grid,
  IconButton,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Typography,
} from "@mui/material";
import { useState } from "react";
import {
  handleDjangoDataRequest,
  handleSpringDataRequest,
} from "../../utils/apiUtils";
import BasicPopOver from "./BasicPopOver";
import {
  BasicConfigPaymentGatewayAirpay,
  BasicConfigPaymentGatewayFortis,
  BasicConfigPaymentGatewayRazorpay,
  BasicConfigPaymentGatewayShift,
  BasicConfigPaymentGatewayStripe,
  BasicConfigPaymentGatewayTsys,
} from "./BasicConfigPaymentGateway";
import {
  BasicInfoSkeleton,
  BasicInfoSkeletonpg,
} from "../../components/BasicInfoSkeleton";
import { toast } from "react-toastify";
import { CircularProgress } from "@mui/material";
import axios from "axios";
import { BASE_SPRING_ENDPOINT } from "../../utils/apiUtils/config";
import { toRegulerCase } from "../../utils/Utils";
import CloseIcon from "@mui/icons-material/Close";
import OtherInfoConfig from "./OtherInfoConfig";

export default function BasicInfo({ basicInfo, hotelId, refresh, setRefresh }) {
  const others = [
    "property_contact",
    "property_status_log",
    "deal_owner",
    "is_digitalfranchise",
    "pss_comments",
    "booking_sms_notif",
    "booking_whatsapp_notif",
    "enable_id_scanner",
    "enable_gha",
    "enable_bookflexi",
    "pg_account_type",
    "pg_transfer_on",
  ];

  const [othersData, setOthersData] = useState({});
  const [openOtherInfoConfig, setOpenOtherInfoConfig] = useState(false);

  //-------------State for Popover---------------
  const [title, setTitle] = useState();
  const [item, setItem] = useState();
  const [currentValue, setCurrentValue] = useState();
  const [pk, setPk] = useState();
  const [pkName, setPkname] = useState();
  const [url, setUrl] = useState();
  const [cotegry, setCotegry] = useState();

  //----Handeling PopOver--------------
  const [anchorEl, setAnchorEl] = useState();
  const handleClick = (e, title, item, value, pk, pkName, url, cotegry) => {
    setAnchorEl(e.currentTarget);
    setTitle(title);
    setItem(item);
    setCurrentValue(value);
    setPk(pk);
    setPkname(pkName);
    setUrl(url);
    setCotegry(cotegry);
  };
  //-----------------------------------------
  const [bearerToken, setBearerToken] = useState();
  const mutationStyle = {
    borderBottom: "1px dashed",
    cursor: "pointer",
  };
  useEffect(() => {
    console.log(hotelId);
    handleSpringDataRequest(
      `core/api/v1/cm/get-channel-bearer-token?hotelId=${hotelId}`
    )
      .then((data) => setBearerToken(data))
      .catch((err) => console.log(err));
  }, [refresh]);

  //--------------------Payment Gaiteway Info----------------

  const [gatewayInfo, setGatewayInfo] = useState();
  const [gateway, setGateway] = useState("");
  const [refreshgateway, setRefreshGatewat] = useState();

  useEffect(() => {
    handleDjangoDataRequest(
      `api/v2/metaAdmin/metaAdminGetPgInfo/?hotel_id=${hotelId}`
    )
      .then((data) => {
        console.log(data);
        setGatewayInfo(data);
        if (data?.active_pg_name) {
          setGateway(data.active_pg_name);
        }
      })
      .catch((err) => console.log(err));
  }, [refreshgateway]);

  //----------------getting access token for shift4----
  const [accessToken, setAccessToken] = useState("");
  const [authTokenError, setAuthTokenError] = useState(false);
  const [authToken, setAuthToken] = useState("");
  const [accessTokenLoader, setAccessTokenLoader] = useState(false);
  const getAccessToken = () => {
    if (!authToken?.trim()) {
      setAuthTokenError(true);
      return;
    }
    setAccessTokenLoader(true);
    axios
      .get(
        `${BASE_SPRING_ENDPOINT}core/api/v1/payments/shift4/generate-and-save-shift4-access-token?hotelId=${hotelId}
      &authToken=${authToken?.trim()}`,
        { headers: { passkey: "udikKqi5lQ2U2QZJBtRdhQPQ" } }
      )
      .then((data) => {
        console.log(data);

        setAccessToken(data?.data);
        setAccessTokenLoader(false);
        toast.success("Access token is generated successfully.");
      })
      .catch((err) => {
        console.log(err);
        toast.error(err.response.data);
        setAccessTokenLoader(false);
      });
  };

  const getOtherInfoConfig = () => {
    handleDjangoDataRequest(
      `api/v2/metaAdmin/metaAdminListInfo/?hotel_id=${hotelId}`
    )
      .then((res) => {
        setOthersData(res);
      })
      .catch(() => {});
  };

  useEffect(() => {
    getOtherInfoConfig();
  }, []);

  return (
    <>
      {basicInfo ? (
        <Grid item xs={12} sx={{ boxShadow: 4 }}>
          <Card sx={{ marginBottom: 2 }}>
            <CardHeader title={"Configure Basic Information"} subheader={""}>
              <Divider />
            </CardHeader>
            <Divider />
            <CardContent>
              <Grid container spacing={2}>
                <Grid item xs={4}>
                  <Box sx={{ mb: 1 }}>
                    <Typography display="inline" mr={0.5}>
                      Name :
                    </Typography>
                    <Typography display="inline" color="text.secondary">
                      {basicInfo?.hotel.hotel_name}
                    </Typography>
                  </Box>
                  <Box sx={{ mb: 1 }}>
                    <Typography display="inline" mr={0.5}>
                      ID :
                    </Typography>
                    <Typography display="inline" color="text.secondary">
                      {basicInfo?.hotel_id}
                    </Typography>
                  </Box>
                  <Box sx={{ mb: 1 }}>
                    <Typography display="inline" mr={0.5}>
                      Group ID :
                    </Typography>
                    <Typography
                      display="inline"
                      color="secondary"
                      sx={mutationStyle}
                      onClick={(e) =>
                        handleClick(
                          e,
                          "GroupId",
                          1,
                          `${basicInfo?.hotel.group_id}`,
                          "groupId",
                          "group_id",
                          "core/api/v1/hotel/update-hotel-groupId",
                          "attr"
                        )
                      }
                    >
                      {basicInfo?.hotel.group_id}
                    </Typography>
                  </Box>
                  <Box sx={{ mb: 1 }}>
                    <Typography display="inline" mr={0.5}>
                      Email :
                    </Typography>
                    <Typography
                      display="inline"
                      color="secondary"
                      sx={mutationStyle}
                      onClick={(e) =>
                        handleClick(
                          e,
                          "Email",
                          1,
                          `${basicInfo?.hotel.primary_hotel_email}`,
                          "emailid",
                          "hotel_email",
                          "user/update-hotelAdmin-email/",
                          "attr"
                        )
                      }
                    >
                      {basicInfo?.hotel.primary_hotel_email}
                    </Typography>
                  </Box>
                  <Box sx={{ mb: 1 }}>
                    <Typography display="inline" mr={0.5}>
                      Password :
                    </Typography>
                    <Button
                      variant="custom-button"
                      onClick={(e) => handleClick(e, "Password", 3, "")}
                    >
                      Set Login Password
                      {/* {basicInfo?.} */}
                    </Button>
                  </Box>
                  {/* Email Enable, No Atribute found */}
                  <Box sx={{ mb: 1 }}>
                    <Typography display="inline" mr={0.5}>
                      Email Send Enabled :
                    </Typography>

                    <Typography
                      display="inline"
                      color="secondary"
                      sx={mutationStyle}
                      onClick={(e) =>
                        handleClick(
                          e,
                          "Email Enable Status",
                          2,
                          `${basicInfo?.hotel.hotel_email_send_flag}`,
                          "hotel_email_send_flag",
                          "hotel_email_send_flag",
                          "api/v2/metaAdmin/metaUpdateHotelEmailSendFlag/",
                          "flag"
                        )
                      }
                    >
                      {basicInfo?.hotel.hotel_email_send_flag || "Empty"}
                    </Typography>
                  </Box>
                  <Box sx={{ mb: 1 }}>
                    <Typography display="inline" mr={0.5}>
                      Website :
                    </Typography>
                    <Typography display="inline" color="text.secondary">
                      {basicInfo?.hotel.hotel_website}
                    </Typography>
                  </Box>
                  <Box sx={{ mb: 1 }}>
                    <Typography display="inline" mr={0.5}>
                      Phone :
                    </Typography>
                    <Typography display="inline" color="text.secondary">
                      {basicInfo?.hotel.hotel_contact}
                    </Typography>
                  </Box>
                  <Box sx={{ mb: 1 }}>
                    <Typography display="inline" mr={0.5}>
                      Address :
                    </Typography>
                    <Typography display="inline" color="text.secondary">
                      {`${basicInfo?.hotel.hotel_address} ${basicInfo?.hotel.hotel_address_line2} ${basicInfo?.hotel.hotel_city} ${basicInfo?.hotel.hotel_state} ${basicInfo?.hotel.hotel_country}`}
                    </Typography>
                  </Box>
                </Grid>
                <Grid item xs={4}>
                  <Box sx={{ mb: 1 }}>
                    <Typography display="inline" mr={0.5}>
                      Rating :
                    </Typography>
                    <Typography display="inline" color="text.secondary">
                      {basicInfo?.hotel.hotel_star_rating}
                    </Typography>
                  </Box>
                  <Box sx={{ mb: 1 }}>
                    <Typography display="inline" mr={0.5}>
                      Slots :
                    </Typography>
                    <Typography display="inline" color="text.secondary">
                      {`${basicInfo?.hotel.hotel_slot1} ${basicInfo?.hotel.hotel_slot2} ${basicInfo?.hotel.hotel_slot3}`}
                    </Typography>
                  </Box>
                  <Box sx={{ mb: 1 }}>
                    <Typography display="inline" mr={0.5}>
                      Flexible Enabled :
                    </Typography>
                    <Typography
                      display="inline"
                      color="secondary"
                      sx={mutationStyle}
                      onClick={(e) =>
                        handleClick(
                          e,
                          "Flexible Enable Status",
                          2,
                          `${basicInfo?.hotel.hotel_flexible_option}`,
                          "flexible_online",
                          "hotel_flexible_option",
                          "api/v2/metaAdmin/metaUpdateHotelAttr/",
                          "attr"
                        )
                      }
                    >
                      {basicInfo?.hotel.hotel_flexible_option || "Empty"}
                    </Typography>
                  </Box>
                  <Box sx={{ mb: 1 }}>
                    <Typography display="inline" mr={0.5}>
                      OTA Enabled :
                    </Typography>
                    <Typography
                      display="inline"
                      color="secondary"
                      sx={mutationStyle}
                      onClick={(e) =>
                        handleClick(
                          e,
                          "OTA Enable Status",
                          2,
                          `${basicInfo?.hotel.ota_status}`,
                          "ota_status",
                          "hotel_ota_status",
                          "api/v2/metaAdmin/metaUpdateHotelAttr/",
                          "attr"
                        )
                      }
                    >
                      {basicInfo?.hotel.ota_status || "Empty"}
                    </Typography>
                  </Box>
                  <Box sx={{ mb: 1 }}>
                    <Typography display="inline" mr={0.5}>
                      Payments Enabled :
                    </Typography>
                    <Typography
                      display="inline"
                      color="secondary"
                      sx={mutationStyle}
                      onClick={(e) =>
                        handleClick(
                          e,
                          "Payments Enable Status",
                          2,
                          `${basicInfo?.hotel.payments_enabled}`,
                          "payments_enabled",
                          "hotel_payments_enabled",
                          "api/v2/metaAdmin/metaUpdateHotelAttr/",
                          "attr"
                        )
                      }
                    >
                      {basicInfo?.hotel.payments_enabled || "Empty"}
                    </Typography>
                  </Box>
                  <Box sx={{ mb: 1 }}>
                    <Typography display="inline" mr={0.5}>
                      Is Whitelabelled? :
                    </Typography>
                    <Typography
                      display="inline"
                      color="secondary"
                      sx={mutationStyle}
                      onClick={(e) =>
                        handleClick(
                          e,
                          "Whitelabled Enable Status",
                          4,
                          `${basicInfo?.hotel.is_white_labelled}`,
                          "is_white_labelled",
                          "is_white_labelled",
                          "api/v2/metaAdmin/metaUpdateHotelAttr/",
                          "attr"
                        )
                      }
                    >
                      {basicInfo?.hotel.is_white_labelled || "Empty"}
                    </Typography>
                  </Box>
                  <Box sx={{ mb: 1 }}>
                    <Typography display="inline" mr={0.5}>
                      Whitelabel URL :
                    </Typography>
                    <Typography
                      display="inline"
                      color="secondary"
                      sx={mutationStyle}
                      onClick={(e) =>
                        handleClick(
                          e,
                          "Whitelable URL",
                          1,
                          `${basicInfo?.hotel.whitelabel_url}`,
                          "whitelabel_url",
                          "whitelabel_url",
                          "api/v2/metaAdmin/metaUpdateHotelAttr/",
                          "attr"
                        )
                      }
                    >
                      {basicInfo?.hotel.whitelabel_url || "Empty"}
                    </Typography>
                  </Box>
                </Grid>
                <Grid item xs={4}>
                  <Box sx={{ mb: 1 }}>
                    <Typography display="inline" mr={0.5}>
                      Is Extranet: :
                    </Typography>
                    <Typography
                      display="inline"
                      color="secondary"
                      sx={mutationStyle}
                      onClick={(e) =>
                        handleClick(
                          e,
                          "Extranet",
                          4,
                          `${basicInfo?.hotel.is_extranet}`,
                          "is_extranet",
                          "hotel_is_extranet",
                          "api/v2/metaAdmin/metaUpdateHotelAttr/",
                          "attr"
                        )
                      }
                    >
                      {basicInfo?.hotel.is_extranet || "Empty"}
                    </Typography>
                  </Box>
                  <Box sx={{ mb: 1 }}>
                    <Typography display="inline" mr={0.5}>
                      PG AccountID for transfers :
                    </Typography>
                    <Typography
                      display="inline"
                      color="secondary"
                      sx={mutationStyle}
                      onClick={(e) =>
                        handleClick(
                          e,
                          "PG AccountID Transfer",
                          1,
                          `${basicInfo?.hotel.pg_account_id}`,
                          "pg_account_id",
                          "hotel_pg_account_id",
                          "api/v2/metaAdmin/metaUpdateHotelAttr/",
                          "attr"
                        )
                      }
                    >
                      {basicInfo?.hotel.pg_account_id || "Empty"}
                    </Typography>
                  </Box>
                  <Box sx={{ mb: 1 }}>
                    <Typography display="inline" mr={0.5}>
                      GoIbibo Bearer Token :
                    </Typography>
                    <Typography
                      display="inline"
                      color="secondary"
                      sx={mutationStyle}
                      onClick={(e) =>
                        handleClick(
                          e,
                          "GoIbibo Bearer Token",
                          1,
                          `${bearerToken?.goibibo_bearer_token}`,
                          "",
                          "goibibo",
                          `core/api/v1/cm/register-channel-token?hotelId=${basicInfo?.hotel_id}`,
                          "token"
                        )
                      }
                    >
                      {bearerToken?.goibibo_bearer_token || "Empty"}
                    </Typography>
                  </Box>
                  <Box sx={{ mb: 1 }}>
                    <Typography display="inline" mr={0.5}>
                      Hostel World Token :
                    </Typography>
                    <Typography
                      display="inline"
                      color="secondary"
                      sx={mutationStyle}
                      onClick={(e) =>
                        handleClick(
                          e,
                          "Hostel World Token",
                          1,
                          `${bearerToken?.hw_bearer_token}`,
                          "",
                          "hostelworld",
                          `core/api/v1/cm/register-channel-token?hotelId=${basicInfo?.hotel_id}`,
                          "token"
                        )
                      }
                    >
                      {bearerToken?.hw_bearer_token || "Empty"}
                    </Typography>
                  </Box>
                  <Box sx={{ mb: 1 }}>
                    <Typography display="inline" mr={0.5}>
                      Simplotel Token :
                    </Typography>
                    <Typography
                      display="inline"
                      color="secondary"
                      sx={mutationStyle}
                      onClick={(e) =>
                        handleClick(
                          e,
                          "Simplotel Token",
                          1,
                          `${bearerToken?.simplotel_bearer_token}`,
                          "",
                          "simplotel",
                          `core/api/v1/cm/register-channel-token?hotelId=${basicInfo?.hotel_id}`,
                          "token"
                        )
                      }
                    >
                      {bearerToken?.simplotel_bearer_token || "Empty"}
                    </Typography>
                  </Box>
                  <Box sx={{ mb: 1 }}>
                    <Typography display="inline" mr={0.5}>
                      Hotelbeds Region Code :
                    </Typography>
                    <Typography
                      display="inline"
                      color="secondary"
                      sx={mutationStyle}
                      onClick={(e) =>
                        handleClick(
                          e,
                          "Hotelbeds Region Code",
                          1,
                          `${bearerToken?.hotelbeds_bearer_token}`,
                          "",
                          "hotelbeds",
                          `core/api/v1/cm/register-channel-token?hotelId=${basicInfo?.hotel_id}`,
                          "token"
                        )
                      }
                    >
                      {bearerToken?.hotelbeds_bearer_token || "Empty"}
                    </Typography>
                  </Box>
                  <Box sx={{ mb: 1 }}>
                    <Typography display="inline" mr={0.5}>
                      TripAdvisor Hotel ID :
                    </Typography>
                    <Typography
                      display="inline"
                      color="secondary"
                      sx={mutationStyle}
                      onClick={(e) =>
                        handleClick(
                          e,
                          "TripAdvisor Hotel ID ",
                          1,
                          `${bearerToken?.trip_hotel_id}`,
                          "",
                          "",
                          `core/api/tripadvisor/add-trip-advisor-hotel`,
                          "trip"
                        )
                      }
                    >
                      {bearerToken?.trip_hotel_id || "Empty"}
                    </Typography>
                  </Box>
                  <Box sx={{ mb: 1 }}>
                    <Typography display="inline" mr={0.5}>
                      Goibibo Access Token :
                    </Typography>
                    <Typography
                      display="inline"
                      color="secondary"
                      sx={mutationStyle}
                      onClick={(e) =>
                        handleClick(
                          e,
                          "Goibibo Access Token",
                          1,
                          `${bearerToken?.gommt_access_token}`,
                          "",
                          "goibiboat",
                          `core/api/v1/cm/register-channel-token?hotelId=${basicInfo?.hotel_id}`,
                          "token"
                        )
                      }
                    >
                      {bearerToken?.gommt_access_token || "Empty"}
                    </Typography>
                  </Box>
                </Grid>
              </Grid>
            </CardContent>
          </Card>
        </Grid>
      ) : (
        <BasicInfoSkeleton />
      )}

      {othersData?.info?.length > 0 ? (
        <Grid item xs={12} sx={{ boxShadow: 4 }}>
          <Card sx={{ marginBottom: 2 }}>
            <CardHeader
              title={
                <Box sx={{ display: "flex", justifyContent: "space-between" }}>
                  <Typography sx={{ fontSize: "19.5px" }}>
                    Configure other information
                  </Typography>
                  <Button
                    variant="custom-button"
                    onClick={() => setOpenOtherInfoConfig(true)}
                  >
                    Edit info
                  </Button>
                </Box>
              }
              subheader={""}
            ></CardHeader>
            <Divider />
            <CardContent>
              {" "}
              <Grid container spacing={1}>
                {others?.map((elm) => (
                  <Grid item xs={4}>
                    <Box
                      sx={{
                        mb: 2,
                        border: "1px solid #D3D3D3",
                        borderRadius: 1,
                        display: "flex",
                        justifyContent: "space-between",
                        p: 1,
                      }}
                    >
                      <Typography
                        variant="subtitle2"
                        sx={{ fontWeight: 700, color: "gray" }}
                      >
                        {toRegulerCase(elm)}
                      </Typography>
                      <Typography>{othersData?.info?.[0]?.[elm]}</Typography>
                    </Box>
                  </Grid>
                ))}
              </Grid>
            </CardContent>
          </Card>
        </Grid>
      ) : (
        <Alert severity="info" sx={{ mb: 2 }}>
          Account subscription is pending. Contact accounts@stayflexi.com for
          adding subscription.
        </Alert>
      )}
      {basicInfo ? (
        <Grid item xs={12} sx={{ boxShadow: 3 }}>
          <Card sx={{ marginBottom: 2 }}>
            <CardHeader
              title={"Configure Custom Payment Gateway Information"}
              subheader={
                "Used for charging swiped cards or manually added cards in Folio"
              }
            >
              <Divider />
            </CardHeader>
            <Divider />
            {/* {basicInfo?.hotel && ( */}
            <CardContent>
              <Grid container spacing={4}>
                <Grid item xs={4}>
                  <FormControl sx={{ width: 300 }} size="small">
                    <InputLabel id="demo-select-small-label">
                      Choose Active Payment Gateway
                    </InputLabel>
                    <Select
                      size="small"
                      labelId="demo-select-small-label"
                      id="demo-select-small"
                      value={gateway}
                      onChange={(e) => setGateway(e.target.value)}
                      label="Choose Active Payment Gateway"
                    >
                      <MenuItem value={"DISABLE"}>DISABLE</MenuItem>
                      <MenuItem value={"STRIPE"}>STRIPE</MenuItem>
                      <MenuItem value={"RAZORPAY"}>RAZORPAY</MenuItem>
                      <MenuItem value={"FORTIS"}>FORTIS</MenuItem>
                      <MenuItem value={"SHIFT4"}>SHIFT4</MenuItem>
                      <MenuItem value={"TSYS"}>TSYS</MenuItem>
                      <MenuItem value={"AIRPAY"}>AIRPAY</MenuItem>
                    </Select>
                  </FormControl>
                </Grid>
                <Grid item xs={8}>
                  {gateway === "SHIFT4" && (
                    <Box
                      sx={{ display: "flex", justifyContent: "space-between" }}
                    >
                      <TextField
                        size="small"
                        id="outlined-basic"
                        label="Auth token"
                        variant="outlined"
                        sx={{ width: " 70% " }}
                        value={authToken}
                        onChange={(e) => {
                          setAuthToken(e.target.value);
                          setAuthTokenError(false);
                        }}
                        required
                        error={authTokenError}
                        helperText={
                          authTokenError ? "Auth token is required" : ""
                        }
                      />
                      <Button
                        variant="custom-button"
                        sx={{ height: 32 }}
                        onClick={getAccessToken}
                        disabled={accessTokenLoader}
                      >
                        Generate access token{" "}
                        {accessTokenLoader && <CircularProgress size={16} />}
                      </Button>
                    </Box>
                  )}
                </Grid>
                {gateway === "STRIPE" && basicInfo?.hotel && (
                  <BasicConfigPaymentGatewayStripe
                    gatewayInfo={gatewayInfo}
                    gateway={gateway}
                    hotelId={hotelId}
                    hotelEmail={basicInfo.hotel.hotel_email}
                    setRefreshGatewat={setRefreshGatewat}
                  />
                )}
                {gateway === "RAZORPAY" && basicInfo?.hotel && (
                  <BasicConfigPaymentGatewayRazorpay
                    gatewayInfo={gatewayInfo}
                    gateway={gateway}
                    hotelId={hotelId}
                    hotelEmail={basicInfo.hotel.hotel_email}
                    setRefreshGatewat={setRefreshGatewat}
                  />
                )}
                {gateway === "FORTIS" && basicInfo?.hotel && (
                  <BasicConfigPaymentGatewayFortis
                    gatewayInfo={gatewayInfo}
                    gateway={gateway}
                    hotelId={hotelId}
                    hotelEmail={basicInfo.hotel.hotel_email}
                    setRefreshGatewat={setRefreshGatewat}
                  />
                )}
                {gateway === "SHIFT4" && basicInfo?.hotel && (
                  <BasicConfigPaymentGatewayShift
                    gatewayInfo={gatewayInfo}
                    gateway={gateway}
                    hotelId={hotelId}
                    hotelEmail={basicInfo.hotel.hotel_email}
                    setRefreshGatewat={setRefreshGatewat}
                    accessToken={accessToken}
                  />
                )}
                {gateway === "TSYS" && basicInfo?.hotel && (
                  <BasicConfigPaymentGatewayTsys
                    gatewayInfo={gatewayInfo}
                    gateway={gateway}
                    hotelId={hotelId}
                    hotelEmail={basicInfo.hotel.hotel_email}
                    setRefreshGatewat={setRefreshGatewat}
                  />
                )}
                {gateway === "AIRPAY" && basicInfo?.hotel && (
                  <BasicConfigPaymentGatewayAirpay
                    gatewayInfo={gatewayInfo}
                    gateway={gateway}
                    hotelId={hotelId}
                    hotelEmail={basicInfo.hotel.hotel_email}
                    setRefreshGatewat={setRefreshGatewat}
                  />
                )}
              </Grid>
            </CardContent>
            {/* )} */}
          </Card>
        </Grid>
      ) : (
        <BasicInfoSkeletonpg />
      )}

      {/* -----------PopOver-------------------------------------------- */}
      <BasicPopOver
        anchorEl={anchorEl}
        setAnchorEl={setAnchorEl}
        title={title}
        item={item}
        currentValue={currentValue}
        pk={pk}
        pkName={pkName}
        hotelID={basicInfo?.hotel_id}
        hotelEmail={basicInfo?.hotel?.primary_hotel_email}
        url={url}
        cotegry={cotegry}
        setRefresh={setRefresh}
      />

      <Drawer
        anchor="right"
        open={openOtherInfoConfig}
        onClose={() => setOpenOtherInfoConfig(false)}
      >
        <Box sx={{ width: 600, padding: 2 }}>
          <IconButton onClick={() => setOpenOtherInfoConfig(false)}>
            <CloseIcon fontSize="small" />
          </IconButton>
          <Divider sx={{ mt: 1, mb: 3 }} />
          <OtherInfoConfig
            hotelId={hotelId}
            otherConfigData={othersData?.info?.[0]}
            setOpenOtherInfoConfig={setOpenOtherInfoConfig}
            getOtherInfoConfig={getOtherInfoConfig}
          />
        </Box>
      </Drawer>
    </>
  );
}

import { Button, Grid, TextField } from "@mui/material";
import React, { useEffect, useState } from "react";
import {
  handleDjangoDataMutationRequest,
  handleSpringDataMutationRequest,
} from "../../utils/apiUtils";

import { CircularProgress } from "@mui/material";
import { toast } from "react-toastify";

export const handleGatewaySaveButton = (
  gatewayInfo,
  gateway,
  updatedInfo,
  hotelId,
  hotelEmail,
  setRefreshGatewat,
  setLoader
) => {
  const dummygatewayInfo = {
    pg_name: null,
    hotel_id: null,
    hotel_email: null,
    stripe_public_key: null,
    stripe_secret_key: null,
    razorpay_key_id: null,
    razorpay_key_secret: null,
    airpay_merc_id: null,
    airpay_username: null,
    airpay_password: null,
    airpay_api_key: null,
    tsys_merc_id: null,
    tsys_terminal_id: null,
    tsys_username: null,
    tsys_password: null,
    tsys_api_key: null,
    fortis_user_id: null,
    fortis_user_api_key: null,
    fortis_location_id: null,
    fortis_terminal_id: null,
    shift4_utg_url: null,
    shift4_access_token: null,
    shift4_api_terminal_id: null,
    shift4_terminal_serial_num: null,
    shift4_terminal_manufacturer: null,
  };
  Object.keys(gatewayInfo).map(
    (elm) => (dummygatewayInfo[elm] = gatewayInfo[elm])
  );
  Object.keys(updatedInfo).map(
    (elm) => (dummygatewayInfo[elm] = updatedInfo[elm]?.trim())
  );
  dummygatewayInfo.pg_name = gateway;
  dummygatewayInfo.hotel_id = hotelId;
  dummygatewayInfo.hotel_email = hotelEmail;
  console.log(dummygatewayInfo);
  const user = localStorage.getItem("logedUserEmail");
  console.log(user,"user");
  setLoader(true);
  const updatedGatewayInfo = {
    ...dummygatewayInfo,
    user: user // Ensure 'user' key is included in the payload
  };
  
  console.log("Sending Data:", updatedGatewayInfo); // Debugging
  
  handleDjangoDataMutationRequest(
    "POST",
    "api/v2/metaAdmin/metaAdminSavePgInfo/",
    updatedGatewayInfo
  ).then((data) => {
      setRefreshGatewat(new Date());
      toast.success(data);
      setLoader(false);
    })
    .catch((err) => {
      setLoader(false);
      toast.error(err);
    });
};

export function BasicConfigPaymentGatewayStripe({
  gatewayInfo,
  gateway,
  hotelId,
  hotelEmail,
  setRefreshGatewat,
}) {
  const [stripPub, setStripPub] = useState();
  const [stripSec, setStripSec] = useState();
  const [loader, setLoader] = useState(false);
  useEffect(() => {
    setStripPub(gatewayInfo?.stripe_public_key);
    setStripSec(gatewayInfo?.stripe_secret_key);
  }, [gatewayInfo]);
  return (
    <>
      <Grid item xs={12}>
        <TextField
          size="small"
          id="outlined-basic"
          label="Stripe Public Key"
          variant="outlined"
          fullWidth
          value={stripPub}
          onChange={(e) => setStripPub(e.target.value)}
        />
      </Grid>
      <Grid item xs={12}>
        <TextField
          size="small"
          id="outlined-basic"
          label="Stripe Secret Key"
          variant="outlined"
          fullWidth
          value={stripSec}
          onChange={(e) => setStripSec(e.target.value)}
        />
      </Grid>
      <Grid item xs={12}>
        <Button
          sx={{ width: "100%" }}
          variant="custom-button"
          disabled={loader}
          onClick={() =>
            handleGatewaySaveButton(
              gatewayInfo,
              gateway,
              {
                stripe_public_key: stripPub,
                stripe_secret_key: stripSec,
              },
              hotelId,
              hotelEmail,
              setRefreshGatewat,
              setLoader
            )
          }
        >
          {loader ? <CircularProgress size={20} /> : "Save"}
        </Button>
      </Grid>
    </>
  );
}
export function BasicConfigPaymentGatewayRazorpay({
  gatewayInfo,
  gateway,
  hotelId,
  hotelEmail,
  setRefreshGatewat,
}) {
  const [razorId, setRazorId] = useState();
  const [razorSec, setRazorSec] = useState();

  const [loader, setLoader] = useState(false);
  useEffect(() => {
    setRazorId(gatewayInfo?.razorpay_key_id);
    setRazorSec(gatewayInfo?.razorpay_key_secret);
  }, [gatewayInfo]);
  return (
    <>
      <Grid item xs={12}>
        <TextField
          size="small"
          id="outlined-basic"
          label="Razorpay Key Id"
          variant="outlined"
          fullWidth
          value={razorId}
          onChange={(e) => setRazorId(e.target.value)}
        />
      </Grid>
      <Grid item xs={12}>
        <TextField
          size="small"
          id="outlined-basic"
          label="Razorpay Key Secret"
          variant="outlined"
          fullWidth
          value={razorSec}
          onChange={(e) => setRazorSec(e.target.value)}
        />
      </Grid>
      <Grid item xs={12}>
        <Button
          variant="custom-button"
          sx={{ width: "100%" }}
          disabled={loader}
          onClick={() =>
            handleGatewaySaveButton(
              gatewayInfo,
              gateway,
              {
                razorpay_key_id: razorId,
                razorpay_key_secret: razorSec,
              },
              hotelId,
              hotelEmail,
              setRefreshGatewat,
              setLoader
            )
          }
        >
          {loader ? <CircularProgress size={20} /> : "Save"}
        </Button>
      </Grid>
    </>
  );
}
export function BasicConfigPaymentGatewayFortis({
  gatewayInfo,
  gateway,
  hotelId,
  hotelEmail,
  setRefreshGatewat,
}) {
  const [fortisUsr, setFortisUsr] = useState();
  const [fortisApi, setFortisApi] = useState();
  const [fortisLoc, setFortisLoc] = useState();
  const [fortisTerm, setFortisTerm] = useState();
  const [loader, setLoader] = useState(false);
  useEffect(() => {
    setFortisUsr(gatewayInfo?.fortis_user_id);
    setFortisApi(gatewayInfo?.fortis_user_api_key);
    setFortisLoc(gatewayInfo?.fortis_location_id);
    setFortisTerm(gatewayInfo?.fortis_terminal_id);
  }, [gatewayInfo]);
  return (
    <>
      <Grid item xs={12}>
        <TextField
          size="small"
          id="outlined-basic"
          label="Fortis User Id"
          variant="outlined"
          fullWidth
          value={fortisUsr}
          onChange={(e) => setFortisUsr(e.target.value)}
        />
      </Grid>
      <Grid item xs={12}>
        <TextField
          size="small"
          id="outlined-basic"
          label="Fortis User Api Key"
          variant="outlined"
          fullWidth
          value={fortisApi}
          onChange={(e) => setFortisApi(e.target.value)}
        />
      </Grid>
      <Grid item xs={12}>
        <TextField
          size="small"
          id="outlined-basic"
          label="Fortis Location Id"
          variant="outlined"
          fullWidth
          value={fortisLoc}
          onChange={(e) => setFortisLoc(e.target.value)}
        />
      </Grid>
      <Grid item xs={12}>
        <TextField
          size="small"
          id="outlined-basic"
          label="Fortis Terminal Id"
          variant="outlined"
          fullWidth
          value={fortisTerm}
          onChange={(e) => setFortisTerm(e.target.value)}
        />
      </Grid>
      <Grid item xs={12}>
        <Button
          variant="custom-button"
          sx={{ width: "100%" }}
          disabled={loader}
          onClick={() =>
            handleGatewaySaveButton(
              gatewayInfo,
              gateway,
              {
                fortis_user_id: fortisUsr,
                fortis_user_api_key: fortisApi,
                fortis_location_id: fortisLoc,
                fortis_terminal_id: fortisTerm,
              },
              hotelId,
              hotelEmail,
              setRefreshGatewat,
              setLoader
            )
          }
        >
          {loader ? <CircularProgress size={20} /> : "Save"}
        </Button>
      </Grid>
    </>
  );
}
export function BasicConfigPaymentGatewayShift({
  gatewayInfo,
  gateway,
  hotelId,
  hotelEmail,
  setRefreshGatewat,
  accessToken,
}) {
  const [shiftUrl, setShiftUrl] = useState();
  const [shiftToken, setShiftToken] = useState("");
  const [shift4ApiTerminalId, setShift4ApiTerminalId] = useState();
  const [shift4TerminalSerialNum, setShift4TerminalSerialNum] = useState();
  const [shift4TerminalManufacturer, setShift4TerminalManufacturer] =
    useState();
  const [loader, setLoader] = useState(false);
  useEffect(() => {
    setShiftUrl(gatewayInfo?.shift4_utg_url);
    setShiftToken(gatewayInfo?.shift4_access_token);
    setShift4ApiTerminalId(gatewayInfo?.shift4_api_terminal_id);
    setShift4TerminalSerialNum(gatewayInfo?.shift4_terminal_serial_num);
    setShift4TerminalManufacturer(gatewayInfo?.shift4_terminal_manufacturer);
  }, [gatewayInfo]);

  useEffect(() => {
    if (accessToken) {
      setShiftToken(accessToken);
    }
  }, [accessToken]);
  return (
    <>
      <Grid item xs={12}>
        <TextField
          size="small"
          id="outlined-basic"
          label="Shift4 Utg Url"
          variant="outlined"
          fullWidth
          value={shiftUrl}
          onChange={(e) => setShiftUrl(e.target.value)}
        />
      </Grid>
      <Grid item xs={12}>
        <TextField
          size="small"
          id="outlined-basic"
          label="Shift4 Access Token"
          variant="outlined"
          fullWidth
          value={shiftToken}
          onChange={(e) => setShiftToken(e.target.value)}
        />
      </Grid>
      <Grid item xs={12}>
        <TextField
          size="small"
          id="outlined-basic"
          label="Shift4 API Terminal Id"
          variant="outlined"
          fullWidth
          value={shift4ApiTerminalId}
          onChange={(e) => setShift4ApiTerminalId(e.target.value)}
        />
      </Grid>
      <Grid item xs={12}>
        <TextField
          size="small"
          id="outlined-basic"
          label="Shift4 Terminal Serial number"
          variant="outlined"
          fullWidth
          value={shift4TerminalSerialNum}
          onChange={(e) => setShift4TerminalSerialNum(e.target.value)}
        />
      </Grid>
      <Grid item xs={12}>
        <TextField
          size="small"
          id="outlined-basic"
          label="Shift4 Terminal Manufacturer"
          variant="outlined"
          fullWidth
          value={shift4TerminalManufacturer}
          onChange={(e) => setShift4TerminalManufacturer(e.target.value)}
        />
      </Grid>
      <Grid item xs={12}>
        <Button
          variant="custom-button"
          sx={{ width: "100%" }}
          disabled={loader}
          onClick={() =>
            handleGatewaySaveButton(
              gatewayInfo,
              gateway,
              {
                shift4_utg_url: shiftUrl,
                shift4_access_token: shiftToken,
                shift4_api_terminal_id: shift4ApiTerminalId,
                shift4_terminal_serial_num: shift4TerminalSerialNum,
                shift4_terminal_manufacturer: shift4TerminalManufacturer,
              },
              hotelId,
              hotelEmail,
              setRefreshGatewat,
              setLoader
            )
          }
        >
          {loader ? <CircularProgress size={20} /> : "Save"}
        </Button>
      </Grid>
    </>
  );
}
export function BasicConfigPaymentGatewayTsys({
  gatewayInfo,
  gateway,
  hotelId,
  hotelEmail,
  setRefreshGatewat,
}) {
  const [tsysMercId, setTsysMercId] = useState();
  const [tsysTermId, setTsysTermId] = useState();
  const [tsysUsername, setTsysUsername] = useState();
  const [tsysPassword, setTsysPassword] = useState();
  const [loader, setLoader] = useState(false);
  const [tsysKey, setTsysKey] = useState();
  useEffect(() => {
    setTsysMercId(gatewayInfo?.tsys_merc_id);
    setTsysTermId(gatewayInfo?.tsys_terminal_id);
    setTsysUsername(gatewayInfo?.tsys_username);
    setTsysPassword(gatewayInfo?.tsys_password);
    setTsysKey(gatewayInfo?.tsys_api_key);
  }, [gatewayInfo]);
  return (
    <>
      <Grid item xs={12}>
        <TextField
          size="small"
          id="outlined-basic"
          label="Tsys Merc Id"
          variant="outlined"
          fullWidth
          value={tsysMercId}
          onChange={(e) => setTsysMercId(e.target.value)}
        />
      </Grid>
      <Grid item xs={12}>
        <TextField
          size="small"
          id="outlined-basic"
          label="Tsys Terminal Id"
          variant="outlined"
          fullWidth
          value={tsysTermId}
          onChange={(e) => setTsysTermId(e.target.value)}
        />
      </Grid>
      <Grid item xs={12}>
        <TextField
          size="small"
          id="outlined-basic"
          label="Tsys Username"
          variant="outlined"
          fullWidth
          value={tsysUsername}
          onChange={(e) => setTsysUsername(e.target.value)}
        />
      </Grid>
      <Grid item xs={12}>
        <TextField
          size="small"
          id="outlined-basic"
          label="Tsys Password"
          variant="outlined"
          sx={{ width: "100%" }}
          value={tsysPassword}
          onChange={(e) => setTsysPassword(e.target.value)}
        />
      </Grid>
      <Grid item xs={12}>
        <TextField
          size="small"
          id="outlined-basic"
          label="Tsys Api Key"
          variant="outlined"
          fullWidth
          value={tsysKey}
          onChange={(e) => setTsysKey(e.target.value)}
        />
      </Grid>
      <Grid item xs={12}>
        <Button
          variant="custom-button"
          sx={{ width: "100%" }}
          disabled={loader}
          onClick={() =>
            handleGatewaySaveButton(
              gatewayInfo,
              gateway,
              {
                tsys_merc_id: tsysMercId,
                tsys_terminal_id: tsysTermId,
                tsys_username: tsysUsername,
                tsys_password: tsysPassword,
                tsys_api_key: tsysKey,
              },
              hotelId,
              hotelEmail,
              setRefreshGatewat,
              setLoader
            )
          }
        >
          {loader ? <CircularProgress size={20} /> : "Save"}
        </Button>
      </Grid>
    </>
  );
}
export function BasicConfigPaymentGatewayAirpay({
  gatewayInfo,
  gateway,
  hotelId,
  hotelEmail,
  setRefreshGatewat,
}) {
  const [airpayId, setAirpayId] = useState();
  const [airpayUsername, setAirpayUsername] = useState();
  const [airpayPassword, setAirpayPassword] = useState();
  const [airpayKey, setAirpayKey] = useState();
  const [loader, setLoader] = useState(false);
  useEffect(() => {
    setAirpayId(gatewayInfo?.airpay_merc_id);
    setAirpayUsername(gatewayInfo?.airpay_username);
    setAirpayPassword(gatewayInfo?.airpay_password);
    setAirpayKey(gatewayInfo?.airpay_api_key);
  }, [gatewayInfo]);
  return (
    <>
      <Grid item xs={12}>
        <TextField
          size="small"
          id="outlined-basic"
          label="Airpay Merc Id"
          variant="outlined"
          fullWidth
          value={airpayId}
          onChange={(e) => setAirpayId(e.target.value)}
        />
      </Grid>
      <Grid item xs={12}>
        <TextField
          size="small"
          id="outlined-basic"
          label="Airpay Username"
          variant="outlined"
          fullWidth
          value={airpayUsername}
          onChange={(e) => setAirpayUsername(e.target.value)}
        />
      </Grid>
      <Grid item xs={12}>
        <TextField
          size="small"
          id="outlined-basic"
          label="Airpay Password"
          variant="outlined"
          fullWidth
          value={airpayPassword}
          onChange={(e) => setAirpayPassword(e.target.value)}
        />
      </Grid>
      <Grid item xs={12}>
        <TextField
          size="small"
          id="outlined-basic"
          label="Airpay Api Key"
          variant="outlined"
          fullWidth
          value={airpayKey}
          onChange={(e) => setAirpayKey(e.target.value)}
        />
      </Grid>
      <Grid item xs={12}>
        <Button
          variant="custom-button"
          sx={{ width: "100%" }}
          disabled={loader}
          onClick={() =>
            handleGatewaySaveButton(
              gatewayInfo,
              gateway,
              {
                airpay_merc_id: airpayId,
                airpay_username: airpayUsername,
                airpay_password: airpayPassword,
                airpay_api_key: airpayKey,
              },
              hotelId,
              hotelEmail,
              setRefreshGatewat,
              setLoader
            )
          }
        >
          {loader ? <CircularProgress size={20} /> : "Save"}
        </Button>
      </Grid>
    </>
  );
}

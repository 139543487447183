import * as React from "react";
import Popover from "@mui/material/Popover";

import Button from "@mui/material/Button";
import {
  Box,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  TextField,
} from "@mui/material";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import CancelIcon from "@mui/icons-material/Cancel";
import { useState } from "react";
import { useEffect } from "react";
import { handleSpringDataMutationRequest } from "../../utils/apiUtils";
import { toast } from "react-toastify";

import { CircularProgress } from "@mui/material";

export default function BasicPopOver({
  anchorEl,
  setAnchorEl,
  title,
  item,
  currentValue,
  pk,
  pkName,
  hotelID,
  hotelEmail,
  url,
  cotegry,
  setRefresh,
}) {
  //--------------To Close the Popover-------------
  const handleClose = () => {
    setAnchorEl(null);
  };
  const open = Boolean(anchorEl);
  //-------------------------Value Mutation States-------------------
  const [textBoxvalue, setTextBoxValue] = useState();
  const [dropDownYNvalue, setDropDownYNvalue] = useState();
  const [dropDownOFvalue, setDropDownOFvalue] = useState();
  const [loader, setLoader] = useState(false);
  useEffect(() => {
    setTextBoxValue(currentValue);
    setDropDownYNvalue(currentValue ? `${currentValue}` : "NO");
    setDropDownOFvalue(currentValue ? `${currentValue}` : "OFF");
  }, [currentValue]);

  const [password, setPassword] = useState();
  const [confirmPassword, setConfirmPassword] = useState();

  //-------------Updating Password-----------
  const handlePasswordUpdate = () => {
    // console.log(hotelID);

    if (password !== confirmPassword) {
      toast.error("Password should be same");
      return;
    }
    // const Token = localStorage.getItem("token");
    handleSpringDataMutationRequest("POST", "user/update-login-password", {
      email: hotelEmail,
      new_password: password,
      hotel_Id: hotelID,
    })
      .then(() => {
        setRefresh(new Date());
        toast.success("Password Changed Successfully");
        handleClose();
      })
      .catch((err) => {
        // console.log(err);
        toast.error(err?.response?.data);
      });
  };

  const handleSaveButton = () => {
    let payload;
    let newValue;
    item === 1 && (newValue = textBoxvalue?.replace(/\s/g, ""));
    item === 2 && (newValue = dropDownOFvalue);
    item === 4 && (newValue = dropDownYNvalue);
    if (cotegry === "attr") {
      if (title === "Email") {
        payload = {
          hotelId: hotelID,
          newUserEmail: newValue?.toLowerCase(),
          oldUserEmail: currentValue,
        };
      }else if(title === "GroupId"){
        payload = {
          hotelId: hotelID,
          groupId: newValue?.toLowerCase(),
          userEmail: hotelEmail,
        };
      } else {
        payload = {
          hotel_id: hotelID,
          pk: pk,
          value: title === "Email" ? newValue?.toLowerCase() : newValue,
          name: pkName,
        };
      }
    }
    if (cotegry === "flag") {
      payload = {
        name: pkName,
        value: newValue,
        pk: pk,
        hotel_id: hotelID,
        hotel_email: hotelEmail,
      };
    }
    if (cotegry === "token") {
      payload = {
        token: newValue,
        channelName: pkName,
      };
    }
    if (cotegry === "trip") {
      payload = {
        hotelId: hotelID,
        tripHotelId: newValue,
      };
    }
    console.log(payload);
    setLoader(true);

    handleSpringDataMutationRequest("POST", url, payload)
      .then((data) => {
        console.log(data);
        setRefresh(new Date());
        handleClose();
        toast.success(data);
        setLoader(false);
      })
      .catch((err) => {
        toast.error(err);
        setLoader(false);
      });
  };

  return (
    <div>
      <Popover
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
      >
        {item === 1 && (
          <Box
            sx={{
              p: 2,
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <TextField
              id="outlined-basic"
              label={title}
              variant="outlined"
              size="small"
              value={textBoxvalue}
              onChange={(e) => {
                setTextBoxValue(e.target.value);
              }}
            />
            <Button
              variant="outlined"
              sx={{ mr: 1, ml: 1 }}
              onClick={handleSaveButton}
              disabled={loader}
            >
              {loader ? <CircularProgress size={24} /> : <CheckCircleIcon />}
            </Button>
            <Button variant="outlined" color="error" onClick={handleClose}>
              <CancelIcon />
            </Button>
          </Box>
        )}
        {item === 2 && (
          <Box
            sx={{
              p: 2,
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <FormControl variant="standard" sx={{ m: 1, minWidth: 200 }}>
              <InputLabel id="demo-simple-select-standard-label">
                {title}
              </InputLabel>
              <Select
                labelId="demo-simple-select-standard-label"
                id="demo-simple-select-standard"
                value={dropDownOFvalue}
                onChange={(e) => {
                  setDropDownOFvalue(e.target.value);
                }}
                label={title}
                size="small"
              >
                {/* <MenuItem value="">
                  <em>None</em>
                </MenuItem> */}
                <MenuItem value="ON">ON</MenuItem>
                <MenuItem value="OFF">OFF</MenuItem>
              </Select>
            </FormControl>
            <Button
              variant="outlined"
              sx={{ mr: 1, ml: 1 }}
              onClick={handleSaveButton}
              disabled={loader}
            >
              {loader ? <CircularProgress size={24} /> : <CheckCircleIcon />}
            </Button>
            <Button variant="outlined" color="error" onClick={handleClose}>
              <CancelIcon />
            </Button>
          </Box>
        )}
        {item === 3 && (
          <Box
            sx={{
              p: 2,
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <TextField
              id="outlined-basic"
              label="Password"
              variant="outlined"
              size="small"
              sx={{ mr: 1 }}
              value={password}
              onChange={(e) => {
                setPassword(e.target.value);
              }}
            />
            <TextField
              id="outlined-basic"
              label="Confirm Password"
              variant="outlined"
              size="small"
              value={confirmPassword}
              onChange={(e) => {
                setConfirmPassword(e.target.value);
              }}
            />
            <Button
              variant="outlined"
              sx={{ mr: 1, ml: 1 }}
              onClick={handlePasswordUpdate}
              disabled={loader}
            >
              {loader ? <CircularProgress size={24} /> : <CheckCircleIcon />}
            </Button>
            <Button variant="outlined" color="error" onClick={handleClose}>
              <CancelIcon />
            </Button>
          </Box>
        )}
        {item === 4 && (
          <Box
            sx={{
              p: 2,
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <FormControl variant="standard" sx={{ m: 1, minWidth: 200 }}>
              <InputLabel id="demo-simple-select-standard-label">
                {title}
              </InputLabel>
              <Select
                labelId="demo-simple-select-standard-label"
                id="demo-simple-select-standard"
                value={dropDownYNvalue}
                onChange={(e) => {
                  setDropDownYNvalue(e.target.value);
                }}
                label={title}
                size="small"
              >
                <MenuItem value="NO">NO</MenuItem>
                <MenuItem value="YES">YES</MenuItem>
              </Select>
            </FormControl>
            <Button
              variant="outlined"
              sx={{ mr: 1, ml: 1 }}
              onClick={handleSaveButton}
              disabled={loader}
            >
              {loader ? <CircularProgress size={24} /> : <CheckCircleIcon />}
            </Button>
            <Button variant="outlined" color="error" onClick={handleClose}>
              <CancelIcon />
            </Button>
          </Box>
        )}
      </Popover>
    </div>
  );
}

import React, { useState, useEffect } from "react";
import Box from "@mui/material/Box";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import Paper from "@mui/material/Paper";
import { styled } from "@mui/material/styles";
import "./acc.css";
import {
  Grid,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  OutlinedInput,
  Autocomplete,
  TextField,
  FormHelperText,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
} from "@mui/material";
import company from "./sss.svg";
import moment from "moment-timezone";
import { isNull, isUndefined } from "lodash";

import axios from "axios";
import { RiFileEditFill } from "react-icons/ri";
import { toast } from "react-toastify";
import { BASE_SPRING_ENDPOINT } from "../../../utils/apiUtils/config";
import { getCountriesAndStates } from "../../../utils/common/CallenderConstants";
import { handleDjangoDataRequest } from "../../../utils/apiUtils";
import { toRegulerCase } from "../../../utils/Utils";
import HiddenTable from "./HiddenTable";

const CompanyInfoManagement = (props) => {
  // console.log(props);
  const [groupId, setGroupId] = useState(null);
  const [resp, setResp] = useState(null);
  const [hotelCurr, setHotelCurr] = useState(null);
  const [timeZones, settimeZones] = useState(null);

  useEffect(() => {
    const tznames = moment.tz.names();
    // console.log("tznames : ", tznames);
    settimeZones(tznames);
  }, []);

  useEffect(() => {
    setGroupId(props?.groupId);
    setResp(props?.res);
    setHotelCurr(props?.curr);
  }, [props]);
  // const style = {
  //   position: "top-right",
  //   autoClose: 5000,
  //   hideProgressBar: false,
  //   closeOnClick: true,
  //   pauseOnHover: true,
  //   draggable: true,
  //   // progress: undefined,
  // };

  function ValidateEmail(mail) {
    if (/^[a-zA-Z0-9_.+-]+@[a-zA-Z0-9-]+\.[a-zA-Z0-9-.]+$/.test(mail)) {
      setvalidEmail(true);
      return true;
    }
    setvalidEmail(false);
    return false;
  }

  const [currSymbol, setCurrSymbol] = useState(null);
  useEffect(() => {
    let curr = hotelCurr === "INR" ? "Rs." : "$";
    // const datass = Currencies?.filter((item) => {
    //   if (item.code === hotelCurr) {
    //     curr = item.symbol;
    //   }
    // });
    setCurrSymbol(curr);
  }, [hotelCurr]);

  const [accountInfo, setAccountInfo] = useState(null);
  const [accoundDetails, setAccoundDetails] = useState(null);
  const [paymentMethods, setpaymentMethods] = useState(null);
  const [subscriptionInfo, setSubscriptionInfo] = useState(null);
  const [billingEmail, setBillingEmail] = useState("");
  const [billingCCEmails, setBillingCCEmails] = useState("");
  const [billingStreetAddress, setBillingStreetAddress] = useState("");
  const [billingCity, setBillingCity] = useState("");
  const [billingAddressZipcode, setBillingAddressZipcode] = useState("");
  const [accountBillingPan, setAccountBillingPan] = useState("");
  const [accountBillingPhone, setAccountBillingPhone] = useState("");
  const [billingState, setBillingState] = useState("");
  const [billingCountry, setBillingCountry] = useState("");
  const countries = getCountriesAndStates();
  const [supportLevel, setSupportLevel] = useState("");
  const [billInterval, setBillInterval] = useState("");
  const [transferWithholding, setTransferWithholding] = useState("");
  const [billDay, setBillDay] = useState("");
  const [accountSuspend, setaccountSuspend] = useState("NO");
  const [shouldChargeCommission, setShouldChargeCommission] = useState("NO");

  const [paymentDuesNotification, setPaymentDuesNotification] = useState("YES");
  const ndcFileInput = React.useRef(null);
  const handleSupportLevelChange = (e) => {
    setSupportLevel(e.target.value);
  };

  //   accoundDetails?.account_name
  const [accountName, setAccountName] = useState(null);
  const handleAccountNameChange = (e) => {
    setAccountName(e.target.value);
  };
  // accoundDetails?.account_csm
  const [accountCSM, setAccountCSM] = useState(null);
  const handleAccountCsmChange = (e) => {
    setAccountCSM(e.target.value);
  };
  // accoundDetails?.account_csm_phone
  const [csmPhone, setCsmPhone] = useState(null);
  const handleCsmPhoneChange = (e) => {
    setCsmPhone(e.target.value);
  };
  // accoundDetails?.account_csm_email
  const [csmEmail, setCsmEmail] = useState("");
  const handleCsmEmailChange = (e) => {
    setCsmEmail(e.target.value);
  };
  // accoundDetails?.account_csm_calendar_link
  const [csmCldLink, setCsmCldLink] = useState(null);
  const handleCsmCldLinkChange = (e) => {
    setCsmCldLink(e.target.value);
  };
  // accoundDetails?.account_billing_timezone
  const [accBillTimeZone, setAccBillTimeZone] = useState(null);
  const handleAccBillTimeZoneChange = (e) => {
    setAccBillTimeZone(e.target.value);
  };
  // accoundDetails?.account_tax_id
  const [accTaxId, setAccTaxId] = useState(null);
  const handleAccTaxIdChange = (e) => {
    setAccTaxId(e.target.value);
  };
  // accoundDetails?.account_signup_date
  const [signUpDate, setSignUpDate] = useState("");
  const [pickDate, setPickDate] = useState(new Date());
  const handleSignUpDateChange = (newValue) => {
    setSignUpDate(newValue?.target?.value);
  };
  // const dateConversion = (newDate) => {
  //   const [year, month, day] = newDate.split("-").map(Number);
  //   const dateObject = new Date(year, month - 1, day);
  //   return dateObject;
  // };
  // accoundDetails?.account_status_log
  const [statusLog, setStatusLog] = useState(null);
  const handleStatusLogChange = (e) => {
    setStatusLog(e.target.value);
  };

  const setCompanyData = () => {
    setAccountInfo(resp);
    setAccoundDetails(resp?.account_details);
    setpaymentMethods(resp?.payment_methods);
    setSubscriptionInfo(resp?.subscription_info);
    setBillingEmail(resp?.account_details?.account_billing_email);
    setBillingCCEmails(resp?.account_details?.account_billing_cc_emails);
    setBillingStreetAddress(resp?.account_details?.billing_address_street);
    setBillingCity(resp?.account_details?.billing_address_city);
    setBillingState(resp?.account_details?.billing_address_state);
    setBillingCountry(resp?.account_details?.billing_address_country);
    setBillingAddressZipcode(resp?.account_details?.billing_address_zipcode);
    setAccountBillingPan(resp?.account_details?.account_billing_pan);
    setAccountBillingPhone(resp?.account_details?.account_billing_phone);
    setBillInterval(resp?.account_details?.account_billing_interval_months);
    setTransferWithholding(resp?.account_details?.withhold_transfer);
    setBillDay(resp?.account_details?.billing_day);
    setSupportLevel(resp?.account_details?.support_level);
    setAccountName(resp?.account_details?.account_name);
    setAccountCSM(resp?.account_details?.account_csm);
    setCsmPhone(resp?.account_details?.account_csm_phone);
    setCsmEmail(resp?.account_details?.account_csm_email);
    setCsmCldLink(resp?.account_details?.account_csm_calendar_link);
    setAccBillTimeZone(resp?.account_details?.account_billing_timezone);
    setAccTaxId(resp?.account_details?.account_tax_id);
    setSignUpDate(resp?.account_details?.account_signup_date?.split(" ")[0]);
    setStatusLog(resp?.account_details?.account_status_log);
    setaccountSuspend(resp?.account_details?.freezed);
    setShouldChargeCommission(resp?.account_details?.should_charge_commission);
    setPaymentDuesNotification(
      resp?.account_details?.payment_dues_notification_banner
    );
  };

  useEffect(() => {
    resp && setCompanyData();
  }, [resp]);

  const convertToBase64 = (eve) => {
    console.log(eve.target.files[0]);
    console.log("name of doc : ", eve.target.name);
    const file = eve.target.files[0];
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result);
      reader.onerror = (error) => reject(error);
    })
      .then((data) => {
        const type = data.split("/")[1].split(";")[0];
        const base64 = data.split(",")[1];
        // handleSetDocumentState(eve.target.name, type, base64);
        console.log("base64 : ", base64);
        let payload = {
          base64: base64,
        };
        axios
          .post(
            `${BASE_SPRING_ENDPOINT}` +
              `core/api/v1/account/upload-contract-pdf?groupId=${groupId}&hotelId=${groupId}`,
            payload
          )
          .then((res) => {
            console.log("res : ", res);
            toast.success(res?.data);
          })
          .catch((err) => {
            toast.error("Something wrong happened");
          });
      })
      .catch((err) => console.log("error caught : ", err));
  };

  const saveAccountDetails = () => {
    const accountInfo = accoundDetails;
    // setBillingEmail(resp?.account_details?.account_billing_email);
    accountInfo.account_billing_email = billingEmail;
    // setBillingCCEmails(resp?.account_details?.account_billing_cc_emails);
    accountInfo.account_billing_cc_emails = billingCCEmails;
    // setBillingStreetAddress(resp?.account_details?.billing_address_street);
    accountInfo.billing_address_street = billingStreetAddress;
    // setBillingCity(resp?.account_details?.billing_address_city);
    accountInfo.billing_address_city = billingCity;
    // setBillingState(resp?.account_details?.billing_address_state);
    accountInfo.billing_address_state = billingState;
    // setBillingCountry(resp?.account_details?.billing_address_country);
    accountInfo.billing_address_country = billingCountry;
    // setBillingAddressZipcode(resp?.account_details?.billing_address_zipcode);
    accountInfo["billing_address_zipcode"] = billingAddressZipcode;
    accountInfo.account_billing_pan = accountBillingPan;
    accountInfo.account_billing_phone = accountBillingPhone;
    // setBillInterval(resp?.account_details?.account_billing_interval_months);
    accountInfo.account_billing_interval_months = billInterval;
    // setTransferWithholding(resp?.account_details?.withhold_transfer);
    accountInfo.withhold_transfer = transferWithholding;
    // setBillDay(resp?.account_details?.billing_day);
    accountInfo.billing_day = billDay;
    // setSupportLevel(resp?.account_details?.support_level);
    accountInfo.supportLevel = supportLevel;
    // setAccountName(resp?.account_details?.account_name);
    accountInfo.account_name = accountName;
    // setAccountCSM(resp?.account_details?.account_csm);
    accountInfo.account_csm = accountCSM;
    // setCsmPhone(resp?.account_details?.account_csm_phone);
    accountInfo.account_csm_phone = csmPhone;
    // setCsmEmail(resp?.account_details?.account_csm_email);
    accountInfo.account_csm_email = csmEmail;
    // setCsmCldLink(resp?.account_details?.account_csm_calendar_link);
    accountInfo.account_csm_calendar_link = csmCldLink;
    // setAccBillTimeZone(resp?.account_details?.account_billing_timezone);
    accountInfo.account_billing_timezone = accBillTimeZone;
    // setAccTaxId(resp?.account_details?.account_tax_id);
    accountInfo.account_tax_id = accTaxId;
    // setSignUpDate(resp?.account_details?.account_signup_date);
    accountInfo.account_signup_date = signUpDate;
    // setStatusLog(resp?.account_details?.account_status_log);
    accountInfo.account_status_log = statusLog;

    const payload = {
      groupId: groupId,
      accountName: accountName,
      accountCsm: accountCSM ? accountCSM : "",
      accountBillingTimezone: accBillTimeZone,
      accountBillingIntervalMonths: billInterval,
      accountBillingEmail: billingEmail,
      accountBillingCcEmails: billingCCEmails,
      billingAddressStreet: billingStreetAddress,
      billingAddressCity: billingCity,
      billingAddressState: billingState,
      billingAddressCountry: billingCountry,
      billingAddressZipcode: billingAddressZipcode,
      accountBillingPan: accountBillingPan,
      accountBillingPhone: accountBillingPhone,
      accountSignupDate: signUpDate,
      accountTaxId: accTaxId,
      supportLevel: supportLevel,
      accountStatusLog: statusLog,
      accountCsmEmail: csmEmail ? csmEmail : "",
      accountCsmPhone: csmPhone ? csmPhone : "",
      accountCsmCalendarLink: csmCldLink ? csmCldLink : "",
      billingDay: billDay,
      withholdTransfer: transferWithholding,
      freezed: accountSuspend,
      shouldChargeCommission: shouldChargeCommission,
      paymentDuesNotificationBanner: paymentDuesNotification,
    };

    if (
      accountName === null ||
      accountName === undefined ||
      accountName === ""
    ) {
      toast.error("Please enter account name");
      return;
    }
    // if (accountCSM === null || accountCSM === undefined || accountCSM === "") {
    //   toast.error("Please enter account csm name");
    //   return;
    // }
    if (
      supportLevel === null ||
      supportLevel === undefined ||
      supportLevel === ""
    ) {
      toast.error("Please select support level");
      return;
    }
    // if (csmPhone === null || csmPhone === undefined || csmPhone === "") {
    //   toast.error("Please select CSM phone number");
    //   return;
    // }
    // if (csmEmail === null || csmEmail === undefined || csmEmail === "") {
    //   toast.error("Please select CSM email");
    //   return;
    // }
    if (
      accBillTimeZone === null ||
      accBillTimeZone === undefined ||
      accBillTimeZone === ""
    ) {
      toast.error("Please select account timezone");
      return;
    }
    // if (accTaxId === null || accTaxId === undefined || accTaxId === "") {
    //   toast.error("Please enter tax id");
    //   return;
    // }
    if (
      transferWithholding === null ||
      transferWithholding === undefined ||
      transferWithholding === ""
    ) {
      toast.error("Please select transfer withholding value");
      return;
    }
    if (billDay === null || billDay === undefined || billDay === "") {
      toast.error("Please select billing day");
      return;
    }
    if (
      billInterval === null ||
      billInterval === undefined ||
      billInterval === ""
    ) {
      toast.error("Please select billing interval");
      return;
    }
    if (
      billingEmail === null ||
      billingEmail === undefined ||
      billingEmail === ""
    ) {
      toast.error("Please enter billing email");
      return;
    }
    // if (
    //   billingStreetAddress === null ||
    //   billingStreetAddress === undefined ||
    //   billingStreetAddress === ""
    // ) {
    //   toast.error("Please enter billing street address");
    //   return;
    // }
    // if (
    //   billingCountry === null ||
    //   billingCountry === undefined ||
    //   billingCountry === ""
    // ) {
    //   toast.error("Please enter billing country");
    //   return;
    // }
    // if (
    //   billingState === null ||
    //   billingState === undefined ||
    //   billingState === ""
    // ) {
    //   toast.error("Please enter billing state");
    //   return;
    // }
    // if (
    //   billingCity === null ||
    //   billingCity === undefined ||
    //   billingCity === ""
    // ) {
    //   toast.error("Please enter billing city");
    //   return;
    // }
    // if (
    //   billingAddressZipcode === null ||
    //   billingAddressZipcode === undefined ||
    //   billingAddressZipcode === ""
    // ) {
    //   toast.error("Please enter billing address zipcode");
    //   return;
    // }

    if (validEmail) {
      axios
        .put(
          `${BASE_SPRING_ENDPOINT}` +
            `core/api/v1/account/update-account-details?groupId=${groupId}&hotelId=${groupId}`,
          payload
        )
        .then((res) => {
          toast.success("Billing info updated successfully");
        })
        .catch((err) => {
          toast.error("Something wrong happened");
        });
    } else {
      toast.error("Please enter correct email");
    }
  };

  const bull = (
    <Box
      component="span"
      sx={{ display: "inline-block", mx: "2px", transform: "scale(0.8)" }}
    >
      •
    </Box>
  );
  const Item = styled(Paper)(({ theme }) => ({
    backgroundColor: theme.palette.mode === "dark" ? "#1A2027" : "#fff",
    ...theme.typography.body2,
    padding: theme.spacing(1),
    textAlign: "center",
    color: theme.palette.text.secondary,
  }));

  const [validEmail, setvalidEmail] = useState(true);

  const [firstAddress, setfirstAddress] = useState(null);
  const [secondAddress, setsecondAddress] = useState(null);

  useEffect(() => {
    if (accoundDetails) {
      let first =
        accoundDetails?.billing_address_street !== ""
          ? accoundDetails?.billing_address_street
          : "";
      if (first?.length > 0)
        first =
          accoundDetails?.billing_address_city !== ""
            ? first + ", " + accoundDetails?.billing_address_city
            : first;
      else {
        first =
          accoundDetails?.billing_address_city !== ""
            ? accoundDetails?.billing_address_city
            : first;
      }

      let second =
        accoundDetails?.billing_address_state !== ""
          ? accoundDetails?.billing_address_state
          : "";
      if (second?.length > 0)
        second =
          accoundDetails?.billing_address_country !== ""
            ? second + ", " + accoundDetails?.billing_address_country
            : second;
      else {
        second =
          accoundDetails?.billing_address_country !== ""
            ? accoundDetails?.billing_address_country
            : second;
      }
      setfirstAddress(first);
      setsecondAddress(second);
    }
  }, [accoundDetails]);

  const [allUsers, setAllUsers] = useState([]);
  useEffect(() => {
    handleDjangoDataRequest("api/v2/metaAdmin/metaAdminAccessControl/")
      .then((data) =>
        setAllUsers(
          data.allUsers.sort(function (a, b) {
            return a.email.localeCompare(b.email);
          })
        )
      )
      .catch((err) => console.log(err));
  }, []);
  const logedUserEmail = localStorage.getItem("logedUserEmail");
  //------Rowdata-------
  const [rowData, setRowData] = useState([]);
  //ColumnDef --------------
  const [columnDefs, setColumnDefs] = useState();
  const [gridApi, setGridApi] = useState(null);
  const [selectedRows, setSelectedRows] = useState(null);
  const [selectedRowsLength, setSelectedRowsLength] = useState(0);

  const handleColumn = (data) => {
    const newColumn = Object.keys(data).map((elm) => {
      return { field: elm, headerName: toRegulerCase(elm) };
    });
    setColumnDefs(newColumn);
  };
  useEffect(() => {
    if (
      subscriptionInfo?.line_items &&
      subscriptionInfo?.line_items?.length > 0
    ) {
      handleColumn(subscriptionInfo?.line_items?.[0]);
      setRowData(subscriptionInfo?.line_items);
    }
  }, [subscriptionInfo]);

  const gridOptions = {
    // ... other grid options

    rowClassRules: {
      "custom-row-class-1": (params) =>
        params.data.current_invoice_status === "Pending" &&
        params.data.current_payment_status === "Pending",
      "custom-row-class-2": (params) =>
        params.data.current_invoice_status === "Raised" &&
        params.data.current_payment_status === "Pending",

      // Add more rules as needed based on your conditions
    },
  };

  const onSelectionChanged = (event) => {
    setSelectedRowsLength(event.api.getSelectedRows().length);
    setSelectedRows(event.api.getSelectedRows());
  };

  const onGridReady = (params) => {
    setGridApi(params.api);
  };

  const onExportClick = () => {
    selectedRowsLength === 0
      ? gridApi.exportDataAsCsv()
      : gridApi.exportDataAsCsv({ onlySelected: true });
  };
  const getDownloadType = (dType) => {
    dType === "1" && onExportClick();
  };
  return (
    <div>
      <Typography
        variant="h4"
        style={{ fontWeight: "400", marginBottom: "2rem" }}
      >
        {/* Company Information */}
        Account Information
      </Typography>
      <Card sx={{ minWidth: 275, marginBottom: "2rem" }}>
        <CardContent>
          <div
            style={{
              display: "flex",
              direction: "row",
            }}
            spacing={2}
          >
            <div
              style={{
                display: "flex",
                direction: "row",
                width: "100%",
              }}
              spacing={2}
            >
              <div style={{ padding: "3rem" }}>
                <img src={company} style={{ height: "12rem" }} />
              </div>
              <div>
                <Typography variant="h6" style={{ fontWeight: "400" }}>
                  {/* Company Name */}
                  Account Name
                </Typography>
                {accoundDetails && (
                  <Typography>{accoundDetails.account_name}</Typography>
                )}
                <Typography
                  variant="h6"
                  style={{ marginTop: "4rem", fontWeight: "400" }}
                >
                  Primary company address
                </Typography>
                {firstAddress && <Typography>{firstAddress}</Typography>}
                {secondAddress && <Typography>{secondAddress}</Typography>}
                <br />
                {accoundDetails && accoundDetails?.accounting_version === 1 && (
                  <Typography className="text-muted">
                    Note : This account uses old accounting model
                  </Typography>
                )}
                {accoundDetails && accoundDetails?.accounting_version === 2 && (
                  <Typography className="text-muted">
                    Note : This account uses new accounting model
                  </Typography>
                )}
              </div>
            </div>
            <div
              style={{
                display: "flex",
                width: "100%",
                justifyContent: "end",
                marginRight: "3rem",
              }}
            >
              {accoundDetails &&
                accoundDetails.contract_upload &&
                accoundDetails.contract_upload !== null &&
                accoundDetails.contract_upload !== "" && (
                  // <button
                  //   className="btn btn-light-primary btn-bold"
                  //   style={{
                  //     height: "fit-content",
                  //     fontSize: "20px",
                  //     paddingTop: "5px",
                  //     paddingBottom: "5px",
                  //   }}
                  //   // variant="custom_button custom-button"
                  //   onClick={() => {
                  //     downloadContract();
                  //   }}
                  // >
                  //   Download contract
                  // </button>
                  <a
                    type="button"
                    style={{ fontSize: "1.3rem", color: "#1BC5BD" }}
                    href={
                      BASE_SPRING_ENDPOINT +
                      "core/api/v1/account/download-account-contract/" +
                      groupId +
                      "?hotelId=" +
                      groupId
                    }
                  >
                    Download contract
                  </a>
                  // <a
                  //   type="button"
                  //   style={{ fontSize: "1.3rem", color: "#1BC5BD" }}

                  //   onClick={() => downloadContract()}
                  //   // href={
                  //   //   "https://stayflexi.com/media/" +
                  //   //   accoundDetails.contract_upload
                  //   // }
                  // >
                  //   Download contract
                  // </a>
                )}
            </div>
            <div className="d-flex">
              <Button
                variant="custom-button"
                className="mr-4 ml-2"
                style={{ height: "3rem" }}
                endIcon={<RiFileEditFill />}
                onClick={() => {
                  ndcFileInput.current.click();
                }}
                // disabled={!editDetailsFlag}
              >
                Upload Contract
                <input
                  style={{
                    color: "" !== "" ? "#20c997" : "black",
                    display: "none",
                  }}
                  id="ndc"
                  type="file"
                  className="form-control"
                  accept=".pdf"
                  name="ndc"
                  ref={ndcFileInput}
                  onChange={(eve) => {
                    let imageName = document.getElementById("ndcName");
                    let inputImage = eve.target.files[0];
                    imageName.innerText = inputImage.name;
                    convertToBase64(eve);
                  }}
                />
              </Button>
              <span
                id="ndcName"
                style={{
                  color: "green",
                  fontSize: "small",
                  marginLeft: "1rem",
                  display: "none",
                }}
              ></span>
            </div>
            {/* <div
              style={{
                padding: "0%",
                textAlign: "center",
                border: "thin solidBlack",
              }}
            >
              <label for="agreement" style={{ cursor: "pointer" }}>
                Upload Contract
                <br />
                <i class="fa fa-file-pdf-o" aria-hidden="true"></i>
                <input
                  style={{
                    color: "" !== "" ? "#20c997" : "black",
                    display: "none",
                  }}
                  id="agreement"
                  type="file"
                  className="form-control"
                  accept=".pdf"
                  name="agreement"
                  onChange={(eve) => {
                    let imageName = document.getElementById("agreementName");
                    let inputImage = eve.target.files[0];
                    imageName.innerText = inputImage.name;
                    convertToBase64(eve);
                  }}
                />
                <br />
                <span
                  id="agreementName"
                  style={{ color: "green", fontSize: "small" }}
                ></span>
              </label>
            </div> */}
          </div>
        </CardContent>
      </Card>
      <Card sx={{ minWidth: 275, marginBottom: "2rem" }}>
        <Typography
          variant="h6"
          style={{
            fontWeight: "400",
            marginTop: "1rem",
            marginLeft: "2rem",
          }}
        >
          Basic Info
        </Typography>
        <CardContent>
          <div
            style={{
              display: "flex",
              direction: "row",
            }}
            spacing={2}
          >
            <Grid container spacing={2}>
              <Grid container spacing={2} item xs={12}>
                <Grid item xs={3}>
                  <Typography className="mt-4" variant="h6">
                    Group id
                  </Typography>{" "}
                  <OutlinedInput
                    disabled={true}
                    size="small"
                    label="hello"
                    className="mt-3"
                    fullWidth
                    value={accoundDetails?.group_id}
                    // onChange={(e) => setbookingFolioPrefix(e.target.value)}
                  />
                </Grid>
                <Grid item xs={3}>
                  {" "}
                  <Typography className="mt-4" variant="h6">
                    Account name
                  </Typography>{" "}
                  <OutlinedInput
                    notched={false}
                    // disabled={true}
                    size="small"
                    label="hello"
                    className="mt-3"
                    fullWidth
                    value={accountName}
                    onChange={handleAccountNameChange}
                  />
                  <p className="text-muted">
                    Email used when onboarding the property(s)
                  </p>
                </Grid>
                <Grid item xs={3}>
                  {" "}
                  <Typography className="mt-4" variant="h6">
                    Account CSM
                  </Typography>{" "}
                  <OutlinedInput
                    notched={false}
                    // disabled={true}
                    size="small"
                    label="hello"
                    className="mt-3"
                    fullWidth
                    value={accountCSM}
                    onChange={handleAccountCsmChange}
                  />
                  <p className="text-muted">
                    Who is the customer success manager for this account?
                  </p>
                </Grid>
                <Grid item xs={3}>
                  {" "}
                  <Typography className="mt-4" variant="h6">
                    Support Level
                  </Typography>
                  <FormControl fullWidth className="mt-3">
                    <InputLabel id="demo-simple-select-helper-label">
                      Support level
                    </InputLabel>
                    <Select
                      labelId="demo-simple-select-helper-label"
                      id="demo-simple-select-helper"
                      value={supportLevel}
                      label="Support level"
                      onChange={handleSupportLevelChange}
                      size="small"
                    >
                      <MenuItem value="LEVEL1">Only email support</MenuItem>
                      <MenuItem value="LEVEL2">Email+phone support</MenuItem>
                      <MenuItem value="LEVEL3">
                        Email+phone+chat support
                      </MenuItem>
                    </Select>
                  </FormControl>
                  <p className="text-muted">Support level for this account?</p>
                </Grid>
                <Grid item xs={3}>
                  {" "}
                  <Typography className="mt-4" variant="h6">
                    CSM phone
                  </Typography>{" "}
                  <OutlinedInput
                    notched={false}
                    // disabled={true}
                    size="small"
                    label="hello"
                    className="mt-3"
                    fullWidth
                    value={csmPhone}
                    onChange={handleCsmPhoneChange}
                  />
                  <p className="text-muted">CSM phone number</p>
                </Grid>
                <Grid item xs={3}>
                  {" "}
                  <Typography className="mt-4" variant="h6">
                    CSM email
                  </Typography>
                  <FormControl fullWidth className="mt-3">
                    <InputLabel id="demo-simple-select-helper-label">
                      CSM email
                    </InputLabel>
                    <Select
                      labelId="demo-simple-select-helper-label"
                      id="demo-simple-select-helper"
                      value={csmEmail}
                      label="CSM email"
                      onChange={handleCsmEmailChange}
                      size="small"
                    >
                      {allUsers?.map((elm) => (
                        <MenuItem value={elm?.email}>{elm?.email}</MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                  <p className="text-muted">CSM email</p>
                </Grid>

                {/* <Grid item xs={3}>
                  {" "}
                  <Typography className="mt-4" variant="h6">
                    CSM email
                  </Typography>{" "}
                  <OutlinedInput
                    notched={false}
                    // disabled={true}
                    size="small"
                    label="hello"
                    className="mt-3"
                    fullWidth
                    value={csmEmail}
                    onChange={handleCsmEmailChange}
                  />
                  <p className="text-muted">CSM email</p>
                </Grid> */}
                <Grid item xs={3}>
                  {" "}
                  <Typography className="mt-4" variant="h6">
                    CSM Scheduling link
                  </Typography>{" "}
                  <OutlinedInput
                    notched={false}
                    // disabled={true}
                    size="small"
                    label="hello"
                    className="mt-3"
                    fullWidth
                    value={csmCldLink}
                    onChange={handleCsmCldLinkChange}
                  />
                  <p className="text-muted">
                    CSM scheduling link, like Calendly
                  </p>
                </Grid>
                <Grid item xs={3}>
                  <Typography className="mt-4 mb-3" variant="h6">
                    Account billing timezone
                  </Typography>{" "}
                  {/* timeZones */}
                  <Autocomplete
                    fullWidth
                    onChange={(eve, val) => {
                      setAccBillTimeZone(val);
                    }}
                    options={timeZones}
                    value={accBillTimeZone}
                    renderInput={(params) => {
                      params.inputProps.autoComplete = "new-password";
                      return (
                        <TextField
                          {...params}
                          label="Account billing timezone"
                          size="small"
                        />
                      );
                    }}
                  ></Autocomplete>
                  {/* {timeZones && (
                    <FormControl fullWidth className="mt-3">
                      <InputLabel id="demo-simple-select-helper-label">
                        Account billing timezone
                      </InputLabel>
                      <Select
                        labelId="demo-simple-select-helper-label"
                        id="demo-simple-select-helper"
                        value={accBillTimeZone}
                        label="Account billing timezone"
                        onChange={handleAccBillTimeZoneChange}
                        size="small"
                      >
                        {timeZones &&
                          timeZones.map((res) => {
                            console.log("The res is : ", res);
                            return <MenuItem value={res}>{res}</MenuItem>;
                          })}
                      </Select>
                    </FormControl>
                  )} */}
                  {/* <OutlinedInput
                    // disabled={true}
                    size="small"
                    label="hello"
                    className="mt-3"
                    fullWidth
                    value={accoundDetails?.account_billing_timezone}
                    // onChange={(e) => setbookingFolioPrefix(e.target.value)}
                  /> */}
                  <p className="text-muted">
                    Timezone to be used for generating invoices
                  </p>
                </Grid>
              </Grid>
              <Grid container spacing={2} item xs={12}>
                <Grid item xs={3}>
                  {" "}
                  <Typography className="mt-4" variant="h6">
                    Account tax id
                  </Typography>{" "}
                  <OutlinedInput
                    notched={false}
                    // disabled={true}
                    size="small"
                    label="hello"
                    className="mt-3"
                    fullWidth
                    value={accTaxId}
                    onChange={handleAccTaxIdChange}
                  />
                  <p className="text-muted">
                    Account Tax ID. For e.g, GST for India
                  </p>
                </Grid>
                <Grid item xs={3}>
                  {" "}
                  <Typography className="mt-4" variant="h6">
                    Transfer withholding
                  </Typography>
                  <FormControl fullWidth className="mt-3">
                    <InputLabel id="demo-simple-select-helper-label">
                      Transfer withholding
                    </InputLabel>
                    <Select
                      labelId="demo-simple-select-helper-label"
                      id="demo-simple-select-helper"
                      value={transferWithholding}
                      label="Transfer withholding"
                      onChange={(e) => setTransferWithholding(e.target.value)}
                      size="small"
                    >
                      <MenuItem value="DISABLED">DISABLED</MenuItem>
                      <MenuItem value="ENABLED">ENABLED</MenuItem>
                    </Select>
                  </FormControl>
                  <p className="text-muted">
                    Withhold transfer if account balance is -ve
                  </p>
                </Grid>

                <Grid item xs={3}>
                  <Typography className="mt-4" variant="h6">
                    Billing day
                  </Typography>{" "}
                  <FormControl fullWidth className="mt-3">
                    <InputLabel id="demo-simple-select-helper-label">
                      Billing day
                    </InputLabel>
                    <Select
                      labelId="demo-simple-select-helper-label"
                      id="demo-simple-select-helper"
                      value={billDay}
                      label="Billing day"
                      onChange={(e) => {
                        setBillDay(e.target.value);
                      }}
                      size="small"
                    >
                      <MenuItem value="1">1</MenuItem>
                      <MenuItem value="2">2</MenuItem>
                      <MenuItem value="3">3</MenuItem>
                      <MenuItem value="4">4</MenuItem>
                      <MenuItem value="5">5</MenuItem>
                      <MenuItem value="6">6</MenuItem>
                      <MenuItem value="7">7</MenuItem>
                      <MenuItem value="8">8</MenuItem>
                      <MenuItem value="9">9</MenuItem>
                      <MenuItem value="10">10</MenuItem>
                      <MenuItem value="11">11</MenuItem>
                      <MenuItem value="12">12</MenuItem>
                      <MenuItem value="13">13</MenuItem>
                      <MenuItem value="14">14</MenuItem>
                      <MenuItem value="15">15</MenuItem>
                    </Select>
                  </FormControl>
                  {/* <OutlinedInput
                    disabled={true}
                    size="small"
                    label="Billing Interval"
                    className="mt-3"
                    fullWidth
                    value={
                      accoundDetails?.account_billing_interval_months +
                      " Months"
                    }
                    // onChange={(e) => setbookingFolioPrefix(e.target.value)}
                  /> */}
                  <p className="text-muted">
                    Account will be charged automatically on this day
                  </p>
                </Grid>
                <Grid item xs={3}>
                  {""}
                  <Typography className="mt-4" variant="h6">
                    Signup date
                  </Typography>{" "}
                  <div className="datePicker">
                    <TextField
                      className="mt-3"
                      style={{ width: "25.3rem" }}
                      type="date"
                      id="outlined-basic"
                      label="Signup date"
                      variant="outlined"
                      size="small"
                      value={signUpDate}
                      onChange={handleSignUpDateChange}
                    />
                    {/* <LocalizationProvider dateAdapter={AdapterDateFns}>
                      <DesktopDatePicker
                        size="small"
                        label="Signup date"
                        inputFormat="dd/MM/yyyy"
                        value={signUpDate}
                        onChange={handleSignUpDateChange}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            id="outlined-basic"
                            className="mt-3"
                            style={{ width: "25.3rem" }}
                            size="small"
                          />
                        )}
                      />
                    </LocalizationProvider> */}
                  </div>
                  {/* <OutlinedInput
                    // disabled={true}
                    size="small"
                    label="hello"
                    className="mt-3"
                    fullWidth
                    value={moment(signUpDate).format("DD-MM-YYYY")}
                    onChange={handleSignUpDateChange}
                  /> */}
                  <p className="text-muted">When was this account onboarded?</p>
                </Grid>

                <Grid item xs={3}>
                  <Typography className="mt-4" variant="h6">
                    Account suspend
                  </Typography>
                  <FormControl fullWidth className="mt-3">
                    <InputLabel id="demo-simple-select-helper-label">
                      Account suspend
                    </InputLabel>
                    <Select
                      labelId="demo-simple-select-helper-label"
                      id="demo-simple-select-helper"
                      value={accountSuspend}
                      label="Account suspend"
                      onChange={(e) => {
                        setaccountSuspend(e.target.value);
                      }}
                      size="small"
                    >
                      <MenuItem value={"YES"}>Yes</MenuItem>
                      <MenuItem value={"NO"}>No</MenuItem>
                    </Select>
                  </FormControl>
                  <p className="text-muted">
                    Account will be be suspend if select yes
                  </p>
                </Grid>
                <Grid item xs={3}>
                  <Typography className="mt-4" variant="h6">
                    Charge direct bookings commission
                  </Typography>
                  <FormControl fullWidth className="mt-3">
                    <InputLabel id="demo-simple-select-helper-label">
                      Charge direct bookings commission
                    </InputLabel>
                    <Select
                      labelId="demo-simple-select-helper-label"
                      id="demo-simple-select-helper"
                      value={shouldChargeCommission}
                      label="Charge direct bookings commission"
                      onChange={(e) => {
                        setShouldChargeCommission(e.target.value);
                      }}
                      size="small"
                    >
                      <MenuItem value={"YES"}>Yes</MenuItem>
                      <MenuItem value={"NO"}>No</MenuItem>
                    </Select>
                  </FormControl>
                  <p className="text-muted">
                    Applicable for accounts using frontdesk PG processor to
                    process direct booking payments. Tells system to charge same
                    bank account for commissions
                  </p>
                </Grid>
              </Grid>
              <Grid container spacing={2} item xs={12}>
                <Grid item xs={12}>
                  <Typography className="mt-4" variant="h6">
                    Account Notes
                  </Typography>{" "}
                  <OutlinedInput
                    notched={false}
                    // disabled={true}
                    id="outlined-basic"
                    name="aboutUsFullText"
                    label="hello"
                    multiline
                    rows={5}
                    maxRows={25}
                    variant="outlined"
                    value={statusLog}
                    onChange={handleStatusLogChange}
                    sx={{ width: "104.6rem" }}
                    type="text"
                  />
                  <p className="text-muted">Log any notes for the account</p>
                </Grid>
              </Grid>
            </Grid>
          </div>
        </CardContent>
      </Card>
      <Card sx={{ minWidth: 275, marginBottom: "2rem" }}>
        <Typography
          variant="h6"
          style={{
            fontWeight: "400",
            marginTop: "1rem",
            marginLeft: "2rem",
          }}
        >
          Billing Information
        </Typography>
        <CardContent>
          <div
            style={{
              display: "flex",
              direction: "row",
            }}
            spacing={2}
          >
            <Grid container spacing={2}>
              <Grid container spacing={2} item xs={12}>
                <Grid item xs={4}>
                  <Typography className="mt-4" variant="h6">
                    Billing interval
                  </Typography>{" "}
                  <FormControl fullWidth className="mt-3">
                    <InputLabel id="demo-simple-select-helper-label">
                      Billing interval
                    </InputLabel>
                    <Select
                      labelId="demo-simple-select-helper-label"
                      id="demo-simple-select-helper"
                      value={billInterval}
                      label="Billing interval"
                      onChange={(e) => {
                        setBillInterval(e.target.value);
                      }}
                      size="small"
                    >
                      <MenuItem value="1">1</MenuItem>
                      <MenuItem value="2">2</MenuItem>
                      <MenuItem value="3">3</MenuItem>
                      <MenuItem value="4">4</MenuItem>
                      <MenuItem value="5">5</MenuItem>
                      <MenuItem value="6">6</MenuItem>
                      <MenuItem value="7">7</MenuItem>
                      <MenuItem value="8">8</MenuItem>
                      <MenuItem value="9">9</MenuItem>
                      <MenuItem value="10">10</MenuItem>
                      <MenuItem value="11">11</MenuItem>
                      <MenuItem value="12">12</MenuItem>
                      <MenuItem value="13">13</MenuItem>
                      <MenuItem value="14">14</MenuItem>
                      <MenuItem value="15">15</MenuItem>
                      <MenuItem value="16">16</MenuItem>
                      <MenuItem value="17">17</MenuItem>
                      <MenuItem value="18">18</MenuItem>
                      <MenuItem value="19">19</MenuItem>
                      <MenuItem value="20">20</MenuItem>
                      <MenuItem value="21">21</MenuItem>
                      <MenuItem value="22">22</MenuItem>
                      <MenuItem value="23">23</MenuItem>
                      <MenuItem value="24">24</MenuItem>
                      <MenuItem value="25">25</MenuItem>
                      <MenuItem value="26">26</MenuItem>
                      <MenuItem value="27">27</MenuItem>
                      <MenuItem value="28">28</MenuItem>
                      <MenuItem value="29">29</MenuItem>
                      <MenuItem value="30">30</MenuItem>
                      <MenuItem value="31">31</MenuItem>
                      <MenuItem value="32">32</MenuItem>
                      <MenuItem value="33">33</MenuItem>
                      <MenuItem value="34">34</MenuItem>
                      <MenuItem value="35">35</MenuItem>
                      <MenuItem value="36">36</MenuItem>
                    </Select>
                  </FormControl>
                  {/* <OutlinedInput
                    disabled={true}
                    size="small"
                    label="Billing Interval"
                    className="mt-3"
                    fullWidth
                    value={
                      accoundDetails?.account_billing_interval_months +
                      " Months"
                    }
                    // onChange={(e) => setbookingFolioPrefix(e.target.value)}
                  /> */}
                  <p className="text-muted">Billing interval in months</p>
                </Grid>
                <Grid item xs={4}>
                  {" "}
                  <Typography className="mt-4" variant="h6">
                    Billing email
                  </Typography>{" "}
                  <OutlinedInput
                    // disabled={true}
                    size="small"
                    notched={false}
                    label="hello"
                    className="mt-3"
                    fullWidth
                    value={billingEmail}
                    error={!validEmail}
                    onChange={(e) => {
                      ValidateEmail(e.target.value);
                      setBillingEmail(e.target.value);
                    }}
                  />
                  {!validEmail && (
                    <FormHelperText error>Please enter email!</FormHelperText>
                  )}
                  <p className="text-muted">
                    Email address to which the invoice will be sent
                  </p>
                </Grid>
                <Grid item xs={4}>
                  {" "}
                  <Typography className="mt-4" variant="h6">
                    Billing CC emails
                  </Typography>{" "}
                  <OutlinedInput
                    // disabled={true}
                    size="small"
                    label="hello"
                    className="mt-3"
                    fullWidth
                    value={billingCCEmails}
                    onChange={(e) => setBillingCCEmails(e.target.value)}
                  />
                  <p className="text-muted">
                    Comma seperated email addresses to which invoice email
                    should be copied to
                  </p>
                </Grid>
                <Grid item xs={4}>
                  <Typography className="mt-4" variant="h6">
                    Billing street address
                  </Typography>{" "}
                  <OutlinedInput
                    // disabled={true}
                    notched={false}
                    size="small"
                    label="hello"
                    className="mt-3"
                    fullWidth
                    value={billingStreetAddress}
                    // error={billingStreetAddress === "" ? true : false}
                    onChange={(e) => setBillingStreetAddress(e.target.value)}
                  />
                  {/* {billingStreetAddress === "" && (
                    <FormHelperText error>Please enter address!</FormHelperText>
                  )} */}
                </Grid>
                <Grid item xs={4}>
                  <Typography
                    className="mt-4"
                    variant="h6"
                    style={{ marginBottom: "9px" }}
                  >
                    Billing country
                  </Typography>{" "}
                  <Autocomplete
                    fullWidth
                    onChange={(eve, val) => {
                      setBillingCountry(val);
                    }}
                    options={Object.keys(countries)}
                    value={billingCountry}
                    renderInput={(params) => {
                      params.inputProps.autoComplete = "new-password";
                      return (
                        <TextField {...params} label="Country" size="small" />
                      );
                    }}
                  ></Autocomplete>
                  {/* <OutlinedInput
                    // disabled={true}
                    size="small"
                    label="hello"
                    className="mt-3"
                    fullWidth
                    value={billingCountry}
                    onChange={(e) => setBillingCountry(e.target.value)}
                  /> */}
                </Grid>

                <Grid item xs={4}>
                  {" "}
                  <Typography
                    className="mt-4"
                    variant="h6"
                    style={{ marginBottom: "9px" }}
                  >
                    Billing state
                  </Typography>{" "}
                  <Autocomplete
                    fullWidth
                    renderInput={(params) => {
                      params.inputProps.autoComplete = "new-password";
                      return (
                        <TextField {...params} label="State" size="small" />
                      );
                    }}
                    onChange={(eve, val) => {
                      setBillingState(val);
                    }}
                    options={
                      isNull(billingCountry) ||
                      isUndefined(countries[billingCountry])
                        ? []
                        : countries[billingCountry]
                    }
                    value={billingState}
                  ></Autocomplete>
                  {/* <OutlinedInput
                    // disabled={true}
                    size="small"
                    label="hello"
                    className="mt-3"
                    fullWidth
                    value={billingState}
                    onChange={(e) => setBillingState(e.target.value)}
                  /> */}
                </Grid>
              </Grid>
              <Grid container spacing={2} item xs={12}>
                <Grid item xs={4}>
                  {" "}
                  <Typography className="mt-4" variant="h6">
                    Billing city
                  </Typography>{" "}
                  <OutlinedInput
                    // disabled={true}
                    size="small"
                    notched={false}
                    label="hello"
                    className="mt-3"
                    fullWidth
                    value={billingCity}
                    // error={billingCity === "" ? true : false}
                    onChange={(e) => setBillingCity(e.target.value)}
                  />
                  {/* {billingCity === "" && (
                    <FormHelperText error>Please enter city!</FormHelperText>
                  )} */}
                </Grid>
                <Grid item xs={4}>
                  {" "}
                  <Typography className="mt-4" variant="h6">
                    Billing address zipcode
                  </Typography>{" "}
                  <OutlinedInput
                    // disabled={true}
                    size="small"
                    notched={false}
                    label="hello"
                    className="mt-3"
                    fullWidth
                    value={billingAddressZipcode}
                    // error={billingAddressZipcode === "" ? true : false}
                    onChange={(e) => setBillingAddressZipcode(e.target.value)}
                  />
                  {/* {billingAddressZipcode === "" && (
                    <FormHelperText error>Please enter zipcode!</FormHelperText>
                  )} */}
                </Grid>
                <Grid item xs={4}>
                  {" "}
                  <Typography className="mt-4" variant="h6">
                    Account billing Pan
                  </Typography>{" "}
                  <OutlinedInput
                    // disabled={true}
                    size="small"
                    notched={false}
                    label="hello"
                    className="mt-3"
                    fullWidth
                    value={accountBillingPan}
                    // error={billingAddressZipcode === "" ? true : false}
                    onChange={(e) => setAccountBillingPan(e.target.value)}
                  />
                  {/* {billingAddressZipcode === "" && (
                    <FormHelperText error>Please enter zipcode!</FormHelperText>
                  )} */}
                </Grid>
                <Grid item xs={4}>
                  {" "}
                  <Typography className="mt-4" variant="h6">
                    Account billing phone
                  </Typography>{" "}
                  <OutlinedInput
                    // disabled={true}
                    size="small"
                    notched={false}
                    label="hello"
                    className="mt-3"
                    fullWidth
                    value={accountBillingPhone}
                    // error={billingAddressZipcode === "" ? true : false}
                    onChange={(e) => setAccountBillingPhone(e.target.value)}
                  />
                  {/* {billingAddressZipcode === "" && (
                    <FormHelperText error>Please enter zipcode!</FormHelperText>
                  )} */}
                </Grid>
                <Grid item xs={3}>
                  <Typography className="mt-4" variant="h6">
                    Payment dues notification banner in PMS
                  </Typography>{" "}
                  <FormControl fullWidth className="mt-3">
                    <InputLabel id="demo-simple-select-helper-label">
                      Payment dues notification banner in PMS
                    </InputLabel>
                    <Select
                      labelId="demo-simple-select-helper-label"
                      id="demo-simple-select-helper"
                      value={paymentDuesNotification}
                      label="Payment dues notification banner in PMS"
                      onChange={(e) => {
                        setPaymentDuesNotification(e.target.value);
                      }}
                      size="small"
                    >
                      <MenuItem value="YES">YES</MenuItem>
                      <MenuItem value="NO">NO</MenuItem>
                    </Select>
                  </FormControl>
                  {/* <p className="text-muted">
                    Account will be charged automatically on this day
                  </p> */}
                </Grid>
              </Grid>
              <Grid container spacing={2} item xs={12}></Grid>
            </Grid>
          </div>

          <div style={{ marginTop: "2rem" }}>
            <Button
              variant="custom-button"
              onClick={() => saveAccountDetails()}
            >
              Save
            </Button>
          </div>
        </CardContent>
      </Card>
      <Card sx={{ minWidth: 275, marginBottom: "2rem" }}>
        <Box sx={{ display: "flex", gap: 3, alignItems: "center" }}>
          <Typography
            variant="h6"
            style={{
              fontWeight: "400",
              marginTop: "1rem",
              marginLeft: "2rem",
            }}
          >
            Account Subscription Information
          </Typography>
          {logedUserEmail === "venkatesh.sakamuri@stayflexi.com" && (
            <Box sx={{ minWidth: 120, p: 2 }}>
              <FormControl fullWidth size="small">
                <InputLabel id="demo-simple-select-label">Export as</InputLabel>
                <Select
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  value={"0"}
                  label="Export as"
                  onChange={(e) => getDownloadType(e.target.value)}
                >
                  <MenuItem value={"0"}>Select option</MenuItem>
                  <MenuItem value={"1"}>Export as CSV</MenuItem>
                </Select>
              </FormControl>
            </Box>
          )}
        </Box>

        <CardContent>
          <div
            style={{
              display: "flex",
              direction: "row",
            }}
            spacing={2}
          >
            <TableContainer component={Paper}>
              <Table sx={{ minWidth: 650 }} aria-label="simple table">
                <TableHead style={{ background: "#F5F8FA" }}>
                  <TableRow>
                    <TableCell>Property ID</TableCell>
                    <TableCell style={{ width: "50rem" }}>
                      Property name
                    </TableCell>
                    {/*<TableCell>{"MRR"}</TableCell>
                    <TableCell>{"Subscription amount"}</TableCell>
                    <TableCell>{"Addon amount"}</TableCell>
                    <TableCell>{"Total amount"}</TableCell> */}
                    <TableCell>{"Description"}</TableCell>
                    <TableCell>{"StartDate"}</TableCell>
                    <TableCell>{"Status"}</TableCell>
                    <TableCell>{"Amount"}</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {subscriptionInfo &&
                    subscriptionInfo?.line_items?.length > 0 &&
                    subscriptionInfo.line_items.map((res) => (
                      <TableRow
                        key="xyz"
                        sx={{
                          "&:last-child td, &:last-child th": { border: 0 },
                        }}
                      >
                        <TableCell
                          style={{ width: "20rem" }}
                          component="th"
                          scope="row"
                          // onClick={() => {
                          //   toggleHotelId(res.hotelId);
                          // }}
                        >
                          {res.hotel_id}
                        </TableCell>
                        <TableCell style={{ width: "30rem" }}>
                          {res.line_item_name}
                        </TableCell>
                        <TableCell style={{ width: "50rem" }}>
                          {res.description}
                        </TableCell>
                        <TableCell style={{ width: "10rem" }}>
                          {res.start_date}
                        </TableCell>
                        <TableCell style={{ width: "10rem" }}>
                          {res.status}
                        </TableCell>
                        <TableCell style={{ width: "10rem" }}>
                          {res.amount && true && res.amount > 0 && (
                            <span>{currSymbol + "" + res.amount}</span>
                          )}
                        </TableCell>
                      </TableRow>
                    ))}
                  {subscriptionInfo &&
                    subscriptionInfo.total_account_subscription_amount !==
                      undefined &&
                    subscriptionInfo.line_items.length > 0 && (
                      <TableRow
                        key="xyz"
                        sx={{
                          "&:last-child td, &:last-child th": { border: 0 },
                        }}
                      >
                        <TableCell
                          component="th"
                          scope="row"
                          // onClick={() => {
                          //   toggleHotelId(res.hotelId);
                          // }}
                        >
                          Total
                        </TableCell>
                        <TableCell style={{ width: "50rem" }}></TableCell>
                        <TableCell></TableCell>
                        <TableCell>
                          {currSymbol +
                            "" +
                            subscriptionInfo.total_account_subscription_amount}
                        </TableCell>
                      </TableRow>
                    )}
                </TableBody>
              </Table>
            </TableContainer>
          </div>
        </CardContent>
      </Card>
      <Box sx={{ display: "none" }}>
        <HiddenTable
          rowData={rowData}
          columnDefs={columnDefs}
          gridOptions={gridOptions}
          onGridReady={onGridReady}
          onSelectionChanged={onSelectionChanged}
        />
      </Box>
      {/* <Typography
        variant="h4"
        style={{ fontWeight: "400", marginTop: "2rem", marginBottom: "2rem" }}
      >
        Points of Contact
      </Typography>
      <div style={{ display: "flex", flexDirection: "row" }}>
        <Card
          sx={{ minWidth: 350 }}
          style={{ marginRight: "4rem", width: "50%" }}
        >
          <CardContent>
            <div
              style={{
                display: "flex",
                direction: "row",
              }}
              spacing={2}
            >
              <div>
                <Typography
                  variant="h6"
                  style={{ fontWeight: "400", marginBottom: "2rem" }}
                >
                  Primary Account Contact
                </Typography>
                <Typography>
                  The main person working with the account on a daily basis.
                  This user will receive all important account notifications
                  (including invoices, upgrades, renewals etc.)
                </Typography>
              </div>
            </div>
          </CardContent>
        </Card>
        <Card sx={{ minWidth: 275 }} style={{ width: "50%" }}>
          <CardContent>
            <div
              style={{
                display: "flex",
                direction: "row",
              }}
              spacing={2}
            >
              <div>
                <Typography
                  variant="h6"
                  style={{ fontWeight: "400", marginBottom: "2rem" }}
                >
                  Billing Contacts
                </Typography>
                <Typography>
                  Send copies of invoices, receipts, orders and other renewal
                  notifications to these Platform users.
                </Typography>
              </div>
            </div>
          </CardContent>
        </Card>
      </div> */}
    </div>
  );
};

// function mapStateToProps(state) {
//   return { data: state.auth.hotels[0] };
// }
// export default connect(mapStateToProps)(CompanyInfo);
export default CompanyInfoManagement;

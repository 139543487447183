import React, { useEffect, useState } from "react";

import Table from "../../components/Table";
import { Box, Button, ClickAwayListener, Stack, Tooltip, Accordion, AccordionSummary, AccordionDetails, Typography, Drawer } from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { formatDateRange, toRegulerCase } from "../../utils/Utils";
import { handleDjangoDataRequest } from "../../utils/apiUtils";
import TableSkeleton from "../../components/TableSkeleton";

import moment from "moment";
import { DateRange } from "react-date-range";
import "react-date-range/dist/styles.css";
import "react-date-range/dist/theme/default.css";
import Nodata from "../../components/Nodata";


export default function MetaUserLog({ hotelId }) {
    const [selectedLog, setSelectedLog] = useState(null);
    const [drawerOpen, setDrawerOpen] = useState(false);
  const [rowData, setRowData] = useState();
  const [table, setTable] = useState(false);
  const [columnDefs, setColumnDefs] = useState();
  // [
  //   { field: "make" },
  //   { field: "model" },
  //   { field: "price" },
  //   { field: "type" },
  // ];
  // Handle column structure dynamically
  const handleColumn = (data) => {
    if (data) {
      const columnData = Object.keys(data[0]).map((elm) => ({
        field: elm,
        headerName: toRegulerCase(elm),
        ...(elm === "log_text" && {
          cellRendererFramework: (params) => (
            <div>
              {/* Tooltip on Hover */}
              <Tooltip title={params.value} arrow>
                <span
                  style={{ color: "#1ab394", textDecoration: "underline", cursor: "pointer" }}
                  onClick={() => {
                    setSelectedLog(params.value);
                    setDrawerOpen(true);
                  }}
                >
                  {params.value.length > 25 ? `${params.value.substring(0, 25)}...` : params.value}
                </span>
              </Tooltip>

              {/* Expandable Row in Table */}
              <ExpandableLog params={params} />
            </div>
          ),
        }),
      }));
      setColumnDefs(columnData);
    }
  };

  //-------------------
  const [dateRange, setDateRange] = useState([
    {
      startDate: new Date(),
      endDate: new Date(),
      key: "selection",
    },
  ]);
  const handleShowButton = () => {
    setTable(false);
    const formatedDate = formatDateRange(dateRange);
    console.log(formatedDate);
    handleDjangoDataRequest(
      `api/v2/metaAdmin/metaGetUserLogsForHotel/?hotel_id=${hotelId}&daterange=${formatedDate}`
    )
      .then((data) => {
        setTable(true);
        handleColumn(data);
        setRowData(data);
        console.log(data);
      })
      .catch((err) => setTable(true));
  };
  useEffect(() => {
    handleShowButton();
  }, []);
  //---------------Skeleton--------
  //open and close date picker
  const styles = {
    position: "absolute",
    top: 28,
    right: 0,
    left: 10,
    zIndex: 100,
    p: 1,
    width: "fit-content",
    bgcolor: "background.paper",
    borderRadius: "5px",
    boxShadow: "rgba(0, 0, 0, 0.24) 0px 3px 8px",
  };
  const [open, setOpen] = useState(false);
  const handleClick = () => setOpen((prev) => !prev);
  const handleClickAway = () => {
    setOpen(false);
  };

  return (
    <>
      <Box sx={{ display: "flex", marginBottom: 1 }}>
        <Stack spacing={4} className="position-relative">
          <ClickAwayListener onClickAway={handleClickAway}>
            <Box sx={{ position: "relative" }}>
              <Button variant="outlined" onClick={handleClick} fullWidth>
                {moment(dateRange[0].startDate).format("DD-MM-YY")} {" to "}
                {moment(dateRange[0].endDate).format("DD-MM-YY")}
              </Button>
              {open ? (
                <Box sx={styles}>
                  <Stack spacing={3}>
                    <DateRange
                      editableDateInputs={true}
                      onChange={(item) => setDateRange([item.selection])}
                      moveRangeOnFirstSelection={false}
                      ranges={dateRange}
                    />
                    <Stack
                      className="position-relative w-100 d-flex justify-content-between"
                      direction="row"
                      sx={{ justifyContent: "space-between" }}
                    >
                      <Button
                        variant="outlined"
                        onClick={() => setOpen(false)}
                        color="error"
                      >
                        Cancel
                      </Button>
                      <Button
                        variant="custom-button"
                        onClick={() => {
                          handleShowButton();
                          handleClickAway();
                          console.log(dateRange);
                        }}
                      >
                        Apply
                      </Button>
                    </Stack>
                  </Stack>
                </Box>
              ) : null}
            </Box>
          </ClickAwayListener>
        </Stack>
        <Button
          variant="custom-button"
          sx={{ ml: 2 }}
          onClick={handleShowButton}
        >
          Show Data
        </Button>
      </Box>
      {table ? (
        rowData ? (
          <Table rowData={rowData} columnDefs={columnDefs} />
        ) : (
          <Nodata />
        )
      ) : (
        <TableSkeleton />
      )}
       {/* Accordion Below Table */}
       {selectedLog && (
        <Accordion>
          <AccordionSummary expandIcon={<ExpandMoreIcon />}>
            <Typography>Log Message</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Typography>{selectedLog}</Typography>
          </AccordionDetails>
        </Accordion>
      )}

      {/* Right-Side Drawer */}
      <Drawer anchor="right" open={drawerOpen} onClose={() => setDrawerOpen(false)}>
        <Box sx={{ width: 300, padding: 2 }}>
          <Typography variant="h6">Log Message</Typography>
          <Typography>{selectedLog}</Typography>
        </Box>
      </Drawer>
    </>
  );
}
const ExpandableLog = ({ params }) => {
    const [expanded, setExpanded] = useState(false);
    const logText = params.value?.trim() ? params.value : "-";

    return (
      <div>
        {logText === "-" ? (
          <span>{logText}</span>
        ) : (
          <>
            <span
              style={{ color: "blue", textDecoration: "underline", cursor: "pointer" }}
              onClick={() => setExpanded(!expanded)}
            >
              {expanded ? "Hide Log" : "View Log"}
            </span>
            {expanded && (
              <div style={{ marginTop: "5px", padding: "5px", border: "1px solid #ccc", borderRadius: "5px" }}>
                {logText}
              </div>
            )}
          </>
        )}
      </div>
    );
};

